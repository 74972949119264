// Modules
import { memo } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

// UI
import { IconButton, Tooltip } from '@chakra-ui/react'

const InfoTooltip = (props) => {
  const { label, ...args } = props

  return (
    <Tooltip label={label} aria-label="Info tooltip">
      <IconButton
        icon={<AiOutlineInfoCircle width="20px" />}
        aria-label="Information"
        variant="ghost"
        size="md"
        padding={0}
        margin={0}
        border={0}
        outline={0}
        height="auto"
        width="auto"
        minWidth="auto"
        paddingInlineStart={0}
        paddingInlineEnd={0}
        background="transparent"
        _hover={{
          background: 'transparent',
        }}
        {...args}
      />
    </Tooltip>
  )
}

export default memo(InfoTooltip)
