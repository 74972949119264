// Modules
import { useFormik } from 'formik'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

// Languages
import {
  LN_AUTH_FORGOT_PASSWORD_MESSAGE,
  LN_AUTH_FORGOT_PASSWORD_SIGN_IN_BTN,
} from 'locales/constants'

// Components
import { SubmitBtn, TextSm } from 'components'
import { formConstants } from 'pages/Auth/Login/LoginForm/constants'
import EmailField from 'pages/Auth/components/EmailField'
import ErrorForm from 'pages/Auth/components/ErrorForm'
import InfoBtn from 'pages/Auth/components/InfoBtn'
import PageWrapper from 'pages/Auth/components/PageWrapper'

// Navigation
import { routers } from 'navigation/constants'

// Utils
import { validationEmail } from 'utils/validation'

// Store
import { onForgotPassword } from 'store/actions/Auth/actions'
import { forgotPasswordSelector } from 'store/actions/Auth/selectors'

const validationSchema = Yup.object({
  ...validationEmail,
})

const ForgotPasswordPage = () => {
  const history = useHistory()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading, error } = useSelector(forgotPasswordSelector)

  const formik = useFormik({
    initialValues: {
      [formConstants.email]: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(onForgotPassword(values))
    },
  })

  const onLoginPage = useCallback(() => {
    history.push('/' + routers.login)
  }, [history])

  return (
    <PageWrapper>
      <TextSm mb={3}>{t(LN_AUTH_FORGOT_PASSWORD_MESSAGE)}</TextSm>
      <form onSubmit={formik.handleSubmit}>
        <EmailField formik={formik} />
        <SubmitBtn disabled={!formik.isValid} loading={loading} />
      </form>
      <InfoBtn onClick={onLoginPage} title={t(LN_AUTH_FORGOT_PASSWORD_SIGN_IN_BTN)} />
      <ErrorForm error={error} />
    </PageWrapper>
  )
}

export default memo(ForgotPasswordPage)
