// Modules
import { useSelector } from 'react-redux'

// Hooks
import useHistoryTablePagination from 'components/Table/TableHistory/useHistoryTablePagination'

// Store
import { getDepositUserHistory } from 'store/actions/UserDeposit/actions'
import { depositHistorySelector } from 'store/actions/UserDeposit/selectors'

function useDepositHistoryTablePagination() {
  const depositHistory = useSelector(depositHistorySelector)
  const listPagination = depositHistory?.pagination

  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useHistoryTablePagination(
    listPagination,
    getDepositUserHistory,
  )

  return {
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage,
  }
}

export default useDepositHistoryTablePagination
