// Modules
import { memo } from 'react'
import { RxCross2 } from 'react-icons/rx'

// UI
import { Box, Flex, Icon } from '@chakra-ui/react'

const PreviewList = (props) => {
  const {
    file,
    isDeleted,
    onDelete,
    deleteBtnStyles,
    iconWidth = '13px',
    width = '100px',
    height = '100px',
  } = props

  if (!file) {
    return null
  }

  return file?.map((file) => (
    <Box
      display="inline-flex"
      borderRadius={2}
      border="1px solid #eaeaea"
      marginBottom={2}
      marginRight={2}
      width={width}
      height={height}
      padding={1}
      boxSizing="border-box"
      key={file?.name}
      position="relative"
      justifyContent="center"
      // backgroundImage={`url(${file.preview})`}
    >
      {isDeleted && (
        <Flex
          className="delete-icon"
          right={0}
          top={0}
          onClick={onDelete}
          position="absolute"
          borderRadius="1px"
          backgroundColor="blue.200"
          height="15px"
          alignItems="center"
          _hover={{
            backgroundColor: 'blue.100',
            cursor: 'pointer',
          }}
          {...deleteBtnStyles}
        >
          <Icon as={RxCross2} w={iconWidth} color="white" />
        </Flex>
      )}

      <Flex minWidth="0" overflow="hidden">
        <img
          src={file.preview || file}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </Flex>
    </Box>
  ))
}

export default memo(PreviewList)

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}
