// Types
import {
  CONFIRM_EMAIL_ACTION,
  CONFIRM_EMAIL_RESULT,
  EDIT_USER_INFO,
  GET_COUNTRIES_LIST,
  GET_USER_BALANCE,
  GET_USER_INFO,
  GET_USER_RATES,
  GET_USER_TRADING_ACCOUNTS,
  PUSH_QUERY_USER_ACTIVE_AT,
  SET_COUNTRIES_LIST,
  SET_SELECTED_APP_LANGUAGE,
  SET_SELECTED_THEME,
  SET_USER_BALANCE,
  SET_USER_CURRENT_TRADING_ACCOUNT,
  SET_USER_DEVICE_IS_MOBILE,
  SET_USER_INFO,
  SET_USER_RATES,
  SET_USER_SIDEBAR_ACTIVE_ITEM,
  SET_USER_TRADING_ACCOUNTS,
} from 'store/actions/User/types'

export const setSelectedAppLanguage = (payload) => ({
  type: SET_SELECTED_APP_LANGUAGE,
  payload: payload,
})

export const setUserSidebarActiveItem = (payload) => ({
  type: SET_USER_SIDEBAR_ACTIVE_ITEM,
  payload: payload,
})

export const setUserInfo = (payload) => ({
  type: SET_USER_INFO,
  payload: payload,
})

export const getUserInfo = (payload) => ({
  type: GET_USER_INFO,
  payload: payload,
})

export const editUserInfo = (payload) => ({
  type: EDIT_USER_INFO,
  payload: payload,
})

export const onConfirmUserEmail = () => ({
  type: CONFIRM_EMAIL_ACTION,
})

export const onConfirmUserEmailResult = () => ({
  type: CONFIRM_EMAIL_RESULT,
})

export const onGetCountriesList = () => ({
  type: GET_COUNTRIES_LIST,
})

export const onSetCountriesList = (payload) => ({
  type: SET_COUNTRIES_LIST,
  payload: payload,
})

export const onGetUserBalance = (payload) => ({
  type: GET_USER_BALANCE,
  payload: payload,
})

export const onSetUserBalance = (payload) => ({
  type: SET_USER_BALANCE,
  payload: payload,
})

export const onSetSelectedTheme = (payload) => ({
  type: SET_SELECTED_THEME,
  payload: payload,
})

export const onSetUserCurrentTradingAccount = (payload) => ({
  type: SET_USER_CURRENT_TRADING_ACCOUNT,
  payload: payload,
})

export const onGetUserTradingAccounts = () => ({
  type: GET_USER_TRADING_ACCOUNTS,
})

export const onSetUserTradingAccounts = (payload) => ({
  type: SET_USER_TRADING_ACCOUNTS,
  payload: payload,
})

export const onGetUserRates = (payload) => ({
  type: GET_USER_RATES,
  payload: payload,
})

export const onSetUserRates = (payload) => ({
  type: SET_USER_RATES,
  payload: payload,
})

export const onSetUserDeviceIsMobile = (payload) => ({
  type: SET_USER_DEVICE_IS_MOBILE,
  payload: payload,
})

export const onPushQueryUserActiveAt = () => ({
  type: PUSH_QUERY_USER_ACTIVE_AT,
})
