// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_ASSET_PRICE,
  LN_TRANSACTION_CALC_PENDING,
  LN_TRANSACTION_CALC_PENDING_MODAL_MESSAGE,
  LN_TRANSACTION_CALC_REVERT_MARKET_PRICE_BTN,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import InputMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'

const PendingPopoverUI = (props) => {
  const {
    popoverLabelVal,
    onChangeAssetPriceInput,
    assetPriceValue,
    onIncrease,
    onDecrease,
    onResetValue,
    onDefaultValue,
    unchangeableCurrencyPriceValue,
    currentCurrencySymbolPointValue,
  } = props
  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Popover placement="left">
      <PopoverTrigger>
        <Button w="full" display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
          <TextXs fontWeight="400" fontSize="10px">
            {t(LN_TRANSACTION_CALC_PENDING)}
          </TextXs>
          <TextXs color={mainTextColor} fontSize="10px" pl="3px">
            {popoverLabelVal}
          </TextXs>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="500">{t(LN_TRANSACTION_CALC_PENDING)}</PopoverHeader>
        <PopoverBody>
          <InputMarket
            label={t(LN_TRANSACTION_CALC_ASSET_PRICE)}
            onChange={onChangeAssetPriceInput}
            value={roundDigitsAfterDots(assetPriceValue, currentCurrencySymbolPointValue)}
            onIncreaseValue={onIncrease}
            onDecreaseValue={onDecrease}
            onResetValue={onResetValue}
          />
          <Button
            size="sm"
            w="full"
            onClick={onDefaultValue}
            isDisabled={assetPriceValue === unchangeableCurrencyPriceValue}
          >
            {t(LN_TRANSACTION_CALC_REVERT_MARKET_PRICE_BTN)}
          </Button>
          <TextXs mt={2} color={mainTextColor}>
            {t(LN_TRANSACTION_CALC_PENDING_MODAL_MESSAGE)}
          </TextXs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default memo(PendingPopoverUI)
