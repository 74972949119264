// Languages
import { LN_AUTH_LOGIN_TAB, LN_AUTH_REGISTRATION_TAB } from 'locales/constants'

export const tabsKey = {
  login: 'login',
  registration: 'registration',
}

export const tabsTitles = {
  [tabsKey.login]: LN_AUTH_LOGIN_TAB,
  [tabsKey.registration]: LN_AUTH_REGISTRATION_TAB,
}
