// Modules
import { memo, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCopyOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_DEPOSIT_TAB_COPY_BTN_ACTION_TEXT,
  LN_DEPOSIT_TAB_DESC_TEXT_WALLET_REQUESTED,
  LN_DEPOSIT_TAB_LINK_BTN_TITLE,
  LN_HISTORY_NO_DATA,
} from 'locales/constants'

// Components
import {
  CopyBtnLikeInput,
  LoadingBlock,
  NoDataMessage,
  TabBase,
  TabPanelBase,
  TextMd,
  TextSm,
} from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { getUserDepositFields } from 'store/actions/UserDeposit/actions'
import {
  depositFieldsSelector,
  loadingDepositFieldsSelector,
} from 'store/actions/UserDeposit/selectors'

// UI
import { Box, Button, TabList, TabPanels, Tabs } from '@chakra-ui/react'

const profileType = {
  url: 'url',
  bitcoin: 'bitcoin',
  text: 'text',
}

const DepositTabs = () => {
  const { t } = useTranslation()
  const { mainBorderColor, isLightTheme } = useCurrentColorTheme()

  const dispatch = useDispatch()

  const depositFields = useSelector(depositFieldsSelector)
  const loadingDepositFields = useSelector(loadingDepositFieldsSelector)

  const [isCopiedBtn, setIsCopiedBtn] = useState(false)

  useEffect(() => {
    dispatch(getUserDepositFields())
  }, [dispatch])

  useEffect(() => {
    if (isCopiedBtn) {
      const timer = setTimeout(() => {
        setIsCopiedBtn(false)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [isCopiedBtn])

  const handleLink = useCallback((link) => {
    link && window.open(link, '_blank', 'noopener,noreferrer')
  }, [])

  const handleCopyBtn = useCallback(
    (value) => {
      if (value) {
        navigator.clipboard.writeText(value).then((res) => {
          setIsCopiedBtn(true)
        })
      }
    },
    [setIsCopiedBtn],
  )

  const showDependsOnProfile = (item) => {
    switch (item?.profile?.toLowerCase()) {
      case profileType?.bitcoin:
        return (
          <>
            {item?.xpub ? (
              <Box textAlign="center">
                <CopyBtnLikeInput
                  value={item?.xpub}
                  maxWidth="375px"
                  rightIcon={<IoCopyOutline />}
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => {
                    handleCopyBtn(item?.xpub)
                  }}
                />
                <TextSm mt={1} transition="height 0.5s ease">
                  {isCopiedBtn && t(LN_DEPOSIT_TAB_COPY_BTN_ACTION_TEXT)}
                </TextSm>
              </Box>
            ) : (
              <TextInfo>{t(LN_DEPOSIT_TAB_DESC_TEXT_WALLET_REQUESTED)}</TextInfo>
            )}
          </>
        )
      case profileType?.url:
        return item?.url ? (
          <Button colorScheme="blue" color="white" onClick={() => handleLink(item?.url)}>
            {t(LN_DEPOSIT_TAB_LINK_BTN_TITLE)}
          </Button>
        ) : null
      default:
        return null
    }
  }

  if (loadingDepositFields) {
    return <LoadingBlock mt={3} />
  }

  if (!depositFields || depositFields?.length === 0) {
    return <NoDataMessage />
  }

  return (
    <Box>
      <Tabs variant="enclosed">
        <TabList borderColor={mainBorderColor}>
          {depositFields?.map((el, i) => (
            <TabBase key={el?.name + i} title={el?.name} />
          ))}
        </TabList>

        <TabPanels
          bg={isLightTheme ? '#ffffff78' : '#ffffff0a'}
          height="200px"
          borderBottomRadius="md"
          border="1px solid"
          borderColor={mainBorderColor}
          borderTop={0}
        >
          {depositFields?.map((el, i) => {
            return (
              <TabPanelBase key={el?.name + i + 'tab-panel'}>
                {el?.text && <TextInfo>{el?.text}</TextInfo>}
                {showDependsOnProfile(el)}
              </TabPanelBase>
            )
          })}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default memo(DepositTabs)

export const TextInfo = memo(({ children }) => {
  return <TextMd fontWeight={500}>{children}</TextMd>
})

TextInfo.displayName = 'TextInfo'
