// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { tabsTitles } from 'pages/Auth/Login/AuthTabs/constants'
import LoginForm from 'pages/Auth/Login/LoginForm'
import RegistrationForm from 'pages/Auth/Login/RegistrationForm'

// Store
import { onSetAuthTabs } from 'store/actions/Auth/actions'
import { authTabsSelector } from 'store/actions/Auth/selectors'

// UI
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

export const AuthTabValueDefault = 0

const AuthTabs = () => {
  const { t } = useTranslation()
  const authTabs = useSelector(authTabsSelector)

  const dispatch = useDispatch()

  const handleTabsChange = useCallback(
    (index) => {
      dispatch(onSetAuthTabs(index))
    },
    [dispatch],
  )

  return (
    <Box>
      <Tabs isFitted index={authTabs} onChange={handleTabsChange}>
        <TabList>
          <Tab fontWeight="500" fontSize="17px">
            {t(tabsTitles.login)}
          </Tab>
          <Tab fontWeight="500" fontSize="17px">
            {t(tabsTitles.registration)}
          </Tab>
        </TabList>

        <TabPanels pt={2}>
          <TabPanel pl={0} pr={0}>
            <LoginForm />
          </TabPanel>
          <TabPanel pl={0} pr={0} pb={0}>
            <RegistrationForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default memo(AuthTabs)
