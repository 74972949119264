// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const kycFieldListSelector = createSelector(selfSelector, (self) => self.kycFieldList)
export const kycFieldListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.kycFieldListLoading,
)

export const verificationTabValueSelector = createSelector(
  selfSelector,
  (self) => self.verificationTabValue,
)
