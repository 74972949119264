// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_PASSWORD } from 'locales/constants'

// Components
import { ErrorMessage, InputBase } from 'components'
import { formConstants } from 'pages/Auth/Login/LoginForm/constants'

// UI
import { FormControl, FormLabel, InputGroup } from '@chakra-ui/react'

const PasswordField = (props) => {
  const { formik, placeholder, isLabel = true } = props

  const { t } = useTranslation()

  const { password } = formConstants

  return (
    <FormControl isRequired mb={3}>
      {isLabel && <FormLabel>{t(LN_AUTH_PASSWORD)}</FormLabel>}
      <InputGroup>
        <InputBase
          type="password"
          {...formik.getFieldProps(password)}
          placeholder={placeholder ? t(LN_AUTH_PASSWORD) : ''}
        />
      </InputGroup>
      {formik.touched[password] && formik.errors[password] && (
        <ErrorMessage error={t(formik.errors[password])} fontSize="sm" />
      )}
    </FormControl>
  )
}

export default memo(PasswordField)
