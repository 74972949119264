// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { ErrorMessage, LinkBase } from 'components/index'

// UI
import { Box, Checkbox, FormControl } from '@chakra-ui/react'

const CheckboxFormikField = (props) => {
  const {
    label,
    isRequired = false,
    formik,
    fieldName,
    disabled,
    placeholder,
    linkLabel,
    target,
    ...args
  } = props

  const { t } = useTranslation()

  return (
    <Box mb={3} {...args}>
      <FormControl isRequired={isRequired}>
        <Checkbox {...formik.getFieldProps(fieldName)} id={fieldName} colorScheme="blue">
          {linkLabel ? (
            <LinkBase href={linkLabel} target={target}>
              {t(label)}
            </LinkBase>
          ) : (
            t(label)
          )}
        </Checkbox>
        {formik.touched[fieldName] && formik.errors[fieldName] && (
          <ErrorMessage error={t(formik.errors[fieldName])} fontSize="sm" />
        )}
      </FormControl>
    </Box>
  )
}

export default memo(CheckboxFormikField)
