// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_STOP_LOSS,
  LN_TRANSACTION_CALC_TAKE_PROFIT,
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import InputMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket'
import RadioProfitGroup from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/RadioProfitGroup'
import {
  stopLoss,
  stopLossRadio,
  takeProfit,
} from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'

const TakeProfitPopoverUI = (props) => {
  const {
    popoverLabelVal,
    currentCurrencySymbolPointValue,
    takeProfitRadio,
    takeProfitRadioValue,
    onChangeTakeProfitRadio,
    takeProfitValue,
    onChangeTakeProfitInput,
    onIncreaseTakeProfit,
    onDecreaseTakeProfit,
    onResetValueTakeProfit,
    stopLossValue,
    onChangeStopLossInput,
    onIncreaseStopLoss,
    onDecreaseStopLoss,
    onResetValueStopLoss,
    stopLossRadioValue,
    onChangeStopLossRadio,
  } = props

  const { t } = useTranslation()
  const { mainTextColor, isLightTheme } = useCurrentColorTheme()

  return (
    <Popover placement="left">
      <PopoverTrigger>
        <Button w="full" display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
          <TextXs fontWeight="400" fontSize="10px">
            {t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS)}
          </TextXs>
          <TextXs color={mainTextColor} fontSize="10px" pl="1px">
            {popoverLabelVal}
          </TextXs>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader fontWeight="500">
          {t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS)}
        </PopoverHeader>
        <PopoverBody>
          {/*<Button size="sm" w="full">*/}
          {/*  {t(LN_TRANSACTION_CALC_DEFAULT_BTN)}*/}
          {/*</Button>*/}
          <Box
            borderBottom="1px solid"
            borderColor={!isLightTheme ? 'brand.borderColor' : 'brand.borderColorLight'}
          >
            <RadioProfitGroup
              name={takeProfitRadio}
              value={takeProfitRadioValue}
              onChange={onChangeTakeProfitRadio}
            />
            <InputMarket
              name={takeProfit}
              label={t(LN_TRANSACTION_CALC_TAKE_PROFIT)}
              boxProps={{ mt: 2 }}
              value={roundDigitsAfterDots(takeProfitValue, currentCurrencySymbolPointValue)}
              onChange={onChangeTakeProfitInput}
              onIncreaseValue={onIncreaseTakeProfit}
              onDecreaseValue={onDecreaseTakeProfit}
              onResetValue={onResetValueTakeProfit}
            />
          </Box>
          <Box mt={2}>
            <RadioProfitGroup
              name={stopLossRadio}
              value={stopLossRadioValue}
              onChange={onChangeStopLossRadio}
            />
            <InputMarket
              name={stopLoss}
              label={t(LN_TRANSACTION_CALC_STOP_LOSS)}
              boxProps={{ mt: 2 }}
              value={roundDigitsAfterDots(stopLossValue, currentCurrencySymbolPointValue)}
              onChange={onChangeStopLossInput}
              onIncreaseValue={onIncreaseStopLoss}
              onDecreaseValue={onDecreaseStopLoss}
              onResetValue={onResetValueStopLoss}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default memo(TakeProfitPopoverUI)
