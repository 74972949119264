// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_CONTINUE_BTN } from 'locales/constants'

import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Button } from '@chakra-ui/react'

const SubmitBtn = (props) => {
  const { t } = useTranslation()

  const { disabled, loading, title = t(LN_AUTH_CONTINUE_BTN), ...args } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Button
      isDisabled={disabled}
      mb={4}
      w="100%"
      colorScheme="blue"
      color="white"
      type="submit"
      isLoading={loading}
      _disabled={{
        backgroundColor: isLightTheme ? '#808692' : '#2d3748',
        cursor: 'not-allowed',
      }}
      _hover={{
        backgroundColor: disabled ? (isLightTheme ? '#808692' : '#2d3748') : 'blue.600',
      }}
      {...args}
    >
      {title}
    </Button>
  )
}

export default memo(SubmitBtn)
