// Modules
import _ from 'lodash'
import * as Yup from 'yup'

// Components
import { withdrawalMethodsConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'
import { formConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'

// Utils
import { validationText } from 'utils/validation/constants'

// Utils
import {
  bitcoinAddressRegex,
  cardHolderNameRegex,
  cardNumberRegex,
  ethereumAddressRegex,
  ibanRegex,
  onlyDigitsRegex,
  positiveNumberRegex,
  swiftCodeRegex,
  usdtAddressRegex,
} from 'utils/validation'

export const validationSchema = Yup.object().shape({
  [formConstants.amount]: Yup.string()
    .required(validationText.inputValueIsRequired)
    .matches(positiveNumberRegex, validationText.inputValueNotPositive),
  [formConstants.withdrawalMethod]: Yup.string().required(validationText.inputValueIsRequired),
  // crypto
  [formConstants.walletAddress]: Yup.string().when([formConstants.withdrawalMethod], {
    is: (val) => {
      return (
        val === withdrawalMethodsConstants.ethereum ||
        val === withdrawalMethodsConstants.bitcoin ||
        val === withdrawalMethodsConstants.usdt
      )
    },
    then: (s) => {
      return Yup.string()
        .required(validationText.inputValueIsRequired)
        .test(
          'validate-wallet-address',
          validationText.invalidCryptoWalletAddress,
          function (value) {
            const withdrawalMethod = this.parent?.[formConstants.withdrawalMethod]
            if (withdrawalMethod === withdrawalMethodsConstants.ethereum) {
              return ethereumAddressRegex.test(value)
            }
            if (withdrawalMethod === withdrawalMethodsConstants.bitcoin) {
              return bitcoinAddressRegex.test(value)
            }
            if (withdrawalMethod === withdrawalMethodsConstants.usdt) {
              return usdtAddressRegex.test(value)
            }
            return false
          },
        )
    },
    otherwise: (s) => Yup.string().notRequired(),
  }),
  // card
  [formConstants.cardNumber]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.card,
    then: (s) => Yup.string().required(validationText.inputValueIsRequired),
    // .matches(cardNumberRegex, validationText.invalidCardNumber),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  [formConstants.cardHolderName]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.card,
    then: (s) =>
      Yup.string()
        .required(validationText.inputValueIsRequired)
        .matches(cardHolderNameRegex, validationText.invalidHolderName)
        .min(3, validationText.invalidHolderNameMinSymbol),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  // bank
  [formConstants.accountHolderName]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.bank,
    then: (s) =>
      Yup.string()
        .required(validationText.inputValueIsRequired)
        .matches(cardHolderNameRegex, validationText.invalidHolderName)
        .min(3, validationText.invalidHolderNameMinSymbol),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  [formConstants.accountNumber]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.bank,
    then: (s) =>
      Yup.string()
        .required(validationText.inputValueIsRequired)
        // .matches(onlyDigitsRegex, validationText.fieldMustContainOnlyDigits)
        .min(8, validationText.fieldMustContainOnlyMin8Digits),
    // .max(20, validationText.fieldMustContainOnlyMax20Digits),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  // at least one of IBAN or SWIFT code must be valid when withdrawalMethod is "bank"
  [formConstants.iban]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.bank,
    then: (s) => Yup.string(),
    // .required(validationText.inputValueIsRequired)
    // .matches(ibanRegex, validationText.invalidIBAN)
    // .min(15, validationText.fieldMustContainAtLeastMin15Digits),
    // .max(34, validationText.fieldMustContainOnlyMax34Digits),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  [formConstants.swiftCode]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.bank,
    then: (s) => Yup.string(),
    // .required(validationText.inputValueIsRequired)
    // .matches(swiftCodeRegex, validationText.invalidSWIFTCode)
    // .min(8, validationText.fieldMustContainOnlyMin8Digits)
    // .max(11, validationText.fieldMustContainOnlyMax11Digits),
    otherwise: (s) => Yup.string().notRequired(),
  }),
  // Custom validation: at least one of IBAN or SWIFT code must be valid when withdrawalMethod is "bank"
  [formConstants.atLeastOneValid]: Yup.string().when([formConstants.withdrawalMethod], {
    is: withdrawalMethodsConstants.bank,
    then: (s) =>
      Yup.string().test('at-least-one-valid', validationText.orIBANorSWIFT, function (value) {
        const { iban, swiftCode } = this.parent
        return (iban && !_.isEmpty(iban)) || (swiftCode && !_.isEmpty(swiftCode))
        // return (iban && iban.match(ibanRegex)) || (swiftCode && swiftCode.match(swiftCodeRegex))
      }),
    otherwise: (s) => Yup.string().notRequired(),
  }),
})
