// Modules
import _ from 'lodash'
import { memo, useCallback, useMemo } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

// Components
import ListPanelWrapper from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanelWrapper'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Store
import { assertListSelector } from 'store/actions/TransactionCreation/selectors'
import {
  deleteExpandableAccordionInd,
  setExpandableAccordionInds,
} from 'store/actions/marketSidebar/actions'
import { expandableAccordionIndsSelector } from 'store/actions/marketSidebar/selectors'

// UI
import { Box, Button, Collapse, Icon } from '@chakra-ui/react'

const AccordionItem = (props) => {
  const { groupId, groupList, list } = props

  const { isLightTheme } = useCurrentColorTheme()
  const { getIconAccordion } = useMarketWatchAccordionList()
  const dispatch = useDispatch()

  const expandableAccordionInds = useSelector(expandableAccordionIndsSelector)

  const assertList = useSelector(assertListSelector)

  const handleChange = useCallback(() => {
    if (expandableAccordionInds.includes(groupId)) {
      dispatch(deleteExpandableAccordionInd(groupId))
    } else {
      dispatch(setExpandableAccordionInds([groupId]))
    }
  }, [expandableAccordionInds, dispatch, groupId])

  const groupAssertListByAssetLabel = useCallback(() => {
    return _.groupBy(assertList, 'asset_label')
  }, [assertList])

  const filterGroupedAssertListByAssetLabel = useCallback((groupId, groupedList) => {
    return Object.keys(groupedList)
      .filter((key) => key.toLowerCase().includes(groupId.toLowerCase()))
      .reduce((acc, key) => {
        acc[key] = groupedList[key]
        return acc
      }, {})
  }, [])

  const isExpandedAccordion = useMemo(() => {
    return expandableAccordionInds?.includes(groupId)
  }, [expandableAccordionInds, groupId])

  const iconAccordion = useMemo(() => {
    return getIconAccordion(groupId)
  }, [getIconAccordion, groupId])

  const filteredGroupedAssertListByAssetLabel = useMemo(() => {
    const groupedAssertListByAssetLabel = groupAssertListByAssetLabel()
    return filterGroupedAssertListByAssetLabel(groupId, groupedAssertListByAssetLabel)
  }, [groupId, groupAssertListByAssetLabel])

  const foundKeys = useMemo(() => {
    return _.keys(filteredGroupedAssertListByAssetLabel)
  }, [filteredGroupedAssertListByAssetLabel])

  const isMoreThanOneTrue = useMemo(() => {
    return foundKeys?.length > 1
  }, [foundKeys])

  return !groupList || groupList?.length === 0 ? null : (
    <Box
      key={groupId}
      overflow="hidden"
      background={isLightTheme ? 'gray.100' : 'whiteAlpha.50'}
      borderRadius="0.375rem"
      color="whiteAlpha.900"
      boxShadow="sm"
    >
      <Button
        width="100%"
        onClick={handleChange}
        pl={3}
        pr={3}
        height={9}
        pb={0}
        pt={0}
        bg={isLightTheme ? 'brand.mainAccent1Light' : 'navy.500'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          backgroundColor: isLightTheme ? 'blue.600' : 'navy.400',
          transition: '0.8s',
        }}
      >
        <Icon as={iconAccordion} color="whiteAlpha.900" />
        <Box
          as="span"
          flex="1"
          textAlign="left"
          ml={1}
          fontSize="sm"
          textTransform="capitalize"
          color="whiteAlpha.900"
          fontWeight={400}
        >
          {groupId}
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Box
            as="span"
            fontSize="xs"
            p="2px 4px"
            bg="navy.300"
            borderRadius="xl"
            color={isLightTheme ? 'white' : 'gray.200'}
            mr={2}
            minW="22px"
            textAlign="center"
            fontWeight={400}
          >
            {groupList?.length}
          </Box>
          <Icon
            as={isExpandedAccordion ? IoIosArrowUp : IoIosArrowDown}
            w="14px"
            h="14px"
            color="whiteAlpha.900"
          />
        </Box>
      </Button>
      <Collapse in={isExpandedAccordion} animateOpacity>
        <Box border={0} mt={1} pr={1.5} pl={1.5} pb={2} pt={1.5}>
          <ListPanelWrapper
            list={list}
            isMoreThanOneTrue={isMoreThanOneTrue}
            groupId={groupId}
            filteredGroupedAssertListByAssetLabel={filteredGroupedAssertListByAssetLabel}
            foundKeys={foundKeys}
          />
        </Box>
      </Collapse>
    </Box>
  )
}

export default memo(AccordionItem)
