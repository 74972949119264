export const SET_WITHDRAWAL_HISTORY = 'SET_WITHDRAWAL_HISTORY'
export const GET_WITHDRAWAL_HISTORY = 'GET_WITHDRAWAL_HISTORY'
export const SET_WITHDRAWAL_MODAL_ITEM_INFO = 'SET_WITHDRAWAL_MODAL_ITEM_INFO'
export const SET_IS_WITHDRAWAL_MODAL_INFO_OPEN = 'SET_IS_WITHDRAWAL_MODAL_INFO_OPEN'

export const SET_WITHDRAWAL_METHODS = 'SET_WITHDRAWAL_METHODS'
export const GET_WITHDRAWAL_METHODS = 'GET_WITHDRAWAL_METHODS'

export const CREATE_WITHDRAWAL = 'CREATE_WITHDRAWAL'
export const CREATE_WITHDRAWAL_SET_LOADING = 'CREATE_WITHDRAWAL_SET_LOADING'

export const CANCEL_WITHDRAWAL = 'CANCEL_WITHDRAWAL'
export const SET_LOADING_CANCEL_WITHDRAWAL = 'SET_LOADING_CANCEL_WITHDRAWAL'
export const SET_CANCEL_WITHDRAWAL_SUCCESS = 'SET_CANCEL_WITHDRAWAL_SUCCESS'
