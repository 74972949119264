// Modules
import { useFormik } from 'formik'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'

// Components
import { SubmitBtn } from 'components'
import { formConstants } from 'pages/Auth/Login/LoginForm/constants'
import ErrorForm from 'pages/Auth/components/ErrorForm'
import NewPasswordField from 'pages/Auth/components/NewPasswordField'
import PageWrapper from 'pages/Auth/components/PageWrapper'
import PasswordField from 'pages/Auth/components/PasswordField'

// Utils
import { validationPassword, validationRepeatPassword } from 'utils/validation'

// Store
import { onResetPassword } from 'store/actions/Auth/actions'
import { resetPasswordSelector } from 'store/actions/Auth/selectors'

const validationSchema = Yup.object({
  ...validationPassword,
  ...validationRepeatPassword,
})

const NewPasswordPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tokenNewPassword = searchParams.get('token')
  const email = searchParams.get('email')

  const dispatch = useDispatch()
  const { loading, error } = useSelector(resetPasswordSelector)

  const formik = useFormik({
    initialValues: {
      [formConstants.password]: '',
      [formConstants.repeatPassword]: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(onResetPassword({ ...values, email: email, token: tokenNewPassword }))
    },
  })

  return (
    <PageWrapper>
      <form onSubmit={formik.handleSubmit}>
        <PasswordField formik={formik} />
        <NewPasswordField formik={formik} />
        <SubmitBtn disabled={!formik.isValid} loading={loading} />
      </form>
      <ErrorForm error={error} />
    </PageWrapper>
  )
}

export default memo(NewPasswordPage)
