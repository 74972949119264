// Modules
import Cookies from 'js-cookie'
import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'

// Constants
import { appLanguages, appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'

// Constants
import { routers } from 'navigation/constants'

// Store
import { isAccessDeniedSelector, isAuthenticatedSelector } from 'store/actions/Auth/selectors'
import { getUserInfo } from 'store/actions/User/actions'
import { cookieAuthToken } from 'store/api'

const ProtectedRoute = ({ component: Component, autologin = false, ...rest }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const isAccessDenied = useSelector(isAccessDeniedSelector)
  const dispatch = useDispatch()

  const query = useQuery()
  const history = useHistory()
  const token = query.get('token')

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    if (autologin) {
      const expirationDate = new Date()
      expirationDate.setTime(expirationDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Two weeks in milliseconds
      // Save token in a cookie
      Cookies.set(cookieAuthToken, token, { expires: expirationDate })

      // Save default language in local storage
      localStorage.setItem(appLanguagesLocalStoreKey, appLanguages?.en)
      if (token) {
        dispatch(getUserInfo(token))
        setIsLoading(false)
        history.push('/' + routers.dashboard)
      }
    } else {
      setIsLoading(false)
    }
  }, [autologin, dispatch, token, history])

  const redirectedPath = isAccessDenied ? routers?.countryIsNotAvailable : routers?.login

  if (isLoading) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAccessDenied ? (
          <Redirect to={'/' + routers?.countryIsNotAvailable} />
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/' + redirectedPath} />
        )
      }
    />
  )
}

export default memo(ProtectedRoute)

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
