// Modules
import { BsFileEarmarkCheck } from 'react-icons/bs'
import { FaRegFileExcel } from 'react-icons/fa6'
import { IoMdEye } from 'react-icons/io'
import { MdFileUploadOff } from 'react-icons/md'
import { RiFileLine } from 'react-icons/ri'

// Languages
import {
  LN_KYC_STATUS_FAILED,
  LN_KYC_STATUS_IN_REVIEW,
  LN_KYC_STATUS_NEW,
  LN_KYC_STATUS_NOT_UPLOADED,
  LN_KYC_STATUS_SUCCESSFUL,
} from 'locales/constants'

export const Status = {
  NEW: 1,
  IN_REVIEW: 2,
  FAILED: 3,
  SUCCESSFUL: 4,
  NOT_UPLOADED: 5,
}

export const StatusConst = {
  new: 1,
  inReview: 2,
  failed: 3,
  successful: 4,
  notUploaded: 5,
}
export const VerificationTabValueDefault = 0

export const StatusLabels = {
  [StatusConst.new]: LN_KYC_STATUS_NEW,
  [StatusConst.inReview]: LN_KYC_STATUS_IN_REVIEW,
  [StatusConst.failed]: LN_KYC_STATUS_FAILED,
  [StatusConst.successful]: LN_KYC_STATUS_SUCCESSFUL,
  [StatusConst.notUploaded]: LN_KYC_STATUS_NOT_UPLOADED,
}

export const StatusIcons = {
  [StatusConst.new]: RiFileLine,
  [StatusConst.inReview]: IoMdEye,
  [StatusConst.failed]: FaRegFileExcel,
  [StatusConst.successful]: BsFileEarmarkCheck,
  [StatusConst.notUploaded]: MdFileUploadOff,
}

export const StatusIconsColor = {
  [StatusConst.new]: 'green.200',
  [StatusConst.inReview]: 'blue.200',
  [StatusConst.failed]: 'red.200',
  [StatusConst.successful]: 'green.200',
  [StatusConst.notUploaded]: 'red.200',
}
