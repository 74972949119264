// Modules
import { useFormik } from 'formik'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

// Assets
import refundPDF from 'assets/files/refund_policy.pdf'
import agreementPDF from 'assets/files/user_agreement.pdf'

// Languages
import {
  LN_AUTH_LOGIN_BTN,
  LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_LABEL,
  LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_LABEL,
  LN_USER_PERSONAL_INFORMATION_FIRST_NAME,
  LN_USER_PERSONAL_INFORMATION_LAST_NAME,
  LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER,
} from 'locales/constants'

// Components
import { CheckboxFormikField, InputFormikField, SubmitBtn } from 'components'
import { formConstants } from 'pages/Auth/Login/RegistrationForm/constants'
import EmailField from 'pages/Auth/components/EmailField'
import ErrorForm from 'pages/Auth/components/ErrorForm'
import NewPasswordField from 'pages/Auth/components/NewPasswordField'
import PasswordField from 'pages/Auth/components/PasswordField'

// Navigation
import { validationText } from 'utils/validation/constants'

// Utils
import {
  validationEmail,
  validationPassword,
  validationPhoneRequiredField,
  validationRepeatPassword,
  validationStringRequiredField,
} from 'utils/validation'

// Store
import { onRegistrationUser } from 'store/actions/Auth/actions'
import { registrationSelector } from 'store/actions/Auth/selectors'

const validationSchema = Yup.object({
  [formConstants.firstName]: validationStringRequiredField(),
  [formConstants.lastName]: validationStringRequiredField(),
  [formConstants.phone]: validationPhoneRequiredField(),
  ...validationEmail,
  ...validationPassword,
  ...validationRepeatPassword,
  [formConstants.agreeToTerms]: Yup.boolean()
    .oneOf([true], validationText.refundPolicy)
    .required(validationText.inputValueIsRequired),
  [formConstants.agreeToRefundPolicy]: Yup.boolean()
    .oneOf([true], validationText.userTerms)
    .required(validationText.inputValueIsRequired),
})

const RegistrationForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const registrationState = useSelector(registrationSelector)
  const loading = registrationState?.loading
  const error = registrationState?.error

  const formik = useFormik({
    initialValues: {
      [formConstants.firstName]: '',
      [formConstants.lastName]: '',
      [formConstants.phone]: '',
      [formConstants.email]: '',
      [formConstants.password]: '',
      [formConstants.repeatPassword]: '',
      [formConstants.agreeToTerms]: false,
      [formConstants.agreeToRefundPolicy]: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(onRegistrationUser(values))
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <InputFormikField
          formik={formik}
          fieldName={formConstants.firstName}
          placeholder={t(LN_USER_PERSONAL_INFORMATION_FIRST_NAME)}
          isRequired
          disabled={false}
        />
        <InputFormikField
          formik={formik}
          fieldName={formConstants.lastName}
          placeholder={t(LN_USER_PERSONAL_INFORMATION_LAST_NAME)}
          isRequired
          disabled={false}
        />
        <InputFormikField
          formik={formik}
          fieldName={formConstants.phone}
          placeholder={t(LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER)}
          isRequired
          disabled={false}
        />
        <EmailField formik={formik} isLabel={false} placeholder />
        <PasswordField formik={formik} isLabel={false} placeholder />
        <NewPasswordField formik={formik} isLabel={false} placeholder />
        <CheckboxFormikField
          formik={formik}
          isRequired
          label={LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_LABEL}
          linkLabel={agreementPDF}
          target="_blank"
          fieldName={formConstants.agreeToTerms}
        />
        <CheckboxFormikField
          formik={formik}
          isRequired
          label={LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_LABEL}
          linkLabel={refundPDF}
          target="_blank"
          fieldName={formConstants.agreeToRefundPolicy}
        />
        <SubmitBtn
          disabled={!formik.isValid || loading}
          loading={loading}
          title={t(LN_AUTH_LOGIN_BTN)}
          mb={1}
        />
      </form>
      <ErrorForm error={error} />
    </>
  )
}

export default memo(RegistrationForm)
