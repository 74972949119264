// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_STOP_LOSS,
  LN_TRANSACTION_CALC_TAKE_PROFIT,
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import InputMarketMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket/InputMarketMobileUI'
import RadioProfitGroup from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/RadioProfitGroup'
import {
  stopLoss,
  stopLossRadio,
  takeProfit,
} from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/constants'

// Hooks
import useCurrentColorTheme, { themeColorMode } from 'hooks/useCurrentColorTheme'

// Utils
import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import { Box, Button, Collapse, useDisclosure } from '@chakra-ui/react'

const TakeProfitPopoverMobileUI = (props) => {
  const {
    popoverLabelVal,
    takeProfitRadio,
    takeProfitRadioValue,
    onChangeTakeProfitRadio,
    takeProfitValue,
    onChangeTakeProfitInput,
    onIncreaseTakeProfit,
    onDecreaseTakeProfit,
    onResetValueTakeProfit,
    stopLossValue,
    onChangeStopLossInput,
    onIncreaseStopLoss,
    onDecreaseStopLoss,
    onResetValueStopLoss,
    stopLossRadioValue,
    onChangeStopLossRadio,
    currentCurrencySymbolPointValue,
  } = props

  const { t } = useTranslation()
  const { mainTextColor, isLightTheme, mainBorderColor } = useCurrentColorTheme()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onToggleOpen = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onClose, onOpen])

  return (
    <Box>
      <Button
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={1}
        onClick={onToggleOpen}
      >
        <TextXs fontWeight="400" fontSize="10px">
          {t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS)}
        </TextXs>
        <TextXs color={mainTextColor} fontSize="10px" pl="1px">
          {popoverLabelVal}
        </TextXs>
      </Button>
      <Box marginTop="-4px">
        <Collapse in={isOpen} animateOpacity>
          <Box
            bg={isLightTheme ? 'gray.50' : 'whiteAlpha.50'}
            p={2}
            borderBottomRightRadius={4}
            borderBottomLeftRadius={4}
            border="1px solid"
            borderColor={mainBorderColor}
            borderTop={0}
          >
            <Box mt={2} mb={2}>
              <TextXs fontWeight="600">{t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS)}</TextXs>
            </Box>

            <Box>
              <Box
                borderBottom="1px solid"
                borderColor={!isLightTheme ? 'brand.borderColor' : 'brand.borderColorLight'}
              >
                <RadioProfitGroup
                  name={takeProfitRadio}
                  value={takeProfitRadioValue}
                  onChange={onChangeTakeProfitRadio}
                />
                <InputMarketMobileUI
                  name={takeProfit}
                  label={t(LN_TRANSACTION_CALC_TAKE_PROFIT)}
                  boxProps={{ mt: 2 }}
                  value={roundDigitsAfterDots(takeProfitValue, currentCurrencySymbolPointValue)}
                  onChange={onChangeTakeProfitInput}
                  onIncreaseValue={onIncreaseTakeProfit}
                  onDecreaseValue={onDecreaseTakeProfit}
                  onResetValue={onResetValueTakeProfit}
                />
              </Box>
              <Box mt={2}>
                <RadioProfitGroup
                  name={stopLossRadio}
                  value={stopLossRadioValue}
                  onChange={onChangeStopLossRadio}
                />
                <InputMarketMobileUI
                  name={stopLoss}
                  label={t(LN_TRANSACTION_CALC_STOP_LOSS)}
                  boxProps={{ mt: 2 }}
                  value={roundDigitsAfterDots(stopLossValue, currentCurrencySymbolPointValue)}
                  onChange={onChangeStopLossInput}
                  onIncreaseValue={onIncreaseStopLoss}
                  onDecreaseValue={onDecreaseStopLoss}
                  onResetValue={onResetValueStopLoss}
                />
              </Box>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default memo(TakeProfitPopoverMobileUI)
