export const routers = {
  login: 'login',
  dashboard: 'dashboard',
  user: 'user',
  logout: 'logout',
  forgotPassword: 'forgot-password',
  newPassword: 'reset-password',
  landing: '/',
  countryIsNotAvailable: 'wrong-country',
}
