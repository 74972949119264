// Modules
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_USER_WITHDRAWAL_TABLE_WITHDRAWAL_REQUESTS_TITLE } from 'locales/constants'

// Components
import HistoryPageWrapper from 'components/HistoryPageWrapper/HistoryPageWrapper'
import TableHistoryBase from 'components/Table/TableHistory/TableHistoryBase'
import { statusConstants } from 'components/Table/TableHistory/constants'
import InfoModal from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/InfoModal'
import { columns } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'
import { typeWithdrawalIcon } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'
import useWithdrawalHistoryTablePagination from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/useWithdrawalHistoryTablePagination'

// Constants
import { FinanceTypeIds } from 'constants/finance'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { getWithdrawalHistory } from 'store/actions/Withdrawal/actions'
import {
  loadingCancelWithdrawalSelector,
  loadingCreateWithdrawalSelector,
  loadingWithdrawalHistoryListSelector,
  withdrawalHistoryListSelector,
} from 'store/actions/Withdrawal/selectors'

import { Box, Spinner } from '@chakra-ui/react'

const WithdrawalHistoryWrapper = () => {
  const dispatch = useDispatch()
  const { userTradingAccountId } = useUserTradingAccountId()

  const loadingList = useSelector(loadingWithdrawalHistoryListSelector)
  const loadingCancel = useSelector(loadingCancelWithdrawalSelector)
  const loadingCreate = useSelector(loadingCreateWithdrawalSelector)

  const withdrawalHistory = useSelector(withdrawalHistoryListSelector)
  const listHistory = useMemo(() => withdrawalHistory?.data || [], [withdrawalHistory?.data])

  useEffect(() => {
    dispatch(getWithdrawalHistory({ userTradingAccountId }))
  }, [dispatch, userTradingAccountId])

  const listTransform = useMemo(() => {
    if (!listHistory || listHistory?.length === 0) return []
    return listHistory?.map((el) => {
      const parsedDescription = el?.client_description ? JSON.parse(el?.client_description) : null

      return {
        id: el?.finance_id,
        date: el?.deposit_date,
        amount: el?.deposit_amount,
        currency: el?.currency,
        status: el?.status?.id,
        description: parsedDescription,
        typeWithdrawal: parsedDescription?.withdrawalMethod,
        typeWithdrawalIcon: typeWithdrawalIcon?.[parsedDescription?.withdrawalMethod] || null,
      }
    })
  }, [listHistory])

  const { paginationInfo, onPreviousPage, onNextPage, onPage } =
    useWithdrawalHistoryTablePagination()

  const loading = loadingList || loadingCreate

  return (
    <HistoryPageWrapper
      loading={loading}
      title={LN_USER_WITHDRAWAL_TABLE_WITHDRAWAL_REQUESTS_TITLE}
      list={listTransform}
    >
      <>
        <TableHistoryBase
          list={listTransform}
          loading={loading}
          paginationInfo={paginationInfo}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          onPage={onPage}
          columns={columns}
          typeFinance={FinanceTypeIds.withdrawal}
          loadingOverflow={loadingCancel}
        />
        <InfoModal />
      </>
    </HistoryPageWrapper>
  )
}

export default memo(WithdrawalHistoryWrapper)
