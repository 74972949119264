// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { ErrorMessage } from 'components'

// UI
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react'

const SelectFormikField = (props) => {
  const { formik, fieldName, optionsList, label, disabled, ...args } = props

  const { t } = useTranslation()

  return (
    <Box mb={3} {...args}>
      <FormControl isRequired>
        <FormLabel fontSize={{ base: 'sm', sm: 'md' }}>{t(label)}</FormLabel>
        <Select variant="filled" isDisabled={disabled} {...formik.getFieldProps(fieldName)}>
          {optionsList?.map((el) => (
            <option key={el?.id} value={el?.id}>
              {t(el?.name)}
            </option>
          ))}
        </Select>

        {formik.touched[fieldName] && formik.errors[fieldName] && (
          <ErrorMessage error={t(formik.errors[fieldName])} fontSize="sm" />
        )}
      </FormControl>
    </Box>
  )
}

export default memo(SelectFormikField)
