// Types
import {
  DELETE_KYC_FIELD,
  GET_KYC_FIELD_LIST,
  SEND_KYC_FIELD,
  SET_KYC_FIELD_LIST,
  SET_VERIFICATION_FIELDS_TAB,
} from 'store/actions/KYC/types'

export const getKYCFieldsList = () => ({
  type: GET_KYC_FIELD_LIST,
})

export const setKYCFieldsList = (payload) => ({
  type: SET_KYC_FIELD_LIST,
  payload,
})
export const sendKYCField = (payload) => ({
  type: SEND_KYC_FIELD,
  payload,
})
export const deleteKYCField = (payload) => ({
  type: DELETE_KYC_FIELD,
  payload,
})
export const setVerificationFieldsTab = (payload) => ({
  type: SET_VERIFICATION_FIELDS_TAB,
  payload: payload,
})
