// Modules
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_HISTORY_NO_DATA } from 'locales/constants'

// Components
import { LoadingBlock, NoDataMessage, TextSm } from 'components'
import VerificationTabs from 'pages/User/components/Content/Verification/components/VerificationTabs'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Store
import { deleteKYCField, getKYCFieldsList, sendKYCField } from 'store/actions/KYC/actions'
import {
  kycFieldListLoadingSelector,
  kycFieldListSelector,
  verificationTabValueSelector,
} from 'store/actions/KYC/selectors'

// UI
import { Box } from '@chakra-ui/react'

// Components
import { MainTitle } from '../Withdrawal'

const Verification = () => {
  const { t } = useTranslation()
  const [fields, setFields] = useState({})

  const dispatch = useDispatch()
  const kycFieldList = useSelector(kycFieldListSelector)
  const kycFieldListLoading = useSelector(kycFieldListLoadingSelector)
  const verificationTabValue = useSelector(verificationTabValueSelector)

  const activeList = useMemo(() => {
    const values = _.values(kycFieldList)
    const filtered = values?.filter((el) => el?.is_active)
    return filtered || []
  }, [kycFieldList])

  useEffect(() => {
    dispatch(getKYCFieldsList())
  }, [dispatch])

  useEffect(() => {
    setFields(kycFieldList)
  }, [kycFieldList])

  const sendImageHandler = useCallback(
    (fieldName, file) => {
      dispatch(sendKYCField({ name: fieldName, file: file }))
    },
    [dispatch],
  )

  const deleteFieldHandler = useCallback(
    (fieldName) => {
      dispatch(deleteKYCField({ name: fieldName }))
    },
    [dispatch],
  )

  return (
    <Box w="100%" p={3}>
      <MainTitle title={t(sidebarList[3]?.title)} />
      {kycFieldListLoading ? (
        <LoadingBlock />
      ) : (
        <>
          {!_.isEmpty(fields) ? (
            <VerificationTabs
              fields={activeList}
              uploadFile={sendImageHandler}
              deleteFieldHandler={deleteFieldHandler}
              tabValue={verificationTabValue}
            />
          ) : (
            <NoDataMessage />
          )}
        </>
      )}
    </Box>
  )
}

export default memo(Verification)
