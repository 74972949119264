// Modules
import axios from 'axios'

// Store
import { API_BASE_URL } from 'store/api/index'

export const loginUserAPI = async ({ email, password }) => {
  const response = await axios.post(`${API_BASE_URL}/login`, {
    email: email,
    password: password,
  })
  // console.log('loginUserAPI response.data', response.data)
  return response.data
}

export const forgotPasswordAPI = async ({ email }) => {
  const response = await axios.post(`${API_BASE_URL}/password/email`, {
    email: email,
  })
  // console.log('forgotPasswordAPI response.data', response.data)
  return response.data
}

export const resetPasswordAPI = async ({ email, token, password, repeatPassword }) => {
  const response = await axios.post(`${API_BASE_URL}/password/reset`, {
    email: email,
    token: token,
    password: password,
    password_confirmation: repeatPassword,
  })
  // console.log('resetPasswordAPI response.data', response.data)
  return response.data
}

export const registrationUserAPI = async ({
  email,
  password,
  repeatPassword,
  firstName,
  lastName,
  phone,
}) => {
  const response = await axios.post(`${API_BASE_URL}/register`, {
    first_name: firstName,
    last_name: lastName,
    phone: phone,
    email: email,
    password: password,
    password_confirmation: repeatPassword,
  })
  // console.log('registrationUserAPI response.data', response.data)
  return response.data
}
