// Modules
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_KYC_FILE_UPLOAD_BTN, LN_KYC_STATUS } from 'locales/constants'

// Components
import { DNDFileUploader, SubmitBtn, TabPanelBase, TextMd } from 'components'
import PreviewList from 'components/DNDFileUploader/PreviewList'
import {
  StatusConst,
  StatusLabels,
} from 'pages/User/components/Content/Verification/components/VerificationTabs/constants'

// UI
import { Box, Flex, Icon } from '@chakra-ui/react'

const VerificationTabPanel = ({ el, uploadFile, deleteFieldHandler }) => {
  const { t } = useTranslation()

  const [file, setFile] = useState([])

  useEffect(() => {
    setFile(el?.url ? [el?.url] : [])
  }, [el?.url])

  const onChooseFile = useCallback(
    (value) => {
      setFile(value)
    },
    [el?.filename],
  )

  const onUploadFile = useCallback(() => {
    console.log('onUploadFile', file?.[0])
    uploadFile(el?.filename, file?.[0])
  }, [el?.filename, file])

  const onDeleteFile = useCallback(
    (e) => {
      e.stopPropagation()
      if (el?.url) {
        deleteFieldHandler(el?.filename)
      } else {
        setFile([])
      }
    },
    [el?.filename],
  )

  const isDeleted = useMemo(
    () => el?.url || el?.status?.id !== StatusConst.successful,
    [el?.url, el?.status?.id],
  )

  return (
    <TabPanelBase key={el?.label + 'tab-panel'}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <TextMd fontWeight="bold">
          {t(LN_KYC_STATUS)}: {t(StatusLabels[el?.status.id])}
        </TextMd>
        {(el?.url || file) && el?.status?.id !== StatusConst.successful && (
          <Box>
            <SubmitBtn
              disabled={el?.url === file?.[0] || _.isEmpty(file)}
              title={t(LN_KYC_FILE_UPLOAD_BTN)}
              onClick={onUploadFile}
            />
          </Box>
        )}
      </Flex>
      <Flex w="100%" justifyContent="space-between">
        <DNDFileUploader
          file={file}
          setFile={onChooseFile}
          isDeleted={el?.url || el?.status?.id !== StatusConst.successful}
          onDelete={onDeleteFile}
          width={file && file?.length > 0 ? '49%' : '100%'}
          height="316px"
        />
        <PreviewList
          file={file}
          isDeleted={isDeleted}
          onDelete={onDeleteFile}
          width="49%"
          height="316px"
          iconWidth="17px"
          deleteBtnStyles={{
            height: '24px',
            width: '24px',
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Flex>
    </TabPanelBase>
  )
}

export default memo(VerificationTabPanel)
const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}
