// Modules
import axios from 'axios'

// Store
import { API_BASE_URL, GetAuthToken, axiosInstance } from 'store/api/index'

export const getUserAPI = async ({ token }) => {
  const response = await axios.get(`${API_BASE_URL}/user`, {
    headers: {
      ...GetAuthToken(token),
    },
  })
  // console.log('getUserAPI response.data', response.data)
  return response.data
}

export const editUserInfoAPI = async (params) => {
  const response = await axiosInstance.patch(`${API_BASE_URL}/user`, params)
  // console.log('editUserInfoAPI response.data', response.data)
  return response.data
}

export const onVerificationEmailUserAPI = async () => {
  const response = await axiosInstance.get('/email/verification-notification')
  // console.log('onVerificationEmailUserAPI response.data', response.data)
  return response.data
}

export const onGetCountriesListAPI = async () => {
  const response = await axiosInstance.get('/countries/list')
  // console.log('onGetCountriesListAPI response.data', response.data)
  return response.data
}

export const onGetUserBalance = async ({ tradeAccountId }) => {
  const response = await axiosInstance.get('/balance', {
    params: {
      trade_account: tradeAccountId,
    },
  })
  return response.data
}

export const onPutUserActiveAt = async () => {
  const response = await axiosInstance.patch('/user/active-at')
  return response.data
}

export const onGetDepositFieldsAPI = async () => {
  const response = await axiosInstance.get('/deposit/fields')
  return response.data
}

export const onGetWithdrawalMethodsFieldsAPI = async () => {
  const response = await axiosInstance.get('/withdrawal-methods/fields')
  return response.data
}

export const createWithdrawalAPI = async ({ tradingAccountId, amount, ...params }) => {
  const data = {
    trading_account_id: tradingAccountId,
    amount: amount,
    client_description: JSON.stringify(params),
  }
  const response = await axiosInstance.post(`${API_BASE_URL}/finance/withdrawal`, data)
  // console.log('createWithdrawalAPI response.data', response.data)
  return response.data
}

export const cancelWithdrawalAPI = async ({ financeId }) => {
  const response = await axiosInstance.post(`${API_BASE_URL}/finance/withdrawal/cancel`, {
    finance_id: financeId,
  })
  // console.log('cancelWithdrawalAPI response.data', response.data)
  return response.data
}

// financeTypeIds - must be an array list - values - WITHDRAWAL = 1 DEPOSIT = 2 CORRECTION = 3 BONUS = 4
// statusIds - must be an array list - values - ATTEMPT = 1, PENDING = 2, SUSPENDED = 3, FAILED = 4, SUCCESS = 5
// depositTypeIds - must be an array list - values - UP_SALE = 1, FTD = 2 PRE_FTD = 3
export const onGetFinanceAPI = async ({
  tradeAccountId,
  financeTypeIds,
  statusIds,
  depositTypeIds,
  page,
  perPage,
}) => {
  const financeTypeIdsValue = financeTypeIds ? { finance_type_ids: financeTypeIds } : {}
  const statusIdsValue = statusIds ? { status_ids: statusIds } : {}
  const depositTypeIdsValue = depositTypeIds ? { deposit_type_ids: depositTypeIds } : {}
  const pageValue = page ? { page } : {}
  const perPageValue = perPage ? { per_page: perPage } : {}

  const response = await axiosInstance.get('/finance', {
    params: {
      trading_account_id: tradeAccountId,
      ...financeTypeIdsValue,
      ...statusIdsValue,
      ...depositTypeIdsValue,
      ...pageValue,
      ...perPageValue,
    },
  })
  // console.log('onGetFinanceAPI response.data', response.data)
  return response.data
}
