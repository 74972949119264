// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import {
  LN_NOTIFICATIONS_CREATE_WITHDRAWAL,
  LN_NOTIFICATIONS_GET_USER_DEPOSIT_HISTORY,
  LN_NOTIFICATIONS_GET_WITHDRAWAL_FIELDS,
} from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'

import { FinanceTypeIds } from 'constants/finance'

// Store
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  setDepositUserHistory,
  setLoadingUserDepositFields,
} from 'store/actions/UserDeposit/actions'
import {
  getWithdrawalHistory,
  setCancelWithdrawalSuccess,
  setLoadingCancelWithdrawal,
  setLoadingCreateWithdrawal,
  setWithdrawalHistory,
  setWithdrawalMethods,
} from 'store/actions/Withdrawal/actions'
import {
  CANCEL_WITHDRAWAL,
  CREATE_WITHDRAWAL,
  GET_WITHDRAWAL_HISTORY,
  GET_WITHDRAWAL_METHODS,
} from 'store/actions/Withdrawal/types'
import {
  cancelWithdrawalAPI,
  createWithdrawalAPI,
  onGetFinanceAPI,
  onGetWithdrawalMethodsFieldsAPI,
} from 'store/api/user'
import { getErrorMessage, onUserNotAuth } from 'store/sagas/utils'

function* getWithdrawalHistorySaga({ payload }) {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token && payload?.userTradingAccountId) {
      const data = yield call(onGetFinanceAPI, {
        tradeAccountId: payload?.userTradingAccountId,
        financeTypeIds: [FinanceTypeIds.withdrawal],
        page: payload?.page,
      })
      yield put(setWithdrawalHistory(data))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(
        toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_USER_DEPOSIT_HISTORY)),
      ),
    )

    yield put(setLoadingUserDepositFields(false))
    yield call(onUserNotAuth, error)
  }
}

function* getWithdrawalMethodsSaga() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const response = yield call(onGetWithdrawalMethodsFieldsAPI)
      yield put(setWithdrawalMethods(response))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(
        toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_WITHDRAWAL_FIELDS)),
      ),
    )

    yield call(onUserNotAuth, error)
  }
}

function* createWithdrawalSaga({ payload }) {
  yield put(setLoadingCreateWithdrawal(true))
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
    const userTradingAccountId = currentTradingAccount?.trading_account_id

    if (token) {
      const response = yield call(createWithdrawalAPI, {
        ...payload?.fields,
        tradingAccountId: userTradingAccountId,
      })

      yield put(
        getWithdrawalHistory({
          userTradingAccountId,
          page: 1,
        }),
      )
      yield put(setLoadingCreateWithdrawal(false))
      payload?.resetForm()
      yield put(showToast(toastConstructor(toastType.success, response?.[0]?.message)))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_CREATE_WITHDRAWAL))),
    )

    yield put(setLoadingCreateWithdrawal(false))
    yield call(onUserNotAuth, error)
  }
}

function* cancelWithdrawalSaga({ payload }) {
  yield put(setLoadingCancelWithdrawal(true))
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const response = yield call(cancelWithdrawalAPI, payload)
      yield put(setLoadingCancelWithdrawal(false))
      yield put(setCancelWithdrawalSuccess(payload))
      yield put(showToast(toastConstructor(toastType.success, response?.[0]?.message)))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_CREATE_WITHDRAWAL))),
    )

    yield put(setLoadingCancelWithdrawal(false))
    yield call(onUserNotAuth, error)
  }
}

export function* watchUserWithdrawalSaga() {
  yield takeEvery(GET_WITHDRAWAL_METHODS, getWithdrawalMethodsSaga)
  yield takeEvery(CREATE_WITHDRAWAL, createWithdrawalSaga)
  yield takeEvery(CANCEL_WITHDRAWAL, cancelWithdrawalSaga)
  yield takeEvery(GET_WITHDRAWAL_HISTORY, getWithdrawalHistorySaga)
}
