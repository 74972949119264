// Modules
import _ from 'lodash'
import { memo } from 'react'

// Components
import { TextXs } from 'components'
import InnerExpandableList from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/InnerExpandableList/InnerExpandableList'
import ListPanel from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel'

// Constants
import { CurrencyGroups } from 'constants/currency'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { getInnerAccordionGroupedList } from 'utils/getInnerAccordionTitles'

// UI
import { Box } from '@chakra-ui/react'

const ListPanelWrapper = (props) => {
  const { list, isMoreThanOneTrue, groupId, filteredGroupedAssertListByAssetLabel, foundKeys } =
    props

  const { mainTextColor } = useCurrentColorTheme()

  const showContent = () => {
    if (groupId === CurrencyGroups.forex) {
      const groupedList = getInnerAccordionGroupedList(list, groupId)
      return <InnerExpandableList list={groupedList} />
    } else {
      return !isMoreThanOneTrue ? (
        <ListPanel list={list?.[groupId]} />
      ) : (
        foundKeys?.map((item, idx) => {
          const listGroup = list?.[groupId]
          const assertGroupedItemList = filteredGroupedAssertListByAssetLabel?.[item]
          const filteredList = _.filter(listGroup, (item) =>
            _.some(assertGroupedItemList, { symbol_description: item?.name }),
          )

          return filteredList && filteredList?.length > 0 ? (
            <Box key={idx + item} pt={2}>
              <TextXs pl={1} pb={1} color={mainTextColor}>
                {item}
              </TextXs>

              <ListPanel list={filteredList} />
            </Box>
          ) : null
        })
      )
    }
  }

  return <>{showContent()}</>
}

export default memo(ListPanelWrapper)
