// Modules
import _ from 'lodash'
import { eventChannel } from 'redux-saga'
import { call, put, take, takeEvery } from 'redux-saga/effects'

// Constants
import { defaultProps } from 'components/TVChartContainer/constants'

// Store
import {
  wsCurrencyMarketChangeCurrencyValue,
  wsCurrencyMarketMessageReceived,
} from 'store/actions/CurrencySocket/actions'
import {
  WS_CONNECT_CURRENCY_MARKET,
  WS_DISCONNECT_CURRENCY_MARKET,
} from 'store/actions/CurrencySocket/types'
import { URL_SOCKET_PATH, WS_PORT } from 'store/api'

const TYPE_CLOSE = 'CLOSE'
const TYPE_ERROR = 'ERROR'
const TYPE_MESSAGE = 'MESSAGE'
const TYPE_OPEN = 'OPEN'

function* handleMessages(action) {
  yield put(wsCurrencyMarketMessageReceived(action.payload))
  if (action?.type === TYPE_MESSAGE) {
    yield put(wsCurrencyMarketChangeCurrencyValue(action.payload))
  }
}

// Saga
export function* watchCurrencyMarketWebSocket() {
  while (true) {
    const { options } = yield take(WS_CONNECT_CURRENCY_MARKET)

    const socket = yield call(connectWebSocket, options)
    yield takeEvery(socket, handleMessages)
    yield take(WS_DISCONNECT_CURRENCY_MARKET)
    socket.close()
  }
}

function connectWebSocket(options) {
  return eventChannel((emitter) => {
    // for socket parameters groups['crypto'], channels['BTC], structure - { group: '', channels: [], intervalGraph: '1D' },

    let prepareStrForSocket = ''
    if (options?.group && options?.group?.length > 0) {
      // prepareStrForSocket = `groups[]=all`
      prepareStrForSocket = `groups[]=${options?.group}`
      // prepareStrForSocket = _.map(
      //   group?.groups,
      //   (str) => `groups[]=${encodeURIComponent(str)}`,
      // ).join('&')
    }
    if (options?.channels && options?.channels?.length > 0) {
      if (prepareStrForSocket && prepareStrForSocket?.length > 0) {
        prepareStrForSocket += '&'
      }
      prepareStrForSocket += _.map(
        _.uniq(options?.channels),
        (str) => `channels[]=${encodeURIComponent(str)}`,
      ).join('&')
      // prepareStrForSocket = channels + `&interval=${'1D'}`
    }
    if (options?.group && options?.group?.length > 0 && options?.groupLimit) {
      prepareStrForSocket += `&groupLimit=${encodeURIComponent(options?.groupLimit)}`
    }

    // console.log('prepareStrForSocket', prepareStrForSocket)

    if (!prepareStrForSocket) {
      return () => {}
    }

    const socket = new WebSocket(
      `ws://${URL_SOCKET_PATH}:${WS_PORT}?${prepareStrForSocket}&interval=${
        options?.intervalGraph || defaultProps.interval
      }`,
      // `ws://${URL_SOCKET_PATH}:${WS_PORT}?${prepareStrForSocket}&interval=${'1D'}`,
    )
    // const socket = new WebSocket(`ws://${URL_SOCKET_PATH}:${WS_PORT}?groups[]=${group?.[0]}`)

    socket.onopen = () => {
      // console.log('WebSocket connection opened')
      emitter({ type: TYPE_OPEN })
    }

    socket.onmessage = (event) => {
      // console.log('Received message:', event.data)
      emitter({ type: TYPE_MESSAGE, payload: JSON.parse(event.data) })
    }

    socket.onclose = () => {
      // console.log('WebSocket connection closed')
      emitter({ type: TYPE_CLOSE })

      // emitter.close() // Close the channel
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      emitter({ type: TYPE_ERROR, payload: error })
    }

    // Return unsubscribe function
    return () => {
      // console.log('Socket off')
      socket.close()
    }
  })
}
