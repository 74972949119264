// Modules
import { Suspense, lazy, memo } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

// Language
import 'locales/i18n'

// Components
import ForgotPasswordPage from 'pages/Auth/ForgotPassword'
import LoginPage from 'pages/Auth/Login'
import NewPasswordPage from 'pages/Auth/NewPassword'
import CountryIsNotAvailablePage from 'pages/CountryIsNotAvailable'
import Dashboard from 'pages/Dashboard'
import Landing from 'pages/Landing'
import NotFoundPage from 'pages/NotFound'
import User from 'pages/User'

// Navigation
import { routers } from 'navigation/constants'

import IsUserLoginRoute from 'navigation/IsUserLoginRoute'
import ProtectedRoute from 'navigation/ProtectedRoute'

// Pages
// const LoginPage = lazy(() => import('pages/Auth/Login'))
// const ForgotPasswordPage = lazy(() => import('pages/Auth/ForgotPassword'))
// const User = lazy(() => import('pages/User'))
// const NewPasswordPage = lazy(() => import('pages/Auth/NewPassword'))
// const NotFoundPage = lazy(() => import('pages/NotFound'))

const Navigation = () => {
  return (
    <Router>
      <Suspense>
        {/*<Suspense fallback={<TextLg>Page is loading</TextLg>}>*/}
        <Switch>
          <ProtectedRoute
            path={`/${routers.dashboard}/autologin/`}
            component={Dashboard}
            autologin
          />
          <ProtectedRoute path={`/${routers.dashboard}`} component={Dashboard} exact />
          <ProtectedRoute path={`/${routers.user}`} component={User} />
          <IsUserLoginRoute path={`/${routers.login}`} component={LoginPage} />
          <IsUserLoginRoute path={`/${routers.forgotPassword}`} component={ForgotPasswordPage} />
          <IsUserLoginRoute path={`/${routers.newPassword}`} component={NewPasswordPage} />
          <Route path={routers.landing} exact>
            <Landing />
          </Route>
          <Route path={`/${routers.countryIsNotAvailable}`} exact>
            <CountryIsNotAvailablePage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}
export default memo(Navigation)
