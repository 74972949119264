// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_NEW_PASSWORD } from 'locales/constants'

// Components
import { ErrorMessage, InputBase } from 'components'
import { formConstants } from 'pages/Auth/Login/LoginForm/constants'

// UI
import { FormControl, FormLabel, InputGroup } from '@chakra-ui/react'

const NewPasswordField = (props) => {
  const { formik, placeholder, isLabel = true } = props

  const { t } = useTranslation()
  const { repeatPassword } = formConstants

  return (
    <FormControl isRequired mb={4}>
      {isLabel && <FormLabel>{t(LN_AUTH_NEW_PASSWORD)}</FormLabel>}
      <InputGroup>
        <InputBase
          type="password"
          {...formik.getFieldProps(repeatPassword)}
          placeholder={placeholder ? t(LN_AUTH_NEW_PASSWORD) : ''}
        />
      </InputGroup>
      {formik.touched[repeatPassword] && formik.errors[repeatPassword] && (
        <ErrorMessage error={t(formik.errors[repeatPassword])} fontSize="sm" />
      )}
    </FormControl>
  )
}

export default memo(NewPasswordField)
