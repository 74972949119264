// Modules
import { memo } from 'react'
import { RxCross2 } from 'react-icons/rx'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex, Icon, Tab, TabPanel, styled } from '@chakra-ui/react'

export const TabBase = memo(({ title, icon = false, iconWidth = '20px', iconColor }) => {
  const { mainBorderColor, isLightTheme } = useCurrentColorTheme()

  return (
    <Tab
      transitionProperty="none"
      transitionDuration="unset"
      gap={1}
      _selected={{
        backgroundColor: isLightTheme ? '#ffffff78' : '#ffffff0a',
        borderColor: mainBorderColor,
        borderBottom: 0,
        outline: '0px',
        outlineOffset: '0px',
        fontWeight: 500,
      }}
    >
      {title}
      {icon && <Icon as={icon} w={iconWidth} color={iconColor} />}
    </Tab>
  )
})

const StyledTabPanel = styled(TabPanel, {
  baseStyle: {
    height: '100%',
  },
})

export const TabPanelBase = memo(({ children }) => {
  return (
    <StyledTabPanel>
      <Flex
        height="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={4}
      >
        {children}
      </Flex>
    </StyledTabPanel>
  )
})

TabBase.displayName = 'TabBase'
TabPanelBase.displayName = 'TabPanelBase'
