// Modules
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

// Constants
import { routers } from 'navigation/constants'

// Store
import { isAccessDeniedSelector, isAuthenticatedSelector } from 'store/actions/Auth/selectors'

const IsUserLoginRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const isAccessDenied = useSelector(isAccessDeniedSelector)

  return (
    <Route
      {...rest}
      render={(props) =>
        isAccessDenied ? (
          <Redirect to={'/' + routers?.countryIsNotAvailable} />
        ) : isAuthenticated ? (
          <Redirect to={'/' + routers?.dashboard} />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default memo(IsUserLoginRoute)
