// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const withdrawalHistoryListSelector = createSelector(
  selfSelector,
  (self) => self.withdrawalHistoryList,
)

export const loadingWithdrawalHistoryListSelector = createSelector(
  selfSelector,
  (self) => self.loadingWithdrawalHistoryList,
)

export const modalWithdrawalItemInfoSelector = createSelector(
  selfSelector,
  (self) => self.modalWithdrawalItemInfo,
)

export const isWithdrawalModalInfoOpenSelector = createSelector(
  selfSelector,
  (self) => self.isWithdrawalModalInfoOpen,
)

export const withdrawalMethodsSelector = createSelector(
  selfSelector,
  (self) => self.withdrawalMethods,
)

export const withdrawalMethodsLoadingSelector = createSelector(
  selfSelector,
  (self) => self.withdrawalMethodsLoading,
)

export const loadingCreateWithdrawalSelector = createSelector(
  selfSelector,
  (self) => self.loadingCreateWithdrawal,
)

export const loadingCancelWithdrawalSelector = createSelector(
  selfSelector,
  (self) => self.loadingCancelWithdrawal,
)
