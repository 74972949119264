// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import DarkModeIcon from 'assets/dark-mode'
import LightModeIcon from 'assets/light-mode'

// Hooks
import { themeColorMode, themeSwitcherKey } from 'hooks/useCurrentColorTheme'

// Store
import { onSetSelectedTheme } from 'store/actions/User/actions'
import { selectedThemeSelector } from 'store/actions/User/selectors'

// UI
import { Flex, Switch, useColorMode } from '@chakra-ui/react'

const ThemeSwitcher = () => {
  const { toggleColorMode } = useColorMode()
  const selectedTheme = useSelector(selectedThemeSelector)

  const dispatch = useDispatch()

  const onChangeTheme = useCallback(
    (event) => {
      const changeValue = event.target.checked ? themeColorMode.dark : themeColorMode.light
      localStorage.setItem(themeSwitcherKey, changeValue)
      toggleColorMode()
      dispatch(onSetSelectedTheme(changeValue))
    },
    [toggleColorMode, dispatch],
  )

  return (
    <Flex align="center">
      {selectedTheme === themeColorMode.dark && <LightModeIcon />}
      <Switch
        isChecked={selectedTheme === themeColorMode.dark}
        onChange={onChangeTheme}
        colorScheme="blue"
        size="md"
        ml={selectedTheme === themeColorMode.dark ? 1 : 0}
        mr={selectedTheme !== themeColorMode.dark ? 1 : 0}
      />
      {selectedTheme !== themeColorMode.dark && <DarkModeIcon />}
    </Flex>
  )
}

export default memo(ThemeSwitcher)
