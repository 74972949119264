// Modules
import { useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

// Languages
import {
  LN_USER_PERSONAL_INFORMATION_CONFIRMED_BTN,
  LN_USER_PERSONAL_INFORMATION_CONFIRM_BTN,
  LN_USER_PERSONAL_INFORMATION_COUNTRY,
  LN_USER_PERSONAL_INFORMATION_EMAIL,
  LN_USER_PERSONAL_INFORMATION_FIRST_NAME,
  LN_USER_PERSONAL_INFORMATION_LAST_NAME,
  LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER,
  LN_USER_PERSONAL_INFORMATION_SUBMIT_BTN,
} from 'locales/constants'

// Components
import { InputFormikField, LoadingBlock, SubmitBtn } from 'components'
import SelectFormikField from 'components/Form/SelectFormikField'
import { formConstants } from 'pages/User/components/Content/PersonalInfo/UserInfoForm/constants'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
import useGetUserCountyCode from 'hooks/useGetUserCountyCode'

// Utils
import {
  validationEmail,
  validationPhoneRequiredField,
  validationStringRequiredField,
} from 'utils/validation'

// Store
import { editUserInfo, onConfirmUserEmail } from 'store/actions/User/actions'
import {
  loadingConfirmEmailSelector,
  loadingEditingUserInfoSelector,
  userInfoSelector,
} from 'store/actions/User/selectors'

// UI
import { Box, Button, Flex } from '@chakra-ui/react'

const validationSchema = Yup.object({
  [formConstants.firstName]: validationStringRequiredField(),
  [formConstants.lastName]: validationStringRequiredField(),
  [formConstants.phone]: validationPhoneRequiredField(),
  ...validationEmail,
})

const UserInfoForm = (props) => {
  const { loading } = props
  const { t } = useTranslation()

  const { disableEditingProfileDataOnTradingPlatform } = useCheckUserRestrictions()

  const dispatch = useDispatch()
  const userInfo = useSelector(userInfoSelector)
  const loadingConfirmEmail = useSelector(loadingConfirmEmailSelector)
  const loadingEditingUserInfo = useSelector(loadingEditingUserInfoSelector)

  const { countriesListLoading, countriesList } = useGetUserCountyCode()

  const onConfirmEmail = useCallback(() => dispatch(onConfirmUserEmail()), [dispatch])

  const isInputDisabled = disableEditingProfileDataOnTradingPlatform

  const formik = useFormik({
    initialValues: {
      [formConstants.firstName]: userInfo?.first_name,
      [formConstants.lastName]: userInfo?.last_name,
      [formConstants.countryName]: userInfo?.country_id,
      [formConstants.phone]: userInfo?.phone,
      [formConstants.email]: userInfo?.email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        first_name: values?.[formConstants.firstName],
        last_name: values?.[formConstants.lastName],
        email: values?.[formConstants.email],
        phone: values?.[formConstants.phone],
        country_id: values?.[formConstants.countryName],
      }
      dispatch(editUserInfo(params))
    },
  })

  useEffect(() => {
    if (userInfo) {
      formik.setFieldValue(formConstants.firstName, userInfo?.first_name)
      formik.setFieldValue(formConstants.lastName, userInfo?.last_name)
      formik.setFieldValue(formConstants.countryName, userInfo?.country_id)
      formik.setFieldValue(formConstants.phone, userInfo?.phone)
      formik.setFieldValue(formConstants.email, userInfo?.email)
    }
  }, [userInfo])

  const isDisabledConfirmBtn = useMemo(() => {
    if (isInputDisabled) {
      return userInfo?.email_verified_at
    } else {
      if (formik.values?.[formConstants.email] === '') {
        return true
      }
      if (
        formik.touched?.[formConstants.email] &&
        formik.values?.[formConstants.email] !== userInfo?.email
      ) {
        return true
      }
    }
  }, [isInputDisabled, formik.values?.[formConstants.email]])

  return loading || countriesListLoading ? (
    <LoadingBlock />
  ) : (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Flex gap={5} w="100%" flexDirection={{ base: 'column', '2sm': 'row' }}>
          <Flex gap={3} flexDirection="column" w={{ base: '100%', '2sm': '50%' }}>
            <InputFormikField
              formik={formik}
              fieldName={formConstants.firstName}
              label={LN_USER_PERSONAL_INFORMATION_FIRST_NAME}
              disabled={isInputDisabled}
              isRequired
            />
            <InputFormikField
              formik={formik}
              fieldName={formConstants.lastName}
              label={LN_USER_PERSONAL_INFORMATION_LAST_NAME}
              disabled={isInputDisabled}
              isRequired
            />
            <Flex alignItems="end" w="100%" gap={2}>
              <InputFormikField
                formik={formik}
                fieldName={formConstants.email}
                label={LN_USER_PERSONAL_INFORMATION_EMAIL}
                disabled={isInputDisabled}
                type="email"
                w="100%"
                mb={0}
                isRequired
                isInnerElement={
                  <Button
                    colorScheme="gray"
                    w="35%"
                    isDisabled={isDisabledConfirmBtn}
                    onClick={onConfirmEmail}
                    isLoading={loadingConfirmEmail}
                    size={{ base: 'sm', sm: 'md' }}
                    ml={2}
                    variant="outline"
                  >
                    {userInfo?.email_verified_at
                      ? t(LN_USER_PERSONAL_INFORMATION_CONFIRMED_BTN)
                      : t(LN_USER_PERSONAL_INFORMATION_CONFIRM_BTN)}
                  </Button>
                }
              />
            </Flex>
            {!isInputDisabled && (
              <Box mt={2}>
                <SubmitBtn
                  disabled={!formik.isValid || loading}
                  loading={loading || loadingEditingUserInfo}
                  title={t(LN_USER_PERSONAL_INFORMATION_SUBMIT_BTN)}
                />
              </Box>
            )}
          </Flex>
          <Flex gap={3} flexDirection="column" w={{ base: '100%', '2sm': '50%' }}>
            <InputFormikField
              formik={formik}
              fieldName={formConstants.phone}
              label={LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER}
              disabled={isInputDisabled}
              isRequired
            />
            <SelectFormikField
              formik={formik}
              fieldName={formConstants.countryName}
              label={LN_USER_PERSONAL_INFORMATION_COUNTRY}
              disabled={isInputDisabled}
              optionsList={countriesList}
            />
          </Flex>
        </Flex>
      </form>
    </>
  )
}

export default memo(UserInfoForm)
