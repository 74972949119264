// Types
import { statusServerConstants } from 'components/Table/TableHistory/constants'

import {
  CREATE_WITHDRAWAL_SET_LOADING,
  GET_WITHDRAWAL_HISTORY,
  GET_WITHDRAWAL_METHODS,
  SET_CANCEL_WITHDRAWAL_SUCCESS,
  SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  SET_LOADING_CANCEL_WITHDRAWAL,
  SET_WITHDRAWAL_HISTORY,
  SET_WITHDRAWAL_METHODS,
  SET_WITHDRAWAL_MODAL_ITEM_INFO,
} from 'store/actions/Withdrawal/types'

const initialState = {
  withdrawalHistoryList: null,
  loadingWithdrawalHistoryList: false,
  modalWithdrawalItemInfo: null,
  isWithdrawalModalInfoOpen: false,

  withdrawalMethods: null,
  withdrawalMethodsLoading: false,
  loadingCreateWithdrawal: false,
  loadingCancelWithdrawal: false,
}

const withdrawalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_HISTORY:
      return {
        ...state,
        withdrawalHistoryList: action.payload,
        loadingWithdrawalHistoryList: false,
      }
    case GET_WITHDRAWAL_HISTORY:
      return {
        ...state,
        withdrawalHistoryList: null,
        loadingWithdrawalHistoryList: true,
      }
    case SET_IS_WITHDRAWAL_MODAL_INFO_OPEN:
      return {
        ...state,
        isWithdrawalModalInfoOpen: action.payload,
      }
    case SET_WITHDRAWAL_MODAL_ITEM_INFO:
      return {
        ...state,
        modalWithdrawalItemInfo: action.payload,
      }
    case SET_WITHDRAWAL_METHODS:
      return {
        ...state,
        withdrawalMethods: action.payload,
        withdrawalMethodsLoading: false,
      }
    case GET_WITHDRAWAL_METHODS:
      return {
        ...state,
        withdrawalMethodsLoading: true,
      }
    case CREATE_WITHDRAWAL_SET_LOADING:
      return {
        ...state,
        loadingCreateWithdrawal: action.payload,
      }
    case SET_LOADING_CANCEL_WITHDRAWAL:
      return {
        ...state,
        loadingCancelWithdrawal: action.payload,
      }
    case SET_CANCEL_WITHDRAWAL_SUCCESS:
      const data = state.withdrawalHistoryList.data
      const changed = data.map((item) =>
        item?.finance_id === action.payload?.financeId
          ? { ...item, status: { id: statusServerConstants?.failed } }
          : item,
      )

      return {
        ...state,
        withdrawalHistoryList: { ...state.withdrawalHistoryList, data: changed },
      }
    default:
      return state
  }
}
export default withdrawalReducer
