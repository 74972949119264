// Modules
import { memo } from 'react'

import { TextXl } from 'components'
// import LandingAlex from 'pages/Landing/Alexkapital'

// UI

const Landing = () => {
  return <TextXl>Landing</TextXl>
  // return <LandingAlex />
}

export default memo(Landing)
