// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const sidebarActiveItemSelector = createSelector(
  selfSelector,
  (self) => self.sidebarActiveItem,
)

export const userInfoSelector = createSelector(selfSelector, (self) => self.userInfo)

export const loadingEditingUserInfoSelector = createSelector(
  selfSelector,
  (self) => self.loadingEditingUserInfo,
)

export const loadingUserInfoSelector = createSelector(selfSelector, (self) => self.loadingUserInfo)

export const loadingConfirmEmailSelector = createSelector(
  selfSelector,
  (self) => self.loadingConfirmEmail,
)

export const selectedLanguageSelector = createSelector(
  selfSelector,
  (self) => self.selectedLanguage,
)

export const selectedThemeSelector = createSelector(selfSelector, (self) => self.selectedTheme)

export const tradingAccountsSelector = createSelector(selfSelector, (self) => self.tradingAccounts)
export const currentTradingAccountSelector = createSelector(
  selfSelector,
  (self) => self.currentTradingAccount,
)
export const userBalanceSelector = createSelector(selfSelector, (self) => self.userBalance)
export const userBalanceLoadingSelector = createSelector(
  selfSelector,
  (self) => self.userBalanceLoading,
)

export const countriesListSelector = createSelector(selfSelector, (self) => self.countriesList)
export const countriesListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.countriesListLoading,
)

export const ratesSelector = createSelector(selfSelector, (self) => self.rates)

export const isMobileSelector = createSelector(selfSelector, (self) => self.isMobile)
