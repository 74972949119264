// Modules
import { memo } from 'react'

// Components
import LoadingBlock from 'components/LoadingBlock/index'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box } from '@chakra-ui/react'

const OverflowLoadingBlock = (props) => {
  const { spinnerStyles, ...args } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      bg={isLightTheme ? '#ffffffc7' : '#2329347d'}
      pt={10}
      zIndex={1}
      left={0}
      {...args}
    >
      <LoadingBlock spinnerStyles={spinnerStyles} />
    </Box>
  )
}

export default memo(OverflowLoadingBlock)
