// Modules
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Constants
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Constants
import { CurrencyGroups } from 'constants/currency'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import {
  wsConnectCurrencyMarket,
  wsDisconnectCurrencyMarket,
} from 'store/actions/CurrencySocket/actions'
import { intervalGraphValueSelector } from 'store/actions/CurrencySocket/selectors'
import {
  accordionSearchValueSelector,
  expandableAccordionIndsSelector,
  innerListExpandableAccordionIndsSelector,
  marketTopTabValueSelector,
  socketMarketParametersSelector,
} from 'store/actions/marketSidebar/selectors'

function useFormatSocketQuery() {
  const dispatch = useDispatch()

  // for currency web socket only for one opened tab in accordion and chosen trade item
  const expandableAccordionInds = useSelector(expandableAccordionIndsSelector)
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)
  const limitInds = useSelector(innerListExpandableAccordionIndsSelector)
  // favourites/all
  const topTabValue = useSelector(marketTopTabValueSelector)
  const socketMarketParameters = useSelector(socketMarketParametersSelector)
  const accordionSearchValue = useSelector(accordionSearchValueSelector)
  const intervalGraphValue = useSelector(intervalGraphValueSelector)

  const isInputValueTrimmedValid = useMemo(() => {
    const inputValueTrimmed = accordionSearchValue?.trim()?.toLowerCase()
    return inputValueTrimmed && inputValueTrimmed?.length >= 3
  }, [accordionSearchValue])

  const channelsSocketObj = useMemo(() => {
    let channelsObj = { channels: [] }

    if (chosenActiveTradeItem?.label) {
      channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.name] }
      // channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.label] }
    }
    // if favourites tab is opened
    if (topTabValue === tabsList[1].id) {
      // if search is not empty add to socket found channels not all favourites
      // if search is not empty add to socket found channels not all favourites
      if (isInputValueTrimmedValid) {
        channelsObj = { channels: [...channelsObj.channels, ...socketMarketParameters?.channels] }
      }
    } // if tab all is opened
    else if (expandableAccordionInds) {
      // if search is not empty add to socket found channels not all groups
      if (isInputValueTrimmedValid) {
        channelsObj = { channels: [...channelsObj.channels, ...socketMarketParameters?.channels] }
      }
    }

    return channelsObj
  }, [
    chosenActiveTradeItem?.name,
    socketMarketParameters?.channels,
    isInputValueTrimmedValid,
    topTabValue,
  ])

  const groupsSocketObj = useMemo(() => {
    let groupsObj = { group: [], groupLimit: null }

    // if all tab is opened
    if (topTabValue !== tabsList[1].id) {
      if (!isInputValueTrimmedValid) {
        if (limitInds && limitInds?.length > 0) {
          groupsObj = {
            group: expandableAccordionInds?.[0],
            groupLimit: expandableAccordionInds?.[0] === CurrencyGroups.forex ? limitInds[0] : null,
          }
        } else {
          groupsObj = { group: expandableAccordionInds?.[0] }
        }
      }
    }
    return groupsObj
  }, [topTabValue, isInputValueTrimmedValid, expandableAccordionInds?.[0], limitInds])

  useEffect(() => {
    const params = {
      group: groupsSocketObj?.group,
      channels: channelsSocketObj?.channels,
      intervalGraph: intervalGraphValue,
      groupLimit: groupsSocketObj?.groupLimit,
    }

    if (groupsSocketObj?.group || channelsSocketObj?.channels) {
      dispatch(wsConnectCurrencyMarket(params))
    }
    return () => {
      dispatch(wsDisconnectCurrencyMarket())
    }
  }, [
    groupsSocketObj?.group,
    channelsSocketObj?.channels,
    groupsSocketObj?.groupLimit,
    intervalGraphValue,
  ])

  /// old logic - too many times rerender
  // useEffect(() => {
  //   // favourites
  //   let channelsObj = { channels: [] }
  //   let groupsObj = { group: [], groupLimit: null }
  //   const inputValueTrimmed = accordionSearchValue?.trim()?.toLowerCase()
  //   const isInputValueTrimmedValid = inputValueTrimmed && inputValueTrimmed?.length >= 3
  //
  //   if (chosenActiveTradeItem?.label) {
  //     channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.name] }
  //     // channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.label] }
  //   }
  //
  //   // if favourites tab is opened
  //   if (topTabValue === tabsList[1].id) {
  //     // if search is not empty add to socket found channels not all favourites
  //     if (isInputValueTrimmedValid) {
  //       // const inputValueTrimmed = accordionSearchValue?.trim()?.toLowerCase()
  //       // if (inputValueTrimmed !== '' && accordionSearchValue?.length >= 3) {
  //       const filtered = searchByName(inputValueTrimmed, favouritesTrade)
  //       channelsObj = {
  //         channels: [...channelsObj.channels, ..._.map(filtered, (el) => el?.name)],
  //         // channels: [...channelsObj.channels, ..._.map(filtered, (el) => el?.label)],
  //       }
  //       // }
  //     } else {
  //       channelsObj = {
  //         channels: [...channelsObj.channels, ..._.map(favouritesTrade, (el) => el?.name)],
  //         // channels: [...channelsObj.channels, ..._.map(favouritesTrade, (el) => el?.label)],
  //       }
  //     }
  //   } // if tab all is opened
  //   else if (expandableAccordionInds) {
  //     // if search is not empty add to socket found channels not all groups
  //     if (isInputValueTrimmedValid) {
  //       channelsObj = { channels: [...channelsObj.channels, ...socketMarketParameters?.channels] }
  //     } else {
  //       if (limitInds && limitInds?.length > 0) {
  //         groupsObj = {
  //           group: expandableAccordionInds?.[0],
  //           groupLimit: expandableAccordionInds?.[0] === CurrencyGroups.forex ? limitInds[0] : null,
  //         }
  //       } else {
  //         groupsObj = { group: expandableAccordionInds?.[0] }
  //       }
  //     }
  //   }
  //
  //   // setIsRequestParamsTheSame(true)
  //   // requestSocketParams
  //   const params = {
  //     group: groupsObj?.group,
  //     channels: channelsObj?.channels,
  //     intervalGraph: intervalGraphValue,
  //     groupLimit: groupsObj?.groupLimit,
  //   }
  //   const areEqual = _.isEqual(params, requestSocketParams)
  //   console.log('areEqual', areEqual)
  //   console.log('params', params)
  //   console.log('requestSocketParams', requestSocketParams)
  //
  //   if (groupsObj?.group || channelsObj?.channels) {
  //     // if ((groupsObj?.group || channelsObj?.channels) && !areEqual) {
  //     // setRequestSocketParams(params)
  //     dispatch(wsConnectCurrencyMarket(params))
  //   }
  //   return () => {
  //     dispatch(wsDisconnectCurrencyMarket())
  //   }
  // }, [
  //   dispatch,
  //   expandableAccordionInds,
  //   chosenActiveTradeItem,
  //   topTabValue,
  //   favouritesTrade,
  //   accordionSearchValue,
  //   intervalGraphValue,
  //   socketMarketParameters?.channels,
  //   // isRequestParamsTheSame,
  //   // requestSocketParams,
  //   // limitInds,
  // ])
}

export default useFormatSocketQuery
