// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import { LN_NOTIFICATIONS_GET_MARKET_LIST } from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'

// Constants
import { localStorageFavouritesKey } from 'constants/index'

// Utils
import { findElemInListCurrency } from 'utils/findElemInListCurrency'

// Store
import {
  setCurrencyMapMarketList,
  setCurrencyMarketList,
  setCurrencyMarketListLoading,
} from 'store/actions/CurrencySocket/actions'
import { GET_CURRENCY_MARKET_LIST } from 'store/actions/CurrencySocket/types'
import { setFavouriteList } from 'store/actions/FavouritesCurrency/actions'
import { showToast } from 'store/actions/ToastsApp/actions'
import { getCurrencyListAPI } from 'store/api/market'
import { getErrorMessage, onUserNotAuth } from 'store/sagas/utils'

function* getAllCurrencyList() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    const favouritesCurrencyList = yield select(
      (state) => state.favouritesCurrencyList.favouritesCurrencyList,
    )
    if (token) {
      const listResponse = yield call(getCurrencyListAPI)

      yield put(setCurrencyMarketList(listResponse?.currencies))
      yield put(setCurrencyMapMarketList(listResponse?.currencyMap || null))
      yield put(setCurrencyMarketListLoading(false))

      // update favourites price value according to new price values
      const updatedFavourites = favouritesCurrencyList?.map((el) => {
        const updatedItem = findElemInListCurrency(el?.name, listResponse?.currencies)
        if (updatedItem) {
          return { ...el, price: updatedItem?.price, spread: updatedItem?.spread }
        } else {
          return el
        }
      })
      localStorage.setItem(localStorageFavouritesKey, JSON.stringify(updatedFavourites))
      yield put(setFavouriteList(updatedFavourites))
    }
  } catch (error) {
    yield put(setCurrencyMarketListLoading(false))
    yield put(setCurrencyMarketList(null))
    yield put(setCurrencyMapMarketList(null))

    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_MARKET_LIST))),
    )
    yield call(onUserNotAuth, error)
  }
}

export function* watchMainCurrencySidebar() {
  yield takeEvery(GET_CURRENCY_MARKET_LIST, getAllCurrencyList)
}
