// Modules
import Lottie from 'lottie-react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_SORRY_PAGE_WRONG_COUNTRY } from 'locales/constants'

// Components
import { TextMd } from 'components'

// Ui
import { Box } from '@chakra-ui/react'

// Assets
import animationData from './animation.json'

const CountryIsNotAvailablePage = () => {
  const { t } = useTranslation()
  return (
    <Box
      h="100vh"
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Box>
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ height: '80vh', width: '100%' }}
        />
      </Box>
      <TextMd fontWeight="500">{t(LN_SORRY_PAGE_WRONG_COUNTRY)}</TextMd>
    </Box>
  )
}

export default memo(CountryIsNotAvailablePage)
