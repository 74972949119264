// Modules
import Cookies from 'js-cookie'
import { call, put, takeEvery } from 'redux-saga/effects'

// Languages
import { LN_NOTIFICATIONS_FORGOT_PASSWORD } from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { appLanguages, appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'
import { toastConstructor, toastType } from 'components/ToastApp/constants'
import { AuthTabValueDefault } from 'pages/Auth/Login/AuthTabs'

// Store
import {
  onForgotPasswordResult,
  onLoginResult,
  onNonAuth,
  onRegistrationUserResult,
  onResetPasswordResult,
  onSetAuthTabs,
  setToken,
} from 'store/actions/Auth/actions'
import {
  FORGOT_PASSWORD_ACTION,
  LOGOUT,
  ON_LOGIN,
  ON_REGISTRATION,
  RESET_PASSWORD_ACTION,
} from 'store/actions/Auth/types'
import { showToast } from 'store/actions/ToastsApp/actions'
import { getUserInfo } from 'store/actions/User/actions'
import { cookieAuthToken } from 'store/api'
import {
  forgotPasswordAPI,
  loginUserAPI,
  registrationUserAPI,
  resetPasswordAPI,
} from 'store/api/auth'
import { getErrorMessage, onUserNotAuth } from 'store/sagas/utils'

function* loginUserSaga(action) {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Two weeks in milliseconds

  try {
    const { email, password } = action.payload
    const response = yield call(loginUserAPI, { email, password })

    const { token } = response
    yield put(setToken(token))

    // Save token in a cookie
    Cookies.set(cookieAuthToken, token, { expires: expirationDate })

    // Save default language in local storage
    localStorage.setItem(appLanguagesLocalStoreKey, appLanguages?.en)

    const tokenCookies = Cookies.get(cookieAuthToken)
    if (tokenCookies) {
      yield put(getUserInfo())
      // yield put(onGetUserBalance()) TODO: test it
    }

    yield put(onLoginResult(''))
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))
    yield put(onLoginResult(result))
    yield call(onUserNotAuth, error)
  }
}

function* forgotPasswordSaga(action) {
  const t = i18n.t.bind(i18n)
  try {
    const { email } = action.payload
    const response = yield call(forgotPasswordAPI, { email })

    // const { token } = response
    yield put(onForgotPasswordResult({ error: '' }))
    yield put(
      showToast(
        toastConstructor(toastType.success, response?.message, t(LN_NOTIFICATIONS_FORGOT_PASSWORD)),
      ),
    )
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))
    yield put(onForgotPasswordResult({ error: result }))
  }
}

function* resetPasswordSaga(action) {
  try {
    const { email, token, password, repeatPassword } = action.payload
    const response = yield call(resetPasswordAPI, { email, token, password, repeatPassword })
    // const { token } = response
    yield put(onResetPasswordResult({ error: '' }))
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))
    yield put(onResetPasswordResult({ error: result }))
  }
}

function* logoutSaga() {
  Cookies.remove(cookieAuthToken)

  yield put(onNonAuth())
}

function* registrationUserSaga(action) {
  try {
    const response = yield call(registrationUserAPI, action.payload)
    yield put(showToast(toastConstructor(toastType.success, response.message)))
    yield put(onRegistrationUserResult(''))
    yield put(onSetAuthTabs(AuthTabValueDefault))
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))
    yield put(onRegistrationUserResult(result))
    yield call(onUserNotAuth, error)
  }
}

export function* watchAuth() {
  yield takeEvery(ON_LOGIN, loginUserSaga)
  yield takeEvery(ON_REGISTRATION, registrationUserSaga)
  yield takeEvery(LOGOUT, logoutSaga)
  yield takeEvery(FORGOT_PASSWORD_ACTION, forgotPasswordSaga)
  yield takeEvery(RESET_PASSWORD_ACTION, resetPasswordSaga)
}
