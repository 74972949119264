// Modules
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import InnerExpandableListItem from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/InnerExpandableList/InnerExpandableListItem'

// Utils
import { getInnerAccordionTitles } from 'utils/getInnerAccordionTitles'

// Store
import {
  setInnerListExpandableAccordionInds,
  setIsChooseFirstTimeInnerAccordionId,
} from 'store/actions/marketSidebar/actions'
import { isChooseFirstTimeInnerAccordionIdSelector } from 'store/actions/marketSidebar/selectors'

// UI
import { VStack } from '@chakra-ui/react'

const InnerExpandableList = (props) => {
  const { list } = props
  const dispatch = useDispatch()

  const isChooseFirstTimeInnerAccordionId = useSelector(isChooseFirstTimeInnerAccordionIdSelector)

  const titlesAccordion = useMemo(() => getInnerAccordionTitles(list), [list])

  useEffect(() => {
    if (!isChooseFirstTimeInnerAccordionId && titlesAccordion) {
      dispatch(setInnerListExpandableAccordionInds([titlesAccordion?.[0]]))
      dispatch(setIsChooseFirstTimeInnerAccordionId(true))
    }
  }, [dispatch, titlesAccordion, isChooseFirstTimeInnerAccordionId])

  return (
    <VStack spacing={1.5} align="stretch">
      {titlesAccordion.map((groupId) => {
        return (
          <InnerExpandableListItem key={groupId} groupList={list?.[groupId]} groupId={groupId} />
        )
      })}
    </VStack>
  )
}

export default memo(InnerExpandableList)
