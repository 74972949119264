// Modules
import { useFormik } from 'formik'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_USER_RESTRICTIONS_WITHDRAWALS,
  LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME,
  LN_USER_WITHDRAWAL_ACCOUNT_NUMBER,
  LN_USER_WITHDRAWAL_AMOUNT,
  LN_USER_WITHDRAWAL_BANK_SWIFT_CODE,
  LN_USER_WITHDRAWAL_CARD_HOLDER_NAME,
  LN_USER_WITHDRAWAL_CARD_NUMBER,
  LN_USER_WITHDRAWAL_REQUEST_NEW_WITHDRAWAL,
  LN_USER_WITHDRAWAL_REQUEST_WITHDRAWAL_SUBMIT_BTN,
  LN_USER_WITHDRAWAL_WALLET_ADDRESS,
  LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS_TITLE_FORM,
} from 'locales/constants'

// Components
import { ErrorMessage, InputFormikField, LoadingBlock, SubmitBtn, TextMd, TextSm } from 'components'
import MethodSelect from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect'
import { withdrawalMethodsConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'
import {
  formConstants,
  initialValues,
} from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'
import { validationSchema } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/validation'

import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { createWithdrawal, getWithdrawalMethods } from 'store/actions/Withdrawal/actions'
import {
  loadingCreateWithdrawalSelector,
  withdrawalMethodsLoadingSelector,
} from 'store/actions/Withdrawal/selectors'

// UI
import { Box, Card, CardBody, Flex } from '@chakra-ui/react'

const WithdrawalForm = () => {
  const { t } = useTranslation()
  const { mainTextColor, isLightTheme } = useCurrentColorTheme()
  const { userTradingAccountSymbol } = useUserTradingAccountId()
  const { isBlockedWithdrawals } = useCheckUserRestrictions()

  const dispatch = useDispatch()

  const withdrawalMethodsLoading = useSelector(withdrawalMethodsLoadingSelector)
  const loadingCreateWithdrawal = useSelector(loadingCreateWithdrawalSelector)

  const [activeMethodFields, setActiveMethodFields] = useState([])

  useEffect(() => {
    dispatch(getWithdrawalMethods())
  }, [])

  const onRequestWithdrawal = useCallback(
    (values, { resetForm }) => {
      let additionalInfo = {}
      if (values?.[formConstants.withdrawalMethod] === withdrawalMethodsConstants.bank) {
        const iban = values?.[formConstants.iban]
          ? { [formConstants.iban]: values?.[formConstants.iban] }
          : {}
        const swiftCode = values?.[formConstants.swiftCode]
          ? { [formConstants.swiftCode]: values?.[formConstants.swiftCode] }
          : {}
        additionalInfo = {
          ...additionalInfo,
          [formConstants.accountNumber]: values?.[formConstants.accountNumber],
          [formConstants.accountHolderName]: values?.[formConstants.accountHolderName],
          ...iban,
          ...swiftCode,
        }
      } else if (
        values?.[formConstants.withdrawalMethod] === withdrawalMethodsConstants.bitcoin ||
        values?.[formConstants.withdrawalMethod] === withdrawalMethodsConstants.ethereum ||
        values?.[formConstants.withdrawalMethod] === withdrawalMethodsConstants.usdt
      ) {
        additionalInfo = {
          ...additionalInfo,
          [formConstants.walletAddress]: values?.[formConstants.walletAddress],
        }
      } else if (values?.[formConstants.withdrawalMethod] === withdrawalMethodsConstants.card) {
        additionalInfo = {
          ...additionalInfo,
          [formConstants.cardNumber]: values?.[formConstants.cardNumber],
          [formConstants.cardHolderName]: values?.[formConstants.cardHolderName],
        }
      }

      const params = {
        fields: {
          amount: values?.[formConstants.amount],
          withdrawalMethod: values?.[formConstants.withdrawalMethod],
          ...additionalInfo,
        },
        resetForm: resetForm,
      }

      dispatch(createWithdrawal(params))
    },
    [dispatch],
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onRequestWithdrawal,
  })

  const loading = withdrawalMethodsLoading
  const isInputDisabled = loadingCreateWithdrawal || isBlockedWithdrawals

  const inputSwitcher = useMemo(() => {
    switch (formik.values?.[formConstants.withdrawalMethod]) {
      case withdrawalMethodsConstants.bank:
        return (
          <Box w="100%">
            <Flex gap={5} w="100%" flexDirection="row">
              <Flex gap={3} flexDirection="column" w="50%">
                <InputFormikField
                  formik={formik}
                  fieldName={formConstants.accountNumber}
                  label={LN_USER_WITHDRAWAL_ACCOUNT_NUMBER}
                  disabled={isInputDisabled}
                  isRequired
                />
              </Flex>
              <Flex gap={3} flexDirection="column" w="50%">
                <InputFormikField
                  formik={formik}
                  fieldName={formConstants.accountHolderName}
                  label={LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME}
                  disabled={isInputDisabled}
                  isRequired
                />
              </Flex>
            </Flex>
            <Flex gap={5} w="100%" flexDirection="row">
              <Flex gap={3} flexDirection="column" w="50%">
                <InputFormikField
                  formik={formik}
                  fieldName={formConstants.iban}
                  label="IBAN"
                  disabled={isInputDisabled}
                />
              </Flex>
              <Flex gap={3} flexDirection="column" w="50%">
                <InputFormikField
                  formik={formik}
                  fieldName={formConstants.swiftCode}
                  label={LN_USER_WITHDRAWAL_BANK_SWIFT_CODE}
                  disabled={isInputDisabled}
                />
              </Flex>
            </Flex>
          </Box>
        )
      case withdrawalMethodsConstants.bitcoin:
      case withdrawalMethodsConstants.usdt:
      case withdrawalMethodsConstants.ethereum:
        return (
          <Flex gap={5} w="100%">
            <Flex gap={3} flexDirection="column" w="50%">
              <InputFormikField
                formik={formik}
                fieldName={formConstants.walletAddress}
                label={LN_USER_WITHDRAWAL_WALLET_ADDRESS}
                disabled={isInputDisabled}
                isRequired
              />
            </Flex>
          </Flex>
        )
      case withdrawalMethodsConstants.card:
        return (
          <Flex gap={5} w="100%">
            <Flex gap={3} flexDirection="column" w="50%">
              <InputFormikField
                formik={formik}
                fieldName={formConstants.cardNumber}
                label={LN_USER_WITHDRAWAL_CARD_NUMBER}
                disabled={isInputDisabled}
                isRequired
              />
            </Flex>
            <Flex gap={3} flexDirection="column" w="50%">
              <InputFormikField
                formik={formik}
                fieldName={formConstants.cardHolderName}
                label={LN_USER_WITHDRAWAL_CARD_HOLDER_NAME}
                disabled={isInputDisabled}
                isRequired
              />
            </Flex>
          </Flex>
        )
    }
  }, [formik, t, formik.values?.[formConstants.withdrawalMethod]])

  if (loading) {
    return <LoadingBlock />
  }

  return (
    <Card
      bg={isLightTheme ? 'white' : 'whiteAlpha.50'}
      borderRadius="md"
      border="1px solid"
      borderColor="whiteAlpha.100"
    >
      <form onSubmit={formik.handleSubmit}>
        <CardBody position="relative">
          {isBlockedWithdrawals && (
            <Box top="10px" position="absolute" right="15px">
              <ErrorMessage
                error={t(LN_USER_RESTRICTIONS_WITHDRAWALS)}
                fontSize="sm"
                fontWeight="bold"
              />
            </Box>
          )}
          <Box pb={5}>
            <TextMd fontWeight={500} color={mainTextColor}>
              {t(LN_USER_WITHDRAWAL_REQUEST_NEW_WITHDRAWAL)}
            </TextMd>
          </Box>
          {loading ? (
            <LoadingBlock />
          ) : (
            <>
              <Flex gap={5} w="100%">
                <Flex gap={3} flexDirection="column" w="50%">
                  <InputFormikField
                    formik={formik}
                    fieldName={formConstants.amount}
                    label={LN_USER_WITHDRAWAL_AMOUNT}
                    disabled={isInputDisabled}
                    type="number"
                    placeholder="0.00"
                    isInputRightAddon
                    inputRightAddonVal={userTradingAccountSymbol}
                    isRequired
                  />
                </Flex>
                <Flex gap={3} flexDirection="column" w="50%">
                  <MethodSelect
                    isInputDisabled={isInputDisabled}
                    formik={formik}
                    loading={loading}
                    setActiveMethodFields={setActiveMethodFields}
                  />
                </Flex>
              </Flex>
              {activeMethodFields?.length !== 0 && (
                <Box pt={5} pb={5}>
                  <TextMd color={mainTextColor} fontWeight={500}>
                    {t(LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS_TITLE_FORM)}
                  </TextMd>
                </Box>
              )}

              {inputSwitcher}
              <Flex pt={6} flexDirection="column" gap={2}>
                {(formik.touched?.[formConstants.swiftCode] ||
                  formik.touched?.[formConstants.iban]) &&
                  formik.errors?.[formConstants.atLeastOneValid] && (
                    <ErrorMessage
                      error={t(formik.errors?.[formConstants.atLeastOneValid])}
                      fontSize="sm"
                    />
                  )}
                <Box>
                  <SubmitBtn
                    w="auto"
                    disabled={
                      !formik.isValid || loading || loadingCreateWithdrawal || isBlockedWithdrawals
                    }
                    loading={loading || loadingCreateWithdrawal}
                    title={t(LN_USER_WITHDRAWAL_REQUEST_WITHDRAWAL_SUBMIT_BTN)}
                  />
                </Box>
              </Flex>
            </>
          )}
        </CardBody>
      </form>
    </Card>
  )
}

export default memo(WithdrawalForm)
