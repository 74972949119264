import { withdrawalMethodsConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'

export const formConstants = {
  amount: 'amount',
  withdrawalMethod: 'withdrawalMethod',

  // bank method
  accountNumber: 'accountNumber',
  accountHolderName: 'accountHolderName',
  iban: 'iban',
  swiftCode: 'swiftCode',

  // crypto
  walletAddress: 'walletAddress',

  // card
  cardNumber: 'cardNumber',
  cardHolderName: 'cardHolderName',

  // validation
  atLeastOneValid: 'atLeastOneValid',
}

export const initialValues = {
  [formConstants.amount]: '',
  [formConstants.withdrawalMethod]: withdrawalMethodsConstants.bitcoin,
  [formConstants.cardNumber]: '',
  [formConstants.cardHolderName]: '',
  [formConstants.walletAddress]: '',
  [formConstants.accountNumber]: '',
  [formConstants.accountHolderName]: '',
  [formConstants.iban]: '',
  [formConstants.swiftCode]: '',
}
