export const SET_USER_SIDEBAR_ACTIVE_ITEM = 'SET_USER_SIDEBAR_ACTIVE_ITEM'

export const SET_USER_INFO = 'SET_USER_INFO'
export const GET_USER_INFO = 'GET_USER_INFO'
export const EDIT_USER_INFO = 'EDIT_USER_INFO'

export const CONFIRM_EMAIL_ACTION = 'CONFIRM_EMAIL_ACTION'
export const CONFIRM_EMAIL_RESULT = 'CONFIRM_EMAIL_RESULT'

export const SET_SELECTED_APP_LANGUAGE = 'SET_SELECTED_APP_LANGUAGE'
export const SET_SELECTED_THEME = 'SET_SELECTED_THEME'

export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST'
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST'

export const GET_USER_BALANCE = 'GET_USER_BALANCE'
export const SET_USER_BALANCE = 'SET_USER_BALANCE'

export const GET_USER_RATES = 'GET_USER_RATES'
export const SET_USER_RATES = 'SET_USER_RATES'

export const GET_USER_TRADING_ACCOUNTS = 'GET_USER_TRADING_ACCOUNTS'
export const SET_USER_TRADING_ACCOUNTS = 'SET_USER_TRADING_ACCOUNTS'

export const SET_USER_CURRENT_TRADING_ACCOUNT = 'SET_USER_CURRENT_TRADING_ACCOUNT'

export const SET_USER_DEVICE_IS_MOBILE = 'SET_USER_DEVICE_IS_MOBILE'

export const PUSH_QUERY_USER_ACTIVE_AT = 'PUSH_QUERY_USER_ACTIVE_AT'
