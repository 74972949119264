// Modules
import * as Yup from 'yup'

// Constants
import { formConstants } from 'pages/Auth/Login/LoginForm/constants'
import { RADIO_BY_PROFIT } from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

// Utils
import { validationText } from 'utils/validation/constants'

export const validationEmail = {
  [formConstants.email]: Yup.string()
    .email(validationText.emailInvalid)
    .required(validationText.emailRequired),
}

export const validationPassword = {
  [formConstants.password]: Yup.string()
    .min(8, validationText.passwordLength)
    .required(validationText.passwordRequired),
}

export const validationRepeatPassword = {
  [formConstants.repeatPassword]: Yup.string()
    .oneOf([Yup.ref(formConstants.password), null], validationText.repeatPasswordMatch)
    .required(validationText.repeatPasswordRequired),
}

export const validationStringRequiredField = () =>
  Yup.string().required(validationText.inputValueIsRequired)

export const validationPhoneRequiredField = () =>
  validationStringRequiredField().matches(/^\+?[0-9]{10,15}$/, validationText.inputValuePhone)

export const validationInputCalcTransaction = (additionalValidation) =>
  Yup.number()
    .positive(validationText.inputValueNotPositive)
    .notOneOf([0], validationText.inputValueNotZero)
    .concat(additionalValidation)

export const checkIfProfitRadioInputIsRequiredValidation = (fieldInput) =>
  Yup.number().when(fieldInput, {
    is: Number(RADIO_BY_PROFIT),
    then: (schema) =>
      schema
        .required(validationText.inputValueIsRequired)
        .positive(validationText.inputValueNotPositive)
        .notOneOf([0], validationText.inputValueNotZero),
    otherwise: (schema) =>
      schema
        .positive(validationText.inputValueNotPositive)
        .notOneOf([0], validationText.inputValueNotZero),
  })

export const usdtAddressRegex =
  /^(0x[a-fA-F0-9]{40}|T[a-zA-Z0-9]{33}|(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,42})$/
export const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/
export const bitcoinAddressRegex =
  /\b(1[1-9A-HJ-NP-Za-km-z]{25,34}|3[1-9A-HJ-NP-Za-km-z]{25,34}|(bc1|BC1)[0-9a-z]{25,39})\b/
export const cardNumberRegex = /^[0-9]{16}$/
export const cardHolderNameRegex = /^[a-zA-Z\s]+$/
export const onlyDigitsRegex = /^\d+$/
export const ibanRegex = /[A-Z]{2}\d{2}[A-Z0-9]{4,30}/
export const swiftCodeRegex = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/
export const positiveNumberRegex = /^[0-9]+(\.[0-9]+)?$/
