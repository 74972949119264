// Modules
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_MARKET_SIDEBAR_ACCORDION_NO_ITEMS } from 'locales/constants'

// Components
import { TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import AccordionItem from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/AccordionItem'

// Hooks
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Store
import { currencyListLoadingSelector } from 'store/actions/CurrencySocket/selectors'
import { setExpandableAccordionInds } from 'store/actions/marketSidebar/actions'

// UI
import { VStack } from '@chakra-ui/react'

const MarketAccordion = (props) => {
  const { list } = props

  const { titlesAccordion } = useMarketWatchAccordionList()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loadingList = useSelector(currencyListLoadingSelector)

  const [isChooseFirstTimeAccordionId, setIsChooseFirstTimeAccordionId] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isChooseFirstTimeAccordionId) {
      setIsChooseFirstTimeAccordionId(true)
      setIsLoading(false)
      dispatch(setExpandableAccordionInds([titlesAccordion?.[0]]))
    }
  }, [dispatch, titlesAccordion, isChooseFirstTimeAccordionId])

  if (!list && !loadingList) {
    return <TextSm>{t(LN_MARKET_SIDEBAR_ACCORDION_NO_ITEMS)}</TextSm>
  }

  if (loadingList || isLoading) {
    return <LoadingBlock />
  }

  return (
    <VStack spacing={1} align="stretch">
      {titlesAccordion?.map((groupId) => {
        return (
          <AccordionItem key={groupId} groupId={groupId} groupList={list?.[groupId]} list={list} />
        )
      })}
    </VStack>
  )
}

export default memo(MarketAccordion)
