// Action Types
import {
  GET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MAP_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST_LOADING,
  SET_INTERVAL_GRAPH_VALUE,
  WS_CONNECT_CURRENCY_MARKET,
  WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE,
  WS_CURRENCY_MARKET_MESSAGE_RECEIVED,
  WS_DISCONNECT_CURRENCY_MARKET,
  WS_ERROR_CURRENCY_MARKET,
} from 'store/actions/CurrencySocket/types'

export const getCurrencyMarketList = () => ({
  type: GET_CURRENCY_MARKET_LIST,
})

export const setCurrencyMarketListLoading = (payload) => ({
  type: SET_CURRENCY_MARKET_LIST_LOADING,
  payload: payload,
})

export const setCurrencyMarketList = (payload) => ({
  type: SET_CURRENCY_MARKET_LIST,
  payload: payload,
})

export const setCurrencyMapMarketList = (payload) => ({
  type: SET_CURRENCY_MAP_MARKET_LIST,
  payload: payload,
})

// WEB SOCKET
export const wsConnectCurrencyMarket = (options) => ({ type: WS_CONNECT_CURRENCY_MARKET, options })

export const wsDisconnectCurrencyMarket = () => ({ type: WS_DISCONNECT_CURRENCY_MARKET })

export const wsCurrencyMarketMessageReceived = (payload) => ({
  type: WS_CURRENCY_MARKET_MESSAGE_RECEIVED,
  payload,
})

export const wsCurrencyMarketChangeCurrencyValue = (payload) => ({
  type: WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE,
  payload,
})

export const wsCurrencyMarketError = (payload) => ({ type: WS_ERROR_CURRENCY_MARKET, payload })

export const setIntervalGraphValue = (payload) => ({
  type: SET_INTERVAL_GRAPH_VALUE,
  payload: payload,
})
