// Modules
import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Components
import { TabBase } from 'components'
import VerificationTabPanel from 'pages/User/components/Content/Verification/components/VerificationTabs/VerificationTabPanel'
import {
  StatusIcons,
  StatusIconsColor,
} from 'pages/User/components/Content/Verification/components/VerificationTabs/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { setVerificationFieldsTab } from 'store/actions/KYC/actions'

// UI
import { Box, TabList, TabPanels, Tabs } from '@chakra-ui/react'

const VerificationTabs = (props) => {
  const { fields, uploadFile, deleteFieldHandler, tabValue } = props

  const { mainBorderColor, isLightTheme } = useCurrentColorTheme()
  const dispatch = useDispatch()

  const handleTabsChange = useCallback(
    (index) => {
      dispatch(setVerificationFieldsTab(index))
    },
    [dispatch],
  )

  return (
    <Box>
      <Tabs variant="enclosed" index={tabValue} onChange={handleTabsChange}>
        <TabList borderColor={mainBorderColor}>
          {fields?.map((el, i) => (
            <TabBase
              key={el?.label + i}
              title={el?.label}
              icon={StatusIcons?.[el?.status?.id]}
              iconColor={StatusIconsColor?.[el?.status?.id]}
            />
          ))}
        </TabList>

        <TabPanels
          bg={isLightTheme ? '#ffffff78' : '#ffffff0a'}
          borderBottomRadius="md"
          border="1px solid"
          borderColor={mainBorderColor}
          borderTop={0}
        >
          {fields?.map((el) => (
            <VerificationTabPanel
              key={el?.label + 'tab-panel'}
              el={el}
              uploadFile={uploadFile}
              deleteFieldHandler={deleteFieldHandler}
            />
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default memo(VerificationTabs)
