// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Link } from '@chakra-ui/react'

const LinkBase = ({ children, href, target, ...args }) => {
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Link
      href={href}
      target={target}
      colorScheme="blue.500"
      _hover={{
        color: isLightTheme ? 'blue.600' : 'blue.600',
        textDecoration: 'none',
      }}
      {...args}
    >
      {children}
    </Link>
  )
}

export default memo(LinkBase)
