// Modules
import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Components
import { WrapperItem } from 'components'
import BookmarkBtn from 'components/SmallList/BookmarkBtn'
import BtnsBlock from 'components/SmallList/BtnsBlock'
import NameText from 'components/SmallList/NameText'
import PercentageText from 'components/SmallList/PercentageText'
import PriceText, { typePriceConst } from 'components/SmallList/PriceText'

// Hooks
import useCurrencyActiveItemItem from 'hooks/Currency/useCurrencyActiveItemItem'
import useCheckIsActiveTradeItem from 'hooks/useCheckIsActiveTradeItem'
import useCheckIsFavouriteTradeItem from 'hooks/useCheckIsFavouriteTradeItem'
import useTradeInfoModal from 'hooks/useTradeInfoModal'

// Store
import { addFavouriteItem } from 'store/actions/FavouritesCurrency/actions'

// UI
import { Flex } from '@chakra-ui/react'

const ItemList = (props) => {
  const { element } = props

  const idElement = element?.id

  const dispatch = useDispatch()
  const { checkIsFavouriteTradeItem } = useCheckIsFavouriteTradeItem()
  const { isActiveTradeItem } = useCheckIsActiveTradeItem({ id: idElement })
  const { onAddItem } = useCurrencyActiveItemItem(element)
  const { onOpenTradeInfoModal } = useTradeInfoModal()

  const onInfoHandler = useCallback(() => {
    onOpenTradeInfoModal(element)
  }, [element, onOpenTradeInfoModal])

  const onPlusHandler = useCallback(() => onAddItem(), [onAddItem])

  const onBookmarkBtn = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(addFavouriteItem(element))
    },
    [dispatch, element],
  )

  return (
    <WrapperItem onItemClick={onAddItem} active={isActiveTradeItem}>
      <Flex alignItems="center" gap="0.1em">
        <BookmarkBtn
          onClick={onBookmarkBtn}
          isActive={checkIsFavouriteTradeItem(element?.id, element?.name)}
        />
        <NameText name={element?.label} />
      </Flex>
      <Flex alignItems="center" gap="0.5em">
        <PriceText
          price={element?.price}
          typePrice={typePriceConst.buy}
          symbolPoint={element?.symbol_point}
        />
        <PriceText
          price={element?.price - element?.spread}
          typePrice={typePriceConst.sell}
          symbolPoint={element?.symbol_point}
        />
        {/*TODO: add difference percentage*/}
        {/*<PercentageText percentage={element?.percentage} />*/}
        <BtnsBlock onInfoHandler={onInfoHandler} onPlusHandler={onPlusHandler} isInfo={false} />
      </Flex>
    </WrapperItem>
  )
}

export default memo(ItemList)
