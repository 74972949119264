// Modules
import { memo } from 'react'

// Components
import DisabledInputInfo from 'components/Form/DisabledInputInfo'

const InputFormikField = (props) => {
  const {
    formik,
    fieldName,
    label,
    disabled,
    placeholder,
    type = 'text',
    isInputRightAddon = false,
    inputRightAddonVal,
    isInnerElement = false,
    isRequired = false,
    ...args
  } = props

  return (
    <DisabledInputInfo
      type={type}
      isRequired={isRequired}
      label={label}
      isInputRightAddon={isInputRightAddon}
      inputRightAddonVal={inputRightAddonVal}
      disabled={disabled}
      isInnerElement={isInnerElement}
      touched={formik.touched[fieldName]}
      error={formik.errors[fieldName]}
      getInputProps={formik.getFieldProps(fieldName)}
      placeholder={placeholder}
      {...args}
    />
  )
}

export default memo(InputFormikField)
