// Languages
import {
  LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BANK,
  LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BITCOIN,
  LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_CARD,
  LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_ETHEREUM,
  LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_USDT,
} from 'locales/constants'

export const withdrawalMethodsConstants = {
  bank: 'bank',
  bitcoin: 'bitcoin',
  ethereum: 'ethereum',
  usdt: 'usdt',
  card: 'card',
}
export const withdrawalMethodsServerConstants = {
  cryptocurrency: 'cryptocurrency',
  card: 'card',
  bankTransfer: 'bank transfer',
}

export const withdrawalDetailsObjList = {
  [withdrawalMethodsConstants.bank]: {
    id: withdrawalMethodsConstants.bank,
    name: LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BANK,
  },
  [withdrawalMethodsConstants.bitcoin]: {
    id: withdrawalMethodsConstants.bitcoin,
    name: LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BITCOIN,
  },
  [withdrawalMethodsConstants.ethereum]: {
    id: withdrawalMethodsConstants.ethereum,
    name: LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_ETHEREUM,
  },
  [withdrawalMethodsConstants.usdt]: {
    id: withdrawalMethodsConstants.usdt,
    name: LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_USDT,
  },
  [withdrawalMethodsConstants.card]: {
    id: withdrawalMethodsConstants.card,
    name: LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_CARD,
  },
}

export const withdrawalMethodsGroupServer = {
  [withdrawalMethodsServerConstants?.cryptocurrency]: [
    { ...withdrawalDetailsObjList?.[withdrawalMethodsConstants.bitcoin] },
    { ...withdrawalDetailsObjList?.[withdrawalMethodsConstants.ethereum] },
    { ...withdrawalDetailsObjList?.[withdrawalMethodsConstants.usdt] },
  ],
  [withdrawalMethodsServerConstants?.card]: [
    { ...withdrawalDetailsObjList?.[withdrawalMethodsConstants.card] },
  ],
  [withdrawalMethodsServerConstants?.bankTransfer]: [
    { ...withdrawalDetailsObjList?.[withdrawalMethodsConstants.bank] },
  ],
}
