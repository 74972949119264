// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import {
  LN_NOTIFICATIONS_GET_ASSERT_LIST,
  LN_NOTIFICATIONS_GET_USER_DEPOSIT_FIELDS,
  LN_NOTIFICATIONS_GET_USER_DEPOSIT_HISTORY,
} from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'

import { FinanceTypeIds } from 'constants/finance'

// Store
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  setDepositUserHistory,
  setLoadingDepositUserHistory,
  setLoadingUserDepositFields,
  setUserDepositFields,
} from 'store/actions/UserDeposit/actions'
import { GET_DEPOSIT_USER_HISTORY, GET_USER_DEPOSIT_FIELDS } from 'store/actions/UserDeposit/types'
import { onGetDepositFieldsAPI, onGetFinanceAPI } from 'store/api/user'
import { getErrorMessage, onUserNotAuth } from 'store/sagas/utils'

function* getDepositHistorySaga({ payload }) {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token && payload?.userTradingAccountId) {
      const data = yield call(onGetFinanceAPI, {
        tradeAccountId: payload?.userTradingAccountId,
        financeTypeIds: [FinanceTypeIds.deposit, FinanceTypeIds.bonus, FinanceTypeIds.correction],
        page: payload?.page,
      })
      yield put(setDepositUserHistory(data))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(
        toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_USER_DEPOSIT_HISTORY)),
      ),
    )

    yield put(setLoadingUserDepositFields(false))
    yield call(onUserNotAuth, error)
  }
}
function* getDepositFieldsSaga() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const data = yield call(onGetDepositFieldsAPI)
      yield put(setUserDepositFields(data))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(
        toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_USER_DEPOSIT_FIELDS)),
      ),
    )

    yield put(setLoadingDepositUserHistory(false))
    yield call(onUserNotAuth, error)
  }
}

export function* watchUserDepositSaga() {
  yield takeEvery(GET_DEPOSIT_USER_HISTORY, getDepositHistorySaga)
  yield takeEvery(GET_USER_DEPOSIT_FIELDS, getDepositFieldsSaga)
}
