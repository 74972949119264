// Modules
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_DEPOSIT_TAB_HISTORY_TITLE } from 'locales/constants'

// Components
import HistoryPageWrapper from 'components/HistoryPageWrapper/HistoryPageWrapper'
import TableHistoryBase from 'components/Table/TableHistory/TableHistoryBase'
import { statusConstants } from 'components/Table/TableHistory/constants'
import InfoModal from 'pages/User/components/Content/Deposit/DepositHistory/InfoModal'
import {
  columns,
  depositTypeConstants,
} from 'pages/User/components/Content/Deposit/DepositHistory/constants'
import useDepositHistoryTablePagination from 'pages/User/components/Content/Deposit/DepositHistory/useDepositHistoryTablePagination'

// Constants
import {
  FinanceTypeIds,
  financeTypeIconsConstants,
  financeTypeLanguageConstants,
} from 'constants/finance'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { getDepositUserHistory } from 'store/actions/UserDeposit/actions'
import {
  depositHistorySelector,
  loadingDepositHistorySelector,
} from 'store/actions/UserDeposit/selectors'

const DepositHistory = () => {
  const dispatch = useDispatch()
  const { userTradingAccountId } = useUserTradingAccountId()

  const depositHistory = useSelector(depositHistorySelector)
  const loadingDeposit = useSelector(loadingDepositHistorySelector)

  const listHistory = useMemo(() => depositHistory?.data, [depositHistory?.data])

  useEffect(() => {
    dispatch(getDepositUserHistory({ userTradingAccountId }))
  }, [dispatch, userTradingAccountId])

  const listTransform = useMemo(() => {
    if (!listHistory || listHistory?.length === 0) return []
    return listHistory?.map((el) => {
      return {
        id: el?.finance_id,
        depositType: depositTypeConstants[el?.finance_type?.id],
        date: el?.deposit_date,
        typeFinance: financeTypeLanguageConstants[el?.finance_type?.id],
        typeFinanceIcon: financeTypeIconsConstants[el?.finance_type?.id],
        amount: el?.deposit_amount,
        currency: el?.currency,
        status: el?.status?.id,
        description: el?.client_description,
      }
    })
  }, [listHistory])

  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useDepositHistoryTablePagination()

  return (
    <HistoryPageWrapper
      loading={loadingDeposit}
      title={LN_DEPOSIT_TAB_HISTORY_TITLE}
      list={listTransform}
    >
      <>
        <TableHistoryBase
          list={listTransform}
          loading={loadingDeposit}
          paginationInfo={paginationInfo}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          onPage={onPage}
          columns={columns}
          typeFinance={FinanceTypeIds.deposit}
        />
        <InfoModal />
      </>
    </HistoryPageWrapper>
  )
}

export default memo(DepositHistory)
