// Languages
import {
  LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_CHECKBOX,
  LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_CHECKBOX,
  LN_AUTH_VALIDATION_EMAIL_REQUIRED,
  LN_AUTH_VALIDATION_INVALID_EMAIL,
  LN_AUTH_VALIDATION_PASSWORD_MUST_MATCH,
  LN_AUTH_VALIDATION_PASSWORD_NUM_CHARACTERS,
  LN_AUTH_VALIDATION_PASSWORD_REQUIRED,
  LN_AUTH_VALIDATION_REPEAT_PASSWORD_REQUIRED,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_AT_LEAST_MIN_15_DIGITS,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_DIGITS,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_11_DIGITS,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_20_DIGITS,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_34_DIGITS,
  LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MIN_8_DIGITS,
  LN_VALIDATION_INPUT_VALUE_INVALID_CARD_NUMBER,
  LN_VALIDATION_INPUT_VALUE_INVALID_CRYPTO_WALLET_ADDRESS,
  LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME,
  LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME_MIN_SYMBOL,
  LN_VALIDATION_INPUT_VALUE_INVALID_IBAN,
  LN_VALIDATION_INPUT_VALUE_INVALID_SWIFT_CODE,
  LN_VALIDATION_INPUT_VALUE_IS_REQUIRED,
  LN_VALIDATION_INPUT_VALUE_NOT_POSITIVE,
  LN_VALIDATION_INPUT_VALUE_NOT_ZERO,
  LN_VALIDATION_INPUT_VALUE_OR_IBAN_OR_SWIFT,
  LN_VALIDATION_INPUT_VALUE_PHONE,
} from 'locales/constants'

export const validationText = {
  emailInvalid: LN_AUTH_VALIDATION_INVALID_EMAIL,
  emailRequired: LN_AUTH_VALIDATION_EMAIL_REQUIRED,
  passwordLength: LN_AUTH_VALIDATION_PASSWORD_NUM_CHARACTERS,
  passwordRequired: LN_AUTH_VALIDATION_PASSWORD_REQUIRED,
  repeatPasswordRequired: LN_AUTH_VALIDATION_REPEAT_PASSWORD_REQUIRED,
  repeatPasswordMatch: LN_AUTH_VALIDATION_PASSWORD_MUST_MATCH,

  inputValueIsRequired: LN_VALIDATION_INPUT_VALUE_IS_REQUIRED,
  inputValueNotPositive: LN_VALIDATION_INPUT_VALUE_NOT_POSITIVE,
  inputValueNotZero: LN_VALIDATION_INPUT_VALUE_NOT_ZERO,
  inputValuePhone: LN_VALIDATION_INPUT_VALUE_PHONE,

  invalidCryptoWalletAddress: LN_VALIDATION_INPUT_VALUE_INVALID_CRYPTO_WALLET_ADDRESS,
  invalidCardNumber: LN_VALIDATION_INPUT_VALUE_INVALID_CARD_NUMBER,
  invalidHolderName: LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME,
  invalidHolderNameMinSymbol: LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME_MIN_SYMBOL,
  fieldMustContainOnlyDigits: LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_DIGITS,
  fieldMustContainOnlyMin8Digits: LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MIN_8_DIGITS,
  fieldMustContainOnlyMax20Digits: LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_20_DIGITS,

  invalidIBAN: LN_VALIDATION_INPUT_VALUE_INVALID_IBAN,
  fieldMustContainAtLeastMin15Digits:
    LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_AT_LEAST_MIN_15_DIGITS,
  fieldMustContainOnlyMax34Digits: LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_34_DIGITS,

  invalidSWIFTCode: LN_VALIDATION_INPUT_VALUE_INVALID_SWIFT_CODE,
  fieldMustContainOnlyMax11Digits: LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_11_DIGITS,
  orIBANorSWIFT: LN_VALIDATION_INPUT_VALUE_OR_IBAN_OR_SWIFT,

  refundPolicy: LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_CHECKBOX,
  userTerms: LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_CHECKBOX,
}
