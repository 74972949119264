// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import {
  LN_NOTIFICATIONS_CONFIRM_EMAIL,
  LN_NOTIFICATIONS_GET_USER_COUNTRY,
  LN_NOTIFICATIONS_GET_USER_DEPOSIT_FIELDS,
  LN_NOTIFICATIONS_GET_USER_INFO,
  LN_USER_RESTRICTIONS,
} from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'

import { localStorageActiveUserTradeAccountKey } from 'constants/index'

// Store
import { setToken } from 'store/actions/Auth/actions'
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  onConfirmUserEmailResult,
  onSetCountriesList,
  onSetUserBalance,
  onSetUserCurrentTradingAccount,
  onSetUserRates,
  onSetUserTradingAccounts,
  setUserInfo,
} from 'store/actions/User/actions'
import {
  CONFIRM_EMAIL_ACTION,
  EDIT_USER_INFO,
  GET_COUNTRIES_LIST,
  GET_USER_BALANCE,
  GET_USER_INFO,
  PUSH_QUERY_USER_ACTIVE_AT,
} from 'store/actions/User/types'
import {
  editUserInfoAPI,
  getUserAPI,
  onGetCountriesListAPI,
  onGetUserBalance,
  onPutUserActiveAt,
  onVerificationEmailUserAPI,
} from 'store/api/user'
import { getErrorMessage, onUserNotAuth } from 'store/sagas/utils'

function* getUserSaga({ payload }) {
  const t = i18n.t.bind(i18n)
  try {
    const token = payload ? payload : yield select((state) => state.auth.token)
    if (token) {
      // console.log('token', token)
      const userInfoResponse = yield call(getUserAPI, { token })
      // console.log('getUserSaga', userInfoResponse)

      const loginIsForbidden = userInfoResponse?.data?.restrictions?.block_lead_login

      yield put(setUserInfo(userInfoResponse?.data))

      if (!loginIsForbidden) {
        // if login using autologin, set to store isAuthenticated an token selectors
        if (payload) {
          yield put(setToken(payload))
        }
        // const data = yield call(fakeApiCall)
        yield put(onSetUserTradingAccounts(userInfoResponse?.data?.trade_accounts))

        const tradingAccountFromLocalStorage = JSON.parse(
          localStorage.getItem(localStorageActiveUserTradeAccountKey),
        )

        const findBalanceTradeId = userInfoResponse?.data?.trade_accounts?.find(
          (el) => el?.trading_account_id === tradingAccountFromLocalStorage?.trading_account_id,
        )

        yield put(
          onSetUserCurrentTradingAccount(
            findBalanceTradeId
              ? tradingAccountFromLocalStorage
              : userInfoResponse?.data?.trade_accounts?.[0] || null,
          ),
        )
      } else {
        yield put(showToast(toastConstructor(toastType.error, t(LN_USER_RESTRICTIONS))))
        yield call(onUserNotAuth, {
          response: { status: 403 },
        })
      }
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_USER_INFO))),
    )

    yield call(onUserNotAuth, error)
    // yield call(onUserNotAuth, {
    //   response: { status: 403, data: { message: 'Access denied: wrong country' } },
    // })
  }
}

function* getCountriesSaga() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const userInfoResponse = yield call(onGetCountriesListAPI)
      yield put(onSetCountriesList(userInfoResponse?.countries))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(
      showToast(toastConstructor(toastType.error, result, t(LN_NOTIFICATIONS_GET_USER_COUNTRY))),
    )

    yield call(onUserNotAuth, error)
  }
}

function* getUserBalanceSaga({ payload }) {
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const response = yield call(onGetUserBalance, { tradeAccountId: payload })
      yield put(onSetUserBalance(response?.balance ?? 0))
      yield put(onSetUserRates(response?.rates ?? null))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))

    yield call(onUserNotAuth, error)
  }
}

function* editUserInfoSaga({ payload }) {
  try {
    const token = yield select((state) => state.auth.token)
    if (token) {
      const response = yield call(editUserInfoAPI, payload)
      const userInfoResponse = yield call(getUserAPI, { token })
      yield put(setUserInfo(userInfoResponse?.data))
      yield put(showToast(toastConstructor(toastType.success, response?.message)))
    }
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result)))

    yield call(onUserNotAuth, error)
  }
}

const toastTitle = LN_NOTIFICATIONS_CONFIRM_EMAIL
function* onVerificationEmailUserSaga() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)
    const userVerificationResponse = yield call(onVerificationEmailUserAPI)
    yield put(
      showToast(
        toastConstructor(toastType.success, userVerificationResponse?.message, t(toastTitle)),
      ),
    )
    yield put(onConfirmUserEmailResult())
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result, t(toastTitle))))

    yield put(onConfirmUserEmailResult())
    yield call(onUserNotAuth, error)
  }
}

function* onPushQueryUserActiveAtSaga() {
  try {
    const token = yield select((state) => state.auth.token)
    yield call(onPutUserActiveAt)
  } catch (error) {
    yield call(onUserNotAuth, error)
  }
}

export function* watchUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserSaga)
  yield takeEvery(CONFIRM_EMAIL_ACTION, onVerificationEmailUserSaga)
  yield takeEvery(GET_COUNTRIES_LIST, getCountriesSaga)
  yield takeEvery(GET_USER_BALANCE, getUserBalanceSaga)
  yield takeEvery(PUSH_QUERY_USER_ACTIVE_AT, onPushQueryUserActiveAtSaga)
  yield takeEvery(EDIT_USER_INFO, editUserInfoSaga)
}
