// Languages
import {
  LN_DEPOSIT_TAB_HISTORY_STATUS_ATTEMP,
  LN_DEPOSIT_TAB_HISTORY_STATUS_FAILED,
  LN_DEPOSIT_TAB_HISTORY_STATUS_PENDING,
  LN_DEPOSIT_TAB_HISTORY_STATUS_SUCCESS,
  LN_DEPOSIT_TAB_HISTORY_STATUS_SUSPENDED,
} from 'locales/constants'

export const statusConstants = {
  1: LN_DEPOSIT_TAB_HISTORY_STATUS_ATTEMP,
  2: LN_DEPOSIT_TAB_HISTORY_STATUS_PENDING,
  3: LN_DEPOSIT_TAB_HISTORY_STATUS_SUSPENDED,
  4: LN_DEPOSIT_TAB_HISTORY_STATUS_FAILED,
  5: LN_DEPOSIT_TAB_HISTORY_STATUS_SUCCESS,
}

export const statusServerConstants = {
  attemp: 1,
  pending: 2,
  suspended: 3,
  failed: 4,
  success: 5,
}

export const depositTypeConstants = {
  1: 'up sale',
  2: 'FTD',
  3: 'pre FTD',
}
