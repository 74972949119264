// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// Languages
import {
  LN_DEPOSIT_TAB_HISTORY_INFO_BTN,
  LN_USER_WITHDRAWAL_TABLE_INFO_BTN,
} from 'locales/constants'

// Components
import { TextMd } from 'components'
import DescriptionBtn from 'components/SmallList/DescriptionBtn'
import StatusItem from 'components/Table/TableHistory/StatusItem'
import { statusConstants, statusServerConstants } from 'components/Table/TableHistory/constants'

// Constants
import { FinanceTypeIds } from 'constants/finance'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDateToPrettierFullDateTime } from 'utils/dateTransform'
import { formatDigitsWithSpaces, roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Store
import {
  setDepositModalInfoItem,
  setIsDepositModalInfoOpen,
} from 'store/actions/UserDeposit/actions'
import {
  cancelWithdrawal,
  setIsWithdrawalModalInfoOpen,
  setWithdrawalModalInfoItem,
} from 'store/actions/Withdrawal/actions'

// UI
import { Flex, Icon, Td, Tr } from '@chakra-ui/react'

export const infoColumnWidth = '30px'

const TableRow = (props) => {
  const { row, typeFinance } = props

  const { isLightTheme } = useCurrentColorTheme()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onInfoWithdrawalBtn = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setWithdrawalModalInfoItem(row))
      dispatch(setIsWithdrawalModalInfoOpen(true))
    },
    [row, dispatch],
  )

  const onInfoDepositBtn = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setDepositModalInfoItem(row))
      dispatch(setIsDepositModalInfoOpen(true))
    },
    [row, dispatch],
  )

  const onCancelWithdrawal = useCallback(() => {
    dispatch(cancelWithdrawal({ financeId: row?.id }))
    dispatch(setWithdrawalModalInfoItem({ ...row, status: statusServerConstants?.failed }))
  }, [row, dispatch])

  const showRowDependsOnType = () => {
    switch (typeFinance) {
      case FinanceTypeIds.withdrawal:
        return (
          <>
            <Td>{row?.date ? formatDateToPrettierFullDateTime(row?.date) : '-'}</Td>
            <Td>
              {row?.amount ? formatDigitsWithSpaces(roundDigitsAfterDots(row?.amount, 2)) : '0'}
            </Td>
            <Td>{row?.currency}</Td>
            <Td>
              <Flex gap={2} alignItems="center">
                {row?.typeWithdrawalIcon && <Icon as={row?.typeWithdrawalIcon} />}
                {t(row?.typeWithdrawal)}
              </Flex>
            </Td>
            <Td>
              <StatusItem status={row?.status} isCancel onCancel={onCancelWithdrawal} />
            </Td>
            <Td style={{ width: infoColumnWidth }}>
              {!row?.description ? null : (
                <DescriptionBtn
                  onClick={onInfoWithdrawalBtn}
                  tooltipTitle={t(LN_USER_WITHDRAWAL_TABLE_INFO_BTN)}
                />
              )}
            </Td>
          </>
        )
      case FinanceTypeIds.bonus:
      case FinanceTypeIds.correction:
      case FinanceTypeIds.deposit:
        return (
          <>
            <Td>{row?.date ? formatDateToPrettierFullDateTime(row?.date) : '-'}</Td>
            <Td>
              <Flex gap={2} alignItems="center">
                <Icon as={row?.typeFinanceIcon} />
                {t(row?.typeFinance)}
              </Flex>
            </Td>
            <Td>
              {row?.amount ? formatDigitsWithSpaces(roundDigitsAfterDots(row?.amount, 2)) : '0'}
            </Td>
            <Td>{row?.currency}</Td>
            <Td>{row?.depositType ? row?.depositType?.toUpperCase() : '-'}</Td>
            <Td>
              <StatusItem status={row?.status} />
            </Td>
            <Td style={{ width: infoColumnWidth }}>
              {!row?.description ? null : (
                <DescriptionBtn
                  onClick={onInfoDepositBtn}
                  tooltipTitle={t(LN_DEPOSIT_TAB_HISTORY_INFO_BTN)}
                />
              )}
            </Td>
          </>
        )
      default:
        return <TextMd>-</TextMd>
    }
  }

  return (
    <Tr
      _hover={{
        transition: '0.8s',
        background: isLightTheme ? 'gray.100' : 'gray.700',
      }}
    >
      {showRowDependsOnType()}
    </Tr>
  )
}

export default memo(TableRow)
