// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { ErrorMessage, InfoTooltip, InputBase } from 'components'

// UI
import { Box, FormControl, FormLabel, InputGroup, InputRightAddon } from '@chakra-ui/react'

const DisabledInputInfo = (props) => {
  const {
    label,
    isInputRightAddon = false,
    inputRightAddonVal,
    disabled = true,
    type = 'text',
    isInnerElement = false,
    isRequired = false,
    placeholder,
    touched,
    error,
    getInputProps,
    isTooltip = false,
    ...args
  } = props

  const { t } = useTranslation()

  const isInputRightAddonInputStyle = isInputRightAddon
    ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
    : {}

  return (
    <Box mb={3} {...args}>
      <FormControl isRequired={isRequired}>
        {label && (
          <FormLabel display="flex" gap={1} fontSize={{ base: 'sm', sm: 'md' }}>
            {t(label)}
            {isTooltip && <InfoTooltip label={isTooltip} />}
          </FormLabel>
        )}
        <InputGroup>
          <InputBase
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            sx={{
              ':disabled': {
                opacity: 0.9,
              },
            }}
            size={{ base: 'sm', sm: 'md' }}
            {...isInputRightAddonInputStyle}
            {...getInputProps}
          />
          {isInputRightAddon && <InputRightAddon border={0}>{inputRightAddonVal}</InputRightAddon>}
          {isInnerElement ? isInnerElement : null}
        </InputGroup>
        {touched && error && <ErrorMessage error={t(error)} fontSize="sm" />}
      </FormControl>
    </Box>
  )
}

export default memo(DisabledInputInfo)
