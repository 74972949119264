// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_HISTORY_NO_DATA } from 'locales/constants'

// Components
import { NoDataMessage, TextSm } from 'components'
import OverflowLoadingBlock from 'components/LoadingBlock/OveflowLoadingBlock'
import Pagination from 'components/Table/Pagination'
import TableHeaderTh from 'components/Table/TableHistory/TableHeaderTh'
import TableRow from 'components/Table/TableHistory/TableRow'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import { paginationPerPage } from 'hooks/useOrderBottomPagination'

// UI
import { Box, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react'

const TableHistoryBase = (props) => {
  const {
    list,
    loading,
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage,
    columns,
    typeFinance,
    loadingOverflow = false,
  } = props

  const { t } = useTranslation()

  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      borderTop="1px solid"
      borderTopColor="navy.20"
      mt={0.5}
      mr={2}
      p="2px 15px"
      pb="25px"
    >
      {loadingOverflow && <OverflowLoadingBlock />}

      <Box>
        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr>
                {columns?.map((header) => (
                  <TableHeaderTh key={header.name} title={header.title} />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {!list || list?.length === 0 ? (
                <NoDataMessage />
              ) : (
                list?.map((el) => <TableRow key={el?.id} row={el} typeFinance={typeFinance} />)
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {paginationInfo?.total > paginationInfo?.per_page && !loading && (
        <Pagination
          gotoPage={onPage}
          nextPage={onNextPage}
          previousPage={onPreviousPage}
          total={paginationInfo?.total || 0}
          pageIndex={Number(paginationInfo?.page) || 1}
          pageSize={paginationInfo?.per_page || paginationPerPage}
        />
      )}
    </Box>
  )
}

export default memo(TableHistoryBase)
