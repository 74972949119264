// Modules
import { memo } from 'react'

// Components
import AuthTabs from 'pages/Auth/Login/AuthTabs'
import PageWrapper from 'pages/Auth/components/PageWrapper'

const LoginPage = () => {
  return (
    <PageWrapper
      cardBodyStyles={{ paddingTop: '5px' }}
      boxStyles={{ height: 'auto', minH: '100vh', paddingBottom: '30px' }}
    >
      <AuthTabs />
    </PageWrapper>
  )
}

export default memo(LoginPage)
