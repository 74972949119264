// Modules
import { call, put, takeEvery } from 'redux-saga/effects'

// Languages
import {
  LN_KYC_DELETE_FIELD,
  LN_KYC_GET_FIELD_LIST,
  LN_KYC_SEND_FIELD,
  LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE,
  LN_TRANSACTION_CREATE_NOTIFICATION_TITLE,
} from 'locales/constants'
import i18n from 'locales/i18n'

// Components
import { toastConstructor, toastType } from 'components/ToastApp/constants'

// Store
import { getKYCFieldsList, setKYCFieldsList } from 'store/actions/KYC/actions'
import { DELETE_KYC_FIELD, GET_KYC_FIELD_LIST, SEND_KYC_FIELD } from 'store/actions/KYC/types'
import { showToast } from 'store/actions/ToastsApp/actions'
import { deleteKYCFieldAPI, getKYCFieldsListAPI, sendKYCFieldAPI } from 'store/api/kyc'
import { getErrorMessage } from 'store/sagas/utils'

function* getKYCFieldListSaga() {
  const t = i18n.t.bind(i18n)
  try {
    const kycFieldList = yield call(getKYCFieldsListAPI)
    yield put(setKYCFieldsList(kycFieldList))
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result, t(LN_KYC_GET_FIELD_LIST))))
  }
}

function* sendKYCFieldSaga({ payload }) {
  const t = i18n.t.bind(i18n)
  const { name, file } = payload
  try {
    const response = yield call(sendKYCFieldAPI, { name, file })
    yield put(getKYCFieldsList())
    yield put(
      showToast(
        toastConstructor(
          toastType.success,
          t(LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE),
          response.message,
        ),
      ),
    )
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result, t(LN_KYC_SEND_FIELD))))
  }
}

function* deleteKYCFieldSaga(action) {
  const t = i18n.t.bind(i18n)
  const { name } = action.payload
  try {
    const response = yield call(deleteKYCFieldAPI, { name })
    yield put(getKYCFieldsList())
    yield put(
      showToast(
        toastConstructor(
          toastType.success,
          t(LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE),
          response.message,
        ),
      ),
    )
  } catch (error) {
    const result = yield call(getErrorMessage, error)
    yield put(showToast(toastConstructor(toastType.error, result, t(LN_KYC_DELETE_FIELD))))
  }
}

export function* watchKYCInfo() {
  yield takeEvery(GET_KYC_FIELD_LIST, getKYCFieldListSaga)
  yield takeEvery(SEND_KYC_FIELD, sendKYCFieldSaga)
  yield takeEvery(DELETE_KYC_FIELD, deleteKYCFieldSaga)
}
