// Types
import {
  CANCEL_WITHDRAWAL,
  CREATE_WITHDRAWAL,
  CREATE_WITHDRAWAL_SET_LOADING,
  GET_WITHDRAWAL_HISTORY,
  GET_WITHDRAWAL_METHODS,
  SET_CANCEL_WITHDRAWAL_SUCCESS,
  SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  SET_LOADING_CANCEL_WITHDRAWAL,
  SET_WITHDRAWAL_HISTORY,
  SET_WITHDRAWAL_METHODS,
  SET_WITHDRAWAL_MODAL_ITEM_INFO,
} from 'store/actions/Withdrawal/types'

export const getWithdrawalHistory = (payload) => ({
  type: GET_WITHDRAWAL_HISTORY,
  payload: payload,
})

export const setWithdrawalHistory = (payload) => ({
  type: SET_WITHDRAWAL_HISTORY,
  payload: payload,
})

export const setIsWithdrawalModalInfoOpen = (payload) => ({
  type: SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  payload: payload,
})

export const setWithdrawalModalInfoItem = (payload) => ({
  type: SET_WITHDRAWAL_MODAL_ITEM_INFO,
  payload: payload,
})

export const getWithdrawalMethods = () => ({
  type: GET_WITHDRAWAL_METHODS,
})

export const setWithdrawalMethods = (payload) => ({
  type: SET_WITHDRAWAL_METHODS,
  payload: payload,
})

export const createWithdrawal = (payload) => ({
  type: CREATE_WITHDRAWAL,
  payload: payload,
})

export const setLoadingCreateWithdrawal = (payload) => ({
  type: CREATE_WITHDRAWAL_SET_LOADING,
  payload: payload,
})

export const cancelWithdrawal = (payload) => ({
  type: CANCEL_WITHDRAWAL,
  payload: payload,
})

export const setLoadingCancelWithdrawal = (payload) => ({
  type: SET_LOADING_CANCEL_WITHDRAWAL,
  payload: payload,
})

export const setCancelWithdrawalSuccess = (payload) => ({
  type: SET_CANCEL_WITHDRAWAL_SUCCESS,
  payload: payload,
})
