export const SET_EXPANDABLE_ACCORDION_INDS = 'SET_EXPANDABLE_ACCORDION_INDS'
export const DELETE_EXPANDABLE_ACCORDION_INDS = 'DELETE_EXPANDABLE_ACCORDION_INDS'
export const ADD_EXPANDABLE_ACCORDION_INDS = 'ADD_EXPANDABLE_ACCORDION_INDS'

export const SET_INNER_LIST_EXPANDABLE_ACCORDION_INDS = 'SET_INNER_LIST_EXPANDABLE_ACCORDION_INDS'
export const DELETE_INNER_LIST_EXPANDABLE_ACCORDION_INDS =
  'DELETE_INNER_LIST_EXPANDABLE_ACCORDION_INDS'
export const ADD_INNER_LIST_EXPANDABLE_ACCORDION_INDS = 'ADD_INNER_LIST_EXPANDABLE_ACCORDION_INDS'

export const SET_ACTIVE_MARKET_TOP_TAB = 'SET_ACTIVE_MARKET_TOP_TAB'
// for socket parameters groups[], channels[]
export const WS_SET_SOCKET_CURRENCY_PARAMETERS = 'WS_SET_SOCKET_CURRENCY_PARAMETERS'

export const SET_ACCORDION_SEARCH_VALUE = 'SET_ACCORDION_SEARCH_VALUE'

export const SET_IS_CHOOSE_FIRST_TIME_INNER_ACCORDION_ID =
  'SET_IS_CHOOSE_FIRST_TIME_INNER_ACCORDION_ID'
