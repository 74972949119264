// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_SEARCH_ACCORDION_INPUT_TITLE } from 'locales/constants'

// Components
import { SearchInput, TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import MarketAccordion from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion'
import ListPanel from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel'
import TopTabs from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs'
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// UI
import { Box, Flex } from '@chakra-ui/react'

const MarketWatchMobileUI = (props) => {
  const { topTabValue, onChooseTab, searchValue, onSearch, loadingList, filtered, favourites } =
    props

  const { t } = useTranslation()

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" p={3} pb={4}>
        <TopTabs active={topTabValue} onChooseTab={onChooseTab} />
      </Flex>
      <Box p={3} pt={0}>
        <Box w="100%">
          <SearchInput
            searchedValue={searchValue}
            setSearchedValue={onSearch}
            mb={3}
            disabled={loadingList}
            placeholder={t(LN_SEARCH_ACCORDION_INPUT_TITLE) + '...'}
            fontSize="xs"
          />
          <Box pr={2} pb={2}>
            {topTabValue === tabsList[0].id ? (
              (!filtered || filtered?.length === 0) && !loadingList ? (
                <TextSm>No items</TextSm>
              ) : loadingList ? (
                <LoadingBlock />
              ) : (
                <MarketAccordion list={filtered} />
              )
            ) : (
              <ListPanel topTabValue={topTabValue} list={favourites} favourite />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default memo(MarketWatchMobileUI)
