// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_USER_WITHDRAWAL_TABLE_STATUS_CANCEL_BTN } from 'locales/constants'

// Components
import { CrossBtn, TextMd } from 'components'
import { statusConstants, statusServerConstants } from 'components/Table/TableHistory/constants'

// UI
import { Flex } from '@chakra-ui/react'

const StatusItem = (props) => {
  const { status, isCancel, onCancel } = props
  const { t } = useTranslation()

  const statusBase = statusConstants?.[status]

  const title = t(statusBase)

  switch (status) {
    // if attemp or pending and withdrawal user can cancel it
    case statusServerConstants.attemp:
      return !isCancel ? (
        <TextMd color="red.200">{title}</TextMd>
      ) : (
        <CancelStatus title={title} color="red.200" onCancel={onCancel} />
      )
    case statusServerConstants.pending:
      return !isCancel ? (
        <TextMd color="blue.200">{title}</TextMd>
      ) : (
        <CancelStatus title={title} color="blue.200" onCancel={onCancel} />
      )
    case statusServerConstants.suspended:
      return <TextMd color="gray.200">{title}</TextMd>
    case statusServerConstants.failed:
      return <TextMd color="red.200">{title}</TextMd>
    case statusServerConstants.success:
      return <TextMd color="green.200">{title}</TextMd>
    default:
      return <TextMd>-</TextMd>
  }
}

export default memo(StatusItem)

const CancelStatus = memo(({ title, color, onCancel }) => {
  const { t } = useTranslation()

  return (
    <Flex gap={1} alignItems="center">
      <TextMd color={color}>{title}</TextMd>
      <CrossBtn tooltipTitle={t(LN_USER_WITHDRAWAL_TABLE_STATUS_CANCEL_BTN)} onClick={onCancel} />
    </Flex>
  )
})

CancelStatus.displayName = 'CancelStatus'
