// Modules
import Cookies from 'js-cookie'
import { put } from 'redux-saga/effects'

// Store
import { onAccessDenied, onNonAuth } from 'store/actions/Auth/actions'
import { accessDeniedMessage, cookieAuthToken } from 'store/api'

export function* onUserNotAuth(error) {
  if (error?.response?.status === 403 || error?.response?.status === 404) {
    if (error?.response?.data?.message === accessDeniedMessage) {
      // Clear token from cookies
      Cookies.remove(cookieAuthToken)
      yield put(onAccessDenied())
    } else {
      // Clear token from cookies
      Cookies.remove(cookieAuthToken)

      yield put(onNonAuth())
    }
  }
}

export function getErrorMessage(error) {
  return error?.response?.data?.error_message
}
