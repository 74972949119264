// Modules
import { BiLogoBitcoin } from 'react-icons/bi'
import { FaRegCreditCard } from 'react-icons/fa6'
import { GiTwoCoins } from 'react-icons/gi'
import { RiBankLine } from 'react-icons/ri'
import { SiEthereum } from 'react-icons/si'

// Languages
import {
  LN_DEPOSIT_TAB_HISTORY_HEADER_STATUS,
  LN_USER_WITHDRAWAL_TABLE_AMOUNT,
  LN_USER_WITHDRAWAL_TABLE_CURRENCY,
  LN_USER_WITHDRAWAL_TABLE_DATE,
  LN_USER_WITHDRAWAL_TABLE_STATUS,
  LN_USER_WITHDRAWAL_TABLE_STATUS_FAILED,
  LN_USER_WITHDRAWAL_TABLE_STATUS_SUCCESS,
  LN_USER_WITHDRAWAL_TABLE_STATUS_WAITING,
  LN_USER_WITHDRAWAL_TABLE_TYPE,
} from 'locales/constants'

// Constants
import { withdrawalMethodsConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'

export const typeWithdrawalIcon = {
  [withdrawalMethodsConstants.bank]: RiBankLine,
  [withdrawalMethodsConstants.card]: FaRegCreditCard,
  [withdrawalMethodsConstants.ethereum]: SiEthereum,
  [withdrawalMethodsConstants.usdt]: GiTwoCoins,
  [withdrawalMethodsConstants.bitcoin]: BiLogoBitcoin,
}

export const statusLanguageConstants = {
  waiting: LN_USER_WITHDRAWAL_TABLE_STATUS_WAITING,
  failed: LN_USER_WITHDRAWAL_TABLE_STATUS_FAILED,
  success: LN_USER_WITHDRAWAL_TABLE_STATUS_SUCCESS,
}

export const headerConstants = {
  date: 'date',
  amount: 'amount',
  currency: 'currency',
  typeWithdrawal: 'typeWithdrawal',
  status: 'status',
  infoBtn: 'infoBtn',
}

export const columns = [
  {
    title: LN_USER_WITHDRAWAL_TABLE_DATE,
    name: headerConstants?.date,
  },
  {
    title: LN_USER_WITHDRAWAL_TABLE_AMOUNT,
    name: headerConstants?.amount,
  },
  {
    title: LN_USER_WITHDRAWAL_TABLE_CURRENCY,
    name: headerConstants?.currency,
  },
  {
    title: LN_USER_WITHDRAWAL_TABLE_TYPE,
    name: headerConstants?.typeWithdrawal,
  },
  {
    title: LN_USER_WITHDRAWAL_TABLE_STATUS,
    name: headerConstants?.status,
  },
  {
    title: '',
    name: headerConstants?.infoBtn,
  },
]
