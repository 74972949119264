// Modules
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiShieldCheck } from 'react-icons/hi2'
import { TbShieldCancel } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Languages
import {
  LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_MESSAGE,
  LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_TITLE,
  LN_USER_PERSONAL_INFORMATION_NOT_VERIFIED,
  LN_USER_PERSONAL_INFORMATION_STATUS,
  LN_USER_PERSONAL_INFORMATION_VERIFIED,
} from 'locales/constants'

// Components
import { TextMd, TextSm } from 'components'
import { isUserEmailVerifiedKey } from 'components/LanguageDropdown/constants'
import { toastConstructor, toastType } from 'components/ToastApp/constants'
import RestrictionPlatform from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionPlatform'
import RestrictionUserProfile from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionUserProfile'
import UserInfoForm from 'pages/User/components/Content/PersonalInfo/UserInfoForm/UserInfoForm'
import { MainTitle } from 'pages/User/components/Content/Withdrawal'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useGetUserCountyCode from 'hooks/useGetUserCountyCode'

// Store
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  loadingConfirmEmailSelector,
  loadingUserInfoSelector,
  userInfoSelector,
} from 'store/actions/User/selectors'

// UI
import { Box, Flex, Icon, Stack } from '@chakra-ui/react'

const PersonalInfo = () => {
  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  const location = useLocation()
  // TODO: for email verification
  const searchParams = new URLSearchParams(location.search)
  const signature = searchParams.get('signature')

  const dispatch = useDispatch()
  const userInfo = useSelector(userInfoSelector)
  const loadingConfirmEmail = useSelector(loadingConfirmEmailSelector)
  const loadingUserInfo = useSelector(loadingUserInfoSelector)

  const { countriesListLoading } = useGetUserCountyCode()
  const { isRestricted, isTradePlatformSettings } = useCheckUserRestrictions()

  const isEmailVerified = userInfo?.email_verified_at

  useEffect(() => {
    const hasAlreadyVerified = localStorage.getItem(isUserEmailVerifiedKey)
    if (signature && hasAlreadyVerified !== 'true') {
      localStorage.setItem(isUserEmailVerifiedKey, 'true')
      dispatch(
        showToast(
          toastConstructor(
            toastType.success,
            t(LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_MESSAGE),
            t(LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_TITLE),
          ),
        ),
      )
    }
  }, [isEmailVerified, signature, dispatch, t])

  const loading = loadingUserInfo || countriesListLoading

  return (
    <Box w="100%" p={3}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', sm: 'center' }}
        pb={5}
        flexDirection={{ base: 'column', sm: 'row' }}
        gap={{ base: 4, sm: 0 }}
      >
        <MainTitle title={t(sidebarList[0]?.title)} pb={0} />
        {!loading && (
          <Flex gap={2} alignItems="center">
            <TextSm color={mainTextColor} fontWeight="500">
              {t(LN_USER_PERSONAL_INFORMATION_STATUS)}:
            </TextSm>
            {userInfo?.email_verified_at ? (
              <>
                <TextMd color="green.200" fontWeight="600">
                  {t(LN_USER_PERSONAL_INFORMATION_VERIFIED)}
                </TextMd>
                <Icon as={HiShieldCheck} color="green.200" />
              </>
            ) : (
              <>
                <TextMd color="red.200" fontWeight="600">
                  {t(LN_USER_PERSONAL_INFORMATION_NOT_VERIFIED)}
                </TextMd>
                <Icon as={TbShieldCancel} color="red.200" />
              </>
            )}
          </Flex>
        )}
      </Flex>
      <UserInfoForm loading={loading} />
      <Stack gap={5} mt={10} pb={5}>
        {isRestricted && <RestrictionUserProfile />}
        {isTradePlatformSettings && <RestrictionPlatform />}
      </Stack>
    </Box>
  )
}

export default memo(PersonalInfo)
