// Constants
import { VerificationTabValueDefault } from 'pages/User/components/Content/Verification/components/VerificationTabs/constants'

// Types
import {
  GET_KYC_FIELD_LIST,
  SET_KYC_FIELD_LIST,
  SET_VERIFICATION_FIELDS_TAB,
} from 'store/actions/KYC/types'

const initialState = {
  kycFieldList: null,
  kycFieldListLoading: false,

  verificationTabValue: VerificationTabValueDefault,
}

const kycReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KYC_FIELD_LIST:
      return {
        ...state,
        kycFieldListLoading: true,
        // kycFieldList: action.payload,
      }
    case SET_KYC_FIELD_LIST:
      return {
        ...state,
        kycFieldList: action.payload,
        kycFieldListLoading: false,
      }

    case SET_VERIFICATION_FIELDS_TAB:
      return {
        ...state,
        verificationTabValue: action.payload,
      }

    default:
      return state
  }
}
export default kycReducer
