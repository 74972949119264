
export const LN_SEARCH_INPUT_TITLE = "search_input_title";
export const LN_SEARCH_ACCORDION_INPUT_TITLE = "search_accordion_input_title";
export const LN_WARNING_ALERT_CANCEL_BTN = "warning_alert_cancel_btn";
export const LN_PAGINATION_SHOW = "pagination_show";
export const LN_PAGINATION_PAGE = "pagination_page";
export const LN_PAGINATION_OF = "pagination_of";
export const LN_SORRY_PAGE_NOT_FOUND = "sorry_page_not_found";
export const LN_SORRY_PAGE_WRONG_COUNTRY = "sorry_page_wrong_country";
export const LN_BACK_TO_DASHBOARD_BTN = "back_to_dashboard_btn";
export const LN_USER_RESTRICTIONS = "user_restrictions";
export const LN_USER_RESTRICTIONS_TITLE = "user_restrictions_title";
export const LN_USER_RESTRICTIONS_OPEN_TRADE = "user_restrictions_open_trade";
export const LN_USER_RESTRICTIONS_CLOSE_TRADE = "user_restrictions_close_trade";
export const LN_USER_RESTRICTIONS_WITHDRAWALS = "user_restrictions_withdrawals";
export const LN_PLATFORM_RESTRICTIONS_TITLE = "platform_restrictions_title";
export const LN_PLATFORM_RESTRICTIONS_IS_TRADE_ALLOWED_FLAG = "platform_restrictions_is_trade_allowed_flag";
export const LN_PLATFORM_RESTRICTIONS_ALLOW_OPEN_TRADE_IN_NON_TRADING_TIME = "platform_restrictions_allow_open_trade_in_non_trading_time";
export const LN_PLATFORM_RESTRICTIONS_ALLOW_DEPOSIT_WITHOUT_VERIFICATION = "platform_restrictions_allow_deposit_without_verification";
export const LN_PLATFORM_RESTRICTIONS_NOT_ALLOWED_EDIT_USER_INFO = "platform_restrictions_not_allowed_edit_user_info";
export const LN_NOTIFICATIONS_FORGOT_PASSWORD = "notifications_forgot_password";
export const LN_NOTIFICATIONS_GET_MARKET_LIST = "notifications_get_market_list";
export const LN_NOTIFICATIONS_GET_ASSERT_LIST = "notifications_get_assert_list";
export const LN_NOTIFICATIONS_GET_USER_INFO = "notifications_get_user_info";
export const LN_NOTIFICATIONS_GET_USER_COUNTRY = "notifications_get_user_country";
export const LN_NOTIFICATIONS_GET_USER_DEPOSIT_HISTORY = "notifications_get_user_deposit_history";
export const LN_NOTIFICATIONS_GET_USER_DEPOSIT_FIELDS = "notifications_get_user_deposit_fields";
export const LN_NOTIFICATIONS_CONFIRM_EMAIL = "notifications_confirm_email";
export const LN_NOTIFICATIONS_GET_WITHDRAWAL_FIELDS = "notifications_get_withdrawal_fields";
export const LN_NOTIFICATIONS_CREATE_WITHDRAWAL = "notifications_create_withdrawal";
export const LN_SIDEBAR_LIST_TRADING = "sidebar_list_trading";
export const LN_SIDEBAR_LIST_TRADING_DEPOSIT = "sidebar_list_trading_deposit";
export const LN_SIDEBAR_LIST_TRADING_HISTORY = "sidebar_list_trading_history";
export const LN_SIDEBAR_LIST_LOG_OUT = "sidebar_list_log_out";
export const LN_DEPOSIT_TAB_LINK_BTN_TITLE = "deposit_tab_link_btn_title";
export const LN_DEPOSIT_TAB_DESC_TEXT_WALLET_REQUESTED = "deposit_tab_desc_text_wallet_requested";
export const LN_DEPOSIT_TAB_COPY_BTN_ACTION_TEXT = "deposit_tab_copy_btn_action_text";
export const LN_DEPOSIT_TAB_HISTORY_TITLE = "deposit_tab_history_title";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_DATE = "deposit_tab_history_header_date";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_AMOUNT = "deposit_tab_history_header_amount";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_FINANCE_TYPE = "deposit_tab_history_header_finance_type";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_CURRENCY = "deposit_tab_history_header_currency";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_DEPOSIT_TYPE = "deposit_tab_history_header_deposit_type";
export const LN_DEPOSIT_TAB_HISTORY_HEADER_STATUS = "deposit_tab_history_header_status";
export const LN_DEPOSIT_TAB_HISTORY_STATUS_SUCCESS = "deposit_tab_history_status_success";
export const LN_DEPOSIT_TAB_HISTORY_STATUS_FAILED = "deposit_tab_history_status_failed";
export const LN_DEPOSIT_TAB_HISTORY_STATUS_SUSPENDED = "deposit_tab_history_status_suspended";
export const LN_DEPOSIT_TAB_HISTORY_STATUS_PENDING = "deposit_tab_history_status_pending";
export const LN_DEPOSIT_TAB_HISTORY_STATUS_ATTEMP = "deposit_tab_history_status_attemp";
export const LN_DEPOSIT_TAB_HISTORY_INFO_BTN = "deposit_tab_history_info_btn";
export const LN_DEPOSIT_TAB_HISTORY_NO_DATA = "deposit_tab_history_no_data";
export const LN_FINANCE_TYPE_DEPOSIT = "finance_type_deposit";
export const LN_FINANCE_TYPE_CORRECTION = "finance_type_correction";
export const LN_FINANCE_TYPE_BONUS = "finance_type_bonus";
export const LN_FINANCE_TYPE_WITHDRAWAL = "finance_type_withdrawal";
export const LN_MARKET_SIDEBAR_ALL = "market_sidebar_all";
export const LN_MARKET_SIDEBAR_FAVOURITE = "market_sidebar_favourite";
export const LN_MARKET_SIDEBAR_ACCORDION_ADD_FAVOURITE = "market_sidebar_accordion_add_favourite";
export const LN_MARKET_SIDEBAR_ACCORDION_ADD_TRADE = "market_sidebar_accordion_add_trade";
export const LN_MARKET_SIDEBAR_ACCORDION_NO_ITEMS = "market_sidebar_accordion_no_items";
export const LN_CLOSE_ALL_TRADES_BTN = "close_all_trades_btn";
export const LN_NAVBAR_BALANCE = "navbar_balance";
export const LN_NAVBAR_MARGIN_INFO_TITLE = "navbar_margin_info_title";
export const LN_NAVBAR_MARGIN_INFO_LEVEL = "navbar_margin_info_level";
export const LN_NAVBAR_MARGIN_INFO_USAGE = "navbar_margin_info_usage";
export const LN_NAVBAR_MARGIN_INFO_AVAILABLE = "navbar_margin_info_available";
export const LN_NAVBAR_MARGIN_INFO_EQUITY = "navbar_margin_info_equity";
export const LN_NAVBAR_MARGIN_INFO_PL = "navbar_margin_info_pl";
export const LN_NAVBAR_MARGIN_INFO_CREDIT = "navbar_margin_info_credit";
export const LN_NAVBAR_SUBSCRIPTION_BRONZE = "navbar_subscription_bronze";
export const LN_NAVBAR_SUBSCRIPTION_SILVER = "navbar_subscription_silver";
export const LN_NAVBAR_SUBSCRIPTION_GOLD = "navbar_subscription_gold";
export const LN_NAVBAR_SUBSCRIPTION_PLATINUM = "navbar_subscription_platinum";
export const LN_NAVBAR_SUBSCRIPTION_TITLE = "navbar_subscription_title";
export const LN_HISTORY_POSITIONS = "history_positions";
export const LN_HISTORY_PENDING_ORDERS = "history_pending_orders";
export const LN_HISTORY_PENDING_CLOSED_POSITIONS = "history_pending_closed_positions";
export const LN_HISTORY_ALL = "history_all";
export const LN_HISTORY_SELL = "history_sell";
export const LN_HISTORY_BUY = "history_buy";
export const LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE = "history_search_by_instrument_title";
export const LN_HISTORY_CLOSE_ALL = "history_close_all";
export const LN_HISTORY_NO_DATA = "history_no_data";
export const LN_HISTORY_TABLE_ORDER_ID = "history_table_order_id";
export const LN_HISTORY_TABLE_CREATED_TIME = "history_table_created_time";
export const LN_HISTORY_TABLE_INSTRUMENT = "history_table_instrument";
export const LN_HISTORY_TABLE_TYPE = "history_table_type";
export const LN_HISTORY_TABLE_VOLUME = "history_table_volume";
export const LN_HISTORY_TABLE_OPEN_PRICE = "history_table_open_price";
export const LN_HISTORY_TABLE_CURRENT_RATE = "history_table_current_rate";
export const LN_HISTORY_TABLE_TAKE_PROFIT = "history_table_take_profit";
export const LN_HISTORY_TABLE_STOP_LOSS = "history_table_stop_loss";
export const LN_HISTORY_TABLE_SWAP = "history_table_swap";
export const LN_HISTORY_TABLE_PROFIT = "history_table_profit";
export const LN_HISTORY_TABLE_START_RATE = "history_table_start_rate";
export const LN_HISTORY_TABLE_CLOSE_PROFIT = "history_table_close_profit";
export const LN_HISTORY_TABLE_CLOSED_TIME = "history_table_closed_time";
export const LN_HISTORY_TABLE_CLOSE_PRICE = "history_table_close_price";
export const LN_HISTORY_TABLE_FINAL_PROFIT = "history_table_final_profit";
export const LN_HISTORY_TABLE_CLOSE_BTN = "history_table_close_btn";
export const LN_HISTORY_TABLE_DELETE_ALERT_MESSAGE = "history_table_delete_alert_message";
export const LN_HISTORY_TABLE_DELETE_ALERT_BTN = "history_table_delete_alert_btn";
export const LN_HISTORY_TOTAL_VOLUME = "history_total_volume";
export const LN_HISTORY_TOTAL_PROFIT = "history_total_profit";
export const LN_HISTORY_SELECT_TIME_TITLE = "history_select_time_title";
export const LN_HISTORY_SELECT_TIME_ALL_TIME = "history_select_time_all_time";
export const LN_HISTORY_SELECT_TIME_TODAY = "history_select_time_today";
export const LN_HISTORY_SELECT_TIME_YESTERDAY = "history_select_time_yesterday";
export const LN_HISTORY_SELECT_TIME_CURRENT_WEEK = "history_select_time_current_week";
export const LN_HISTORY_SELECT_TIME_CURRENT_MONTH = "history_select_time_current_month";
export const LN_HISTORY_SELECT_TIME_LAST_WEEK = "history_select_time_last_week";
export const LN_HISTORY_SELECT_TIME_LAST_MONTH = "history_select_time_last_month";
export const LN_HISTORY_CLOSE_ALL_ALERT_MESSAGE = "history_close_all_alert_message";
export const LN_ADD_NEW_TRADE_MODAL_TITLE = "add_new_trade_modal_title";
export const LN_ADD_NEW_TRADE_MODAL_BTN_TITLE = "add_new_trade_modal_btn_title";
export const LN_ADD_NEW_TRADE_MODAL_FAVOURITES_TAB = "add_new_trade_modal_favourites_tab";
export const LN_ADD_NEW_TRADE_MODAL_TABLE_ASSET = "add_new_trade_modal_table_asset";
export const LN_ADD_NEW_TRADE_MODAL_TABLE_BUY = "add_new_trade_modal_table_buy";
export const LN_ADD_NEW_TRADE_MODAL_TABLE_SELL = "add_new_trade_modal_table_sell";
export const LN_ADD_NEW_TRADE_MODAL_TABLE_NO_DATA = "add_new_trade_modal_table_no_data";
export const LN_ADD_NEW_TRADE_MODAL_TABLE_NO_ITEMS = "add_new_trade_modal_table_no_items";
export const LN_TRADES_BAR_SET_ACTIVE_TRADE = "trades_bar_set_active_trade";
export const LN_TRADES_BAR_DELETE_TRADE = "trades_bar_delete_trade";
export const LN_TRANSACTION_CALC_VOLUME = "transaction_calc_volume";
export const LN_TRANSACTION_CALC_RESET = "transaction_calc_reset";
export const LN_TRANSACTION_CALC_LOTS = "transaction_calc_lots";
export const LN_TRANSACTION_CALC_UNITS = "transaction_calc_units";
export const LN_TRANSACTION_CALC_CURRENCY = "transaction_calc_currency";
export const LN_TRANSACTION_CALC_SPREAD = "transaction_calc_spread";
export const LN_TRANSACTION_CALC_SWAP = "transaction_calc_swap";
export const LN_TRANSACTION_CALC_SYMBOL_POINT = "transaction_calc_symbol_point";
export const LN_TRANSACTION_CALC_CONTRACT_SIZE = "transaction_calc_contract_size";
export const LN_TRANSACTION_CALC_POSITION = "transaction_calc_position";
export const LN_TRANSACTION_CALC_MARGIN = "transaction_calc_margin";
export const LN_TRANSACTION_CALC_FREE_MARGIN = "transaction_calc_free_margin";
export const LN_TRANSACTION_CALC_LEVERAGE = "transaction_calc_leverage";
export const LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS = "transaction_calc_take_profit_stop_loss";
export const LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_RATE = "transaction_calc_take_profit_stop_loss_by_rate";
export const LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_PROFIT = "transaction_calc_take_profit_stop_loss_by_profit";
export const LN_TRANSACTION_CALC_TAKE_PROFIT = "transaction_calc_take_profit";
export const LN_TRANSACTION_CALC_STOP_LOSS = "transaction_calc_stop_loss";
export const LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET = "transaction_calc_stop_loss_not_set";
export const LN_TRANSACTION_CALC_PENDING = "transaction_calc_pending";
export const LN_TRANSACTION_CALC_PENDING_AT = "transaction_calc_pending_at";
export const LN_TRANSACTION_CALC_ASSET_PRICE = "transaction_calc_asset_price";
export const LN_TRANSACTION_CALC_REVERT_MARKET_PRICE_BTN = "transaction_calc_revert_market_price_btn";
export const LN_TRANSACTION_CALC_PENDING_MODAL_MESSAGE = "transaction_calc_pending_modal_message";
export const LN_TRANSACTION_CALC_DEFAULT_BTN = "transaction_calc_default_btn";
export const LN_TRANSACTION_CALC_BTN_BUY = "transaction_calc_btn_buy";
export const LN_TRANSACTION_CALC_BTN_SELL = "transaction_calc_btn_sell";
export const LN_TRANSACTION_CALC_BTN_SHOW_PROFIT_CALCULATOR = "transaction_calc_btn_show_profit_calculator";
export const LN_TRANSACTION_MARKET_CLOSED_WARNING_OPEN_HOURS = "transaction_market_closed_warning_open_hours";
export const LN_TRANSACTION_MARKET_CLOSED_WARNING = "transaction_market_closed_warning";
export const LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE = "transaction_close_notification_title";
export const LN_TRANSACTION_CREATE_NOTIFICATION_TITLE = "transaction_create_notification_title";
export const LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE = "transaction_create_notification_success_message";
export const LN_TRANSACTION_NOTIFICATION_CURRENCY_NOT_CHOSEN = "transaction_notification_currency_not_chosen";
export const LN_TRANSACTION_NOTIFICATION_NO_USER_TRADING_ACCOUNT = "transaction_notification_no_user_trading_account";
export const LN_TRANSACTION_CALC_MODAL_PROFIT_CALCULATOR_TITLE = "transaction_calc_modal_profit_calculator_title";
export const LN_TRANSACTION_CALC_MODAL_BUY_TAB = "transaction_calc_modal_buy_tab";
export const LN_TRANSACTION_CALC_MODAL_SELL_TAB = "transaction_calc_modal_sell_tab";
export const LN_TRANSACTION_CALC_MODAL_STOP_LOSS = "transaction_calc_modal_stop_loss";
export const LN_TRANSACTION_CALC_MODAL_TAKE_PROFIT = "transaction_calc_modal_take_profit";
export const LN_TRANSACTION_CALC_MODAL_ENTRY_PRICE = "transaction_calc_modal_entry_price";
export const LN_TRANSACTION_CALC_MODAL_VOLUME = "transaction_calc_modal_volume";
export const LN_TRANSACTION_CALC_MODAL_CALCULATIONS_FOR = "transaction_calc_modal_calculations_for";
export const LN_TRANSACTION_CALC_MODAL_LEVERAGE = "transaction_calc_modal_leverage";
export const LN_TRANSACTION_CALC_MODAL_REQUIRED_MARGIN = "transaction_calc_modal_required_margin";
export const LN_TRANSACTION_CALC_MODAL_PROFIT_TP = "transaction_calc_modal_profit_tp";
export const LN_TRANSACTION_CALC_MODAL_LOSS_SL = "transaction_calc_modal_loss_sl";
export const LN_TRANSACTION_CALC_MODAL_ROE = "transaction_calc_modal_roe";
export const LN_TRANSACTION_CALC_MODAL_PIP = "transaction_calc_modal_pip";
export const LN_TRANSACTION_CALC_CALCULATE_BTN = "transaction_calc_calculate_btn";
export const LN_USER_PERSONAL_INFORMATION_TAB = "user_personal_information_tab";
export const LN_USER_PERSONAL_INFORMATION_FIRST_NAME = "user_personal_information_first_name";
export const LN_USER_PERSONAL_INFORMATION_LAST_NAME = "user_personal_information_last_name";
export const LN_USER_PERSONAL_INFORMATION_EMAIL = "user_personal_information_email";
export const LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER = "user_personal_information_phone_number";
export const LN_USER_PERSONAL_INFORMATION_COUNTRY = "user_personal_information_country";
export const LN_USER_PERSONAL_INFORMATION_CONFIRM_BTN = "user_personal_information_confirm_btn";
export const LN_USER_PERSONAL_INFORMATION_CONFIRMED_BTN = "user_personal_information_confirmed_btn";
export const LN_USER_PERSONAL_INFORMATION_STATUS = "user_personal_information_status";
export const LN_USER_PERSONAL_INFORMATION_NOT_VERIFIED = "user_personal_information_not_verified";
export const LN_USER_PERSONAL_INFORMATION_VERIFIED = "user_personal_information_verified";
export const LN_USER_PERSONAL_INFORMATION_SUBMIT_BTN = "user_personal_information_submit_btn";
export const LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_MESSAGE = "user_personal_information_email_notification_message";
export const LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_TITLE = "user_personal_information_email_notification_title";
export const LN_USER_DEPOSIT_TAB = "user_deposit_tab";
export const LN_USER_WITHDRAWAL_TAB = "user_withdrawal_tab";
export const LN_USER_WITHDRAWAL_TAB_NO_DATA = "user_withdrawal_tab_no_data";
export const LN_USER_WITHDRAWAL_REQUEST_NEW_WITHDRAWAL = "user_withdrawal_request_new_withdrawal";
export const LN_USER_WITHDRAWAL_AMOUNT = "user_withdrawal_amount";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS = "user_withdrawal_withdrawal_details";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BANK = "user_withdrawal_withdrawal_methods_bank";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_BITCOIN = "user_withdrawal_withdrawal_methods_bitcoin";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_ETHEREUM = "user_withdrawal_withdrawal_methods_ethereum";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_USDT = "user_withdrawal_withdrawal_methods_usdt";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_METHODS_CARD = "user_withdrawal_withdrawal_methods_card";
export const LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS_TITLE_FORM = "user_withdrawal_withdrawal_details_title_form";
export const LN_USER_WITHDRAWAL_ACCOUNT_NUMBER = "user_withdrawal_account_number";
export const LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME = "user_withdrawal_account_holder_name";
export const LN_USER_WITHDRAWAL_BANK_SWIFT_CODE = "user_withdrawal_bank_swift_code";
export const LN_USER_WITHDRAWAL_WALLET_ADDRESS = "user_withdrawal_wallet_address";
export const LN_USER_WITHDRAWAL_CARD_NUMBER = "user_withdrawal_card_number";
export const LN_USER_WITHDRAWAL_CARD_HOLDER_NAME = "user_withdrawal_card_holder_name";
export const LN_USER_WITHDRAWAL_REQUEST_WITHDRAWAL_SUBMIT_BTN = "user_withdrawal_request_withdrawal_submit_btn";
export const LN_USER_WITHDRAWAL_TABLE_WITHDRAWAL_REQUESTS_TITLE = "user_withdrawal_table_withdrawal_requests_title";
export const LN_USER_WITHDRAWAL_TABLE_DATE = "user_withdrawal_table_date";
export const LN_USER_WITHDRAWAL_TABLE_AMOUNT = "user_withdrawal_table_amount";
export const LN_USER_WITHDRAWAL_TABLE_CURRENCY = "user_withdrawal_table_currency";
export const LN_USER_WITHDRAWAL_TABLE_TYPE = "user_withdrawal_table_type";
export const LN_USER_WITHDRAWAL_TABLE_STATUS = "user_withdrawal_table_status";
export const LN_USER_WITHDRAWAL_TABLE_STATUS_WAITING = "user_withdrawal_table_status_waiting";
export const LN_USER_WITHDRAWAL_TABLE_STATUS_FAILED = "user_withdrawal_table_status_failed";
export const LN_USER_WITHDRAWAL_TABLE_STATUS_SUCCESS = "user_withdrawal_table_status_success";
export const LN_USER_WITHDRAWAL_TABLE_STATUS_CANCEL_BTN = "user_withdrawal_table_status_cancel_btn";
export const LN_USER_WITHDRAWAL_TABLE_INFO_BTN = "user_withdrawal_table_info_btn";
export const LN_USER_WITHDRAWAL_INFO_MODAL_TITLE = "user_withdrawal_info_modal_title";
export const LN_USER_VERIFICATION_TAB = "user_verification_tab";
export const LN_USER_NAVBAR_START_TRADING_BTN = "user_navbar_start_trading_btn";
export const LN_AUTH_VALIDATION_EMAIL_REQUIRED = "auth_validation_email_required";
export const LN_AUTH_VALIDATION_INVALID_EMAIL = "auth_validation_invalid_email";
export const LN_AUTH_VALIDATION_PASSWORD_NUM_CHARACTERS = "auth_validation_password_num_characters";
export const LN_AUTH_VALIDATION_PASSWORD_REQUIRED = "auth_validation_password_required";
export const LN_AUTH_VALIDATION_REPEAT_PASSWORD_REQUIRED = "auth_validation_repeat_password_required";
export const LN_AUTH_VALIDATION_PASSWORD_MUST_MATCH = "auth_validation_password_must_match";
export const LN_AUTH_EMAIL = "auth_email";
export const LN_AUTH_PASSWORD = "auth_password";
export const LN_AUTH_FORGOT_PASSWORD_BTN = "auth_forgot_password_btn";
export const LN_AUTH_LOGIN_BTN = "auth_login_btn";
export const LN_AUTH_FORGOT_PASSWORD_MESSAGE = "auth_forgot_password_message";
export const LN_AUTH_FORGOT_PASSWORD_SIGN_IN_BTN = "auth_forgot_password_sign_in_btn";
export const LN_AUTH_CONTINUE_BTN = "auth_continue_btn";
export const LN_AUTH_NEW_PASSWORD = "auth_new_password";
export const LN_AUTH_LOGIN_TAB = "auth_login_tab";
export const LN_AUTH_REGISTRATION_TAB = "auth_registration_tab";
export const LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_CHECKBOX = "auth_registration_tab_terms_conditions_checkbox";
export const LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_CHECKBOX = "auth_registration_tab_refund_policy_checkbox";
export const LN_AUTH_REGISTRATION_TAB_TERMS_CONDITIONS_LABEL = "auth_registration_tab_terms_conditions_label";
export const LN_AUTH_REGISTRATION_TAB_REFUND_POLICY_LABEL = "auth_registration_tab_refund_policy_label";
export const LN_VALIDATION_INPUT_VALUE_IS_REQUIRED = "validation_input_value_is_required";
export const LN_VALIDATION_INPUT_VALUE_NOT_POSITIVE = "validation_input_value_not_positive";
export const LN_VALIDATION_INPUT_VALUE_NOT_ZERO = "validation_input_value_not_zero";
export const LN_VALIDATION_INPUT_VALUE_PHONE = "validation_input_value_phone";
export const LN_VALIDATION_INPUT_VALUE_INVALID_CRYPTO_WALLET_ADDRESS = "validation_input_value_invalid_crypto_wallet_address";
export const LN_VALIDATION_INPUT_VALUE_INVALID_CARD_NUMBER = "validation_input_value_invalid_card_number";
export const LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME = "validation_input_value_invalid_holder_name";
export const LN_VALIDATION_INPUT_VALUE_INVALID_HOLDER_NAME_MIN_SYMBOL = "validation_input_value_invalid_holder_name_min_symbol";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_DIGITS = "validation_input_value_field_must_contain_only_digits";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MIN_8_DIGITS = "validation_input_value_field_must_contain_only_min_8_digits";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_20_DIGITS = "validation_input_value_field_must_contain_only_max_20_digits";
export const LN_VALIDATION_INPUT_VALUE_INVALID_IBAN = "validation_input_value_invalid_iban";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_AT_LEAST_MIN_15_DIGITS = "validation_input_value_field_must_contain_at_least_min_15_digits";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_34_DIGITS = "validation_input_value_field_must_contain_only_max_34_digits";
export const LN_VALIDATION_INPUT_VALUE_INVALID_SWIFT_CODE = "validation_input_value_invalid_swift_code";
export const LN_VALIDATION_INPUT_VALUE_FIELD_MUST_CONTAIN_ONLY_MAX_11_DIGITS = "validation_input_value_field_must_contain_only_max_11_digits";
export const LN_VALIDATION_INPUT_VALUE_OR_IBAN_OR_SWIFT = "validation_input_value_or_iban_or_swift";
export const LN_KYC_DELETE_FIELD = "kyc_delete_field";
export const LN_KYC_SEND_FIELD = "kyc_send_field";
export const LN_KYC_GET_FIELD_LIST = "kyc_get_field_list";
export const LN_KYC_STATUS = "kyc_status";
export const LN_KYC_STATUS_NEW = "kyc_status_new";
export const LN_KYC_STATUS_IN_REVIEW = "kyc_status_in_review";
export const LN_KYC_STATUS_FAILED = "kyc_status_failed";
export const LN_KYC_STATUS_SUCCESSFUL = "kyc_status_successful";
export const LN_KYC_STATUS_NOT_UPLOADED = "kyc_status_not_uploaded";
export const LN_KYC_FILE_UPLOAD_BTN = "kyc_file_upload_btn";
export const LN_KYC_FILE_DND_TITLE = "kyc_file_dnd_title";
export const LN_KYC_FILE_DND_SELECT_TITLE = "kyc_file_dnd_select_title";
export const LN_KYC_FILE_DND_SUPPORTED_FORMATS = "kyc_file_dnd_supported_formats";
export const LN_LIGHT_THEME_SWITCHER_TITLE = "light_theme_switcher_title";
export const LN_DARK_THEME_SWITCHER_TITLE = "dark_theme_switcher_title";
export const LN_THEME_SWITCHER_TITLE = "theme_switcher_title";
export const LN_BTN_CLICK_TO_COPY = "btn_click_to_copy";
