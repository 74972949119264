// Modules
import Cookies from 'js-cookie'

// Constants
import { AuthTabValueDefault } from 'pages/Auth/Login/AuthTabs'

// Types
import {
  ACCESS_DENIED,
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_RESULT,
  LOGOUT,
  NOT_AUTH,
  ON_LOGIN,
  ON_LOGIN_RESULT,
  ON_REGISTRATION,
  ON_REGISTRATION_RESULT,
  ON_SET_AUTH_TABS,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_RESULT,
  SET_TOKEN,
} from 'store/actions/Auth/types'
import { cookieAuthToken } from 'store/api'

const initialState = {
  token: Cookies.get(cookieAuthToken),
  isAuthenticated: !!Cookies.get(cookieAuthToken),
  isAccessDenied: false,
  loading: false,
  error: '',
  forgotPassword: { loading: false, error: '' },
  resetPassword: { loading: false, error: '' },
  registration: { loading: false, error: '' },

  authTabs: AuthTabValueDefault,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
        isAccessDenied: false,
      }
    case ON_LOGIN_RESULT:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ON_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      }
    case NOT_AUTH:
      return {
        ...state,
        isAuthenticated: false,
      }
    case ACCESS_DENIED:
      return {
        ...state,
        isAuthenticated: false,
        isAccessDenied: true,
      }
    case FORGOT_PASSWORD_ACTION:
      return {
        ...state,
        forgotPassword: { loading: true },
      }
    case FORGOT_PASSWORD_RESULT:
      return {
        ...state,
        forgotPassword: { loading: false, ...action.payload },
      }
    case RESET_PASSWORD_ACTION:
      return {
        ...state,
        resetPassword: { loading: true },
      }
    case RESET_PASSWORD_RESULT:
      return {
        ...state,
        resetPassword: { loading: false, ...action.payload },
      }
    case ON_SET_AUTH_TABS:
      return {
        ...state,
        authTabs: action.payload,
      }
    case ON_REGISTRATION:
      return {
        ...state,
        registration: { loading: true },
      }
    case ON_REGISTRATION_RESULT:
      return {
        ...state,
        registration: { loading: false, ...action.payload },
      }
    default:
      return state
  }
}
export default userReducer
