// Modules
import { memo, useCallback, useMemo } from 'react'
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { TextXs } from 'components'
import ListPanel from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import {
  deleteInnerListExpandableAccordionInd,
  setInnerListExpandableAccordionInds,
} from 'store/actions/marketSidebar/actions'
import { innerListExpandableAccordionIndsSelector } from 'store/actions/marketSidebar/selectors'

// UI
import { Box, Button, Collapse, Icon } from '@chakra-ui/react'

const InnerExpandableListItem = (props) => {
  const { groupList, groupId } = props
  const dispatch = useDispatch()

  const expandableAccordionInds = useSelector(innerListExpandableAccordionIndsSelector)

  const toggleItem = useCallback(() => {
    if (expandableAccordionInds.includes(groupId)) {
      dispatch(deleteInnerListExpandableAccordionInd(groupId))
    } else {
      dispatch(setInnerListExpandableAccordionInds([groupId]))
    }
  }, [groupId, expandableAccordionInds, dispatch])

  const isOpen = useMemo(() => {
    return expandableAccordionInds?.includes(groupId)
  }, [expandableAccordionInds, groupId])

  const { mainTextColor, isLightTheme } = useCurrentColorTheme()

  return (
    <Box overflow="hidden">
      <Button
        width="100%"
        variant="link"
        size="sm"
        onClick={toggleItem}
        textDecoration="none"
        pl={2}
        pr={2}
        borderRadius="md"
        borderBottomWidth={1}
        pb={1}
        pt={1}
        bg={isLightTheme ? 'white' : '#262a35'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <TextXs color={mainTextColor}>{groupId}</TextXs>

          <Box display="flex" alignItems="center" gap={1}>
            <Box
              as="span"
              fontSize="11px"
              p="1"
              borderRadius="xl"
              color={mainTextColor}
              minW="19px"
              height="19px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderColor="navy.300"
              borderWidth={1}
            >
              {groupList?.length}
            </Box>
            <Icon as={isOpen ? IoIosArrowDown : IoIosArrowForward} w="11px" h="11px" />
          </Box>
        </Box>
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box border={0} mt={1}>
          <ListPanel list={groupList} />
        </Box>
      </Collapse>
    </Box>
  )
}

export default memo(InnerExpandableListItem)
