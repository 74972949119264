export const formConstants = {
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  email: 'email',
  password: 'password',
  repeatPassword: 'repeatPassword',
  agreeToTerms: 'agreeToTerms',
  agreeToRefundPolicy: 'agreeToRefundPolicy',
}
