// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_HISTORY_NO_DATA } from 'locales/constants'

// Components
import { TextSm } from 'components/Typography/TextBase'

const NoDataMessage = (props) => {
  const { title = LN_HISTORY_NO_DATA } = props

  const { t } = useTranslation()

  return (
    <TextSm fontSize="16px" fontWeight="500" mt={2}>
      {t(title)}
    </TextSm>
  )
}

export default memo(NoDataMessage)
