// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { TextMd } from 'components/Typography/TextBase'
import { NoDataMessage } from 'components/index'
import { MainTitle } from 'pages/User/components/Content/Withdrawal'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex, Spinner } from '@chakra-ui/react'

const HistoryPageWrapper = (props) => {
  const { children, loading, title, list, noDataTitle } = props

  const { t } = useTranslation()
  const { highLightColor } = useCurrentColorTheme()

  return (
    <Box>
      <MainTitle title={t(title)} pt={6} />
      {loading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spinner color={highLightColor} />
        </Flex>
      ) : !list || list?.length === 0 ? (
        <NoDataMessage title={noDataTitle} />
      ) : (
        children
      )}
    </Box>
  )
}

export default memo(HistoryPageWrapper)
