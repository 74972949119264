// Modules
import { useSelector } from 'react-redux'

// Hooks
import useHistoryTablePagination from 'components/Table/TableHistory/useHistoryTablePagination'

// Store
import { getWithdrawalHistory } from 'store/actions/Withdrawal/actions'
import { withdrawalHistoryListSelector } from 'store/actions/Withdrawal/selectors'

function useWithdrawalHistoryTablePagination() {
  const withdrawalHistory = useSelector(withdrawalHistoryListSelector)
  const listPagination = withdrawalHistory?.pagination

  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useHistoryTablePagination(
    listPagination,
    getWithdrawalHistory,
  )

  return {
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage,
  }
}

export default useWithdrawalHistoryTablePagination
