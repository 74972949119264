export const LOGOUT = 'LOGOUT'
export const SET_TOKEN = 'SET_TOKEN'
export const ON_LOGIN = 'ON_LOGIN'
export const ON_LOGIN_RESULT = 'ON_LOGIN_RESULT'
export const FORGOT_PASSWORD_ACTION = 'FORGOT_PASSWORD_ACTION'
export const FORGOT_PASSWORD_RESULT = 'FORGOT_PASSWORD_RESULT'
export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION'
export const RESET_PASSWORD_RESULT = 'RESET_PASSWORD_RESULT'
export const NOT_AUTH = 'NOT_AUTH'
export const ACCESS_DENIED = 'ACCESS_DENIED'

export const ON_REGISTRATION = 'ON_REGISTRATION'
export const ON_REGISTRATION_RESULT = 'ON_REGISTRATION_RESULT'

export const ON_SET_AUTH_TABS = 'ON_SET_AUTH_TABS'
