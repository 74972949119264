// Modules
import { memo, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Languages
import { LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET } from 'locales/constants'

// Components
import PendingPopoverMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/PendingPopoverMobileUI'
import PendingPopoverUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/PendingPopoverUI'

import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// Store
import { isMobileSelector } from 'store/actions/User/selectors'

const PendingPopoverContainer = () => {
  const { t } = useTranslation()

  const isMobile = useSelector(isMobileSelector)

  const {
    assetPriceValue,
    setAssetPriceValue,
    currentCurrencySymbolPointValue,
    unchangeableCurrencyPriceValue,
    assetPriceTouched,
    setAssetPriceTouched,
    calcTakeProfitStopLossStep,
  } = useContext(MarketCalcContext)

  const onChangeAssetPriceInput = useCallback(
    (e) => {
      setAssetPriceValue(e?.target?.value)
      setAssetPriceTouched(true)
    },
    [setAssetPriceValue, setAssetPriceTouched],
  )

  const onIncrease = useCallback(() => {
    setAssetPriceValue((prevValue) => prevValue + calcTakeProfitStopLossStep)
    // setAssetPriceValue((prevValue) => prevValue + currentCurrencySymbolPointValue)
    setAssetPriceTouched(true)
  }, [setAssetPriceValue, calcTakeProfitStopLossStep, setAssetPriceTouched])

  const onDecrease = useCallback(() => {
    setAssetPriceValue((prevValue) => {
      const newV = prevValue - calcTakeProfitStopLossStep
      // const newV = prevValue - currentCurrencySymbolPointValue
      return newV > 0 ? newV : 0
    })
    setAssetPriceTouched(true)
  }, [setAssetPriceValue, calcTakeProfitStopLossStep, setAssetPriceTouched])

  const onDefaultValue = useCallback(() => {
    setAssetPriceValue(unchangeableCurrencyPriceValue)
    setAssetPriceTouched(true)
  }, [setAssetPriceValue, unchangeableCurrencyPriceValue, setAssetPriceTouched])

  const onResetValue = useCallback(() => {
    setAssetPriceValue(unchangeableCurrencyPriceValue)
    setAssetPriceTouched(false)
  }, [setAssetPriceValue, setAssetPriceTouched, unchangeableCurrencyPriceValue])

  const popoverLabelVal = useMemo(() => {
    if (assetPriceTouched && assetPriceValue) {
      return roundDigitsAfterDots(Number(assetPriceValue), currentCurrencySymbolPointValue)
    } else {
      return t(LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET)
    }
  }, [assetPriceTouched, assetPriceValue, t, currentCurrencySymbolPointValue])

  return isMobile ? (
    <PendingPopoverMobileUI
      popoverLabelVal={popoverLabelVal}
      onChangeAssetPriceInput={onChangeAssetPriceInput}
      assetPriceValue={assetPriceValue}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      onResetValue={onResetValue}
      onDefaultValue={onDefaultValue}
      unchangeableCurrencyPriceValue={unchangeableCurrencyPriceValue}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
    />
  ) : (
    <PendingPopoverUI
      popoverLabelVal={popoverLabelVal}
      onChangeAssetPriceInput={onChangeAssetPriceInput}
      assetPriceValue={assetPriceValue}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      onResetValue={onResetValue}
      onDefaultValue={onDefaultValue}
      unchangeableCurrencyPriceValue={unchangeableCurrencyPriceValue}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
    />
  )
}

export default memo(PendingPopoverContainer)
