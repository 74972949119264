// Store
import { axiosInstance } from './index'

const basePath = '/kyc'

export const getKYCFieldsListAPI = async () => {
  const response = await axiosInstance.get(`${basePath}/fields`)
  // console.log('onGetKYCFieldsListAPI response.data', response.data)
  return response.data
}

export const sendKYCFieldAPI = async ({ name, file }) => {
  const formData = new FormData()

  formData.append('name', name)
  formData.append('file', file)

  const response = await axiosInstance.post(basePath, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  // console.log('sendKYCFieldAPI response.data', response.data)
  return response.data
}

export const deleteKYCFieldAPI = async ({ name }) => {
  const response = await axiosInstance.delete(basePath, {
    params: { name },
  })
  // console.log('sendKYCFieldAPI response.data', response.data)
  return response.data
}
