// Modules
import { memo } from 'react'

function LightModeIcon(props) {
  const { fillBg = '#ffbc45', fillBgHighlight = '#f9ae35' } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      x="0"
      y="0"
      viewBox="0 0 128 128"
    >
      <circle cx="64" cy="63.97" r="33.51" fill={fillBg} opacity="1" data-original={fillBg} />
      <path
        fill={fillBgHighlight}
        d="M78.97 34c2.26 4.51 3.53 9.59 3.53 14.97 0 18.5-15 33.51-33.51 33.51-5.38 0-10.47-1.28-14.97-3.53C39.53 89.93 50.88 97.48 64 97.48c18.5 0 33.51-15 33.51-33.51 0-13.12-7.55-24.47-18.54-29.97z"
        opacity="1"
      />
      <g fill={fillBg}>
        <path
          d="M64 2c-1.1 0-2 .9-2 2v11.22c0 1.1.9 2 2 2s2-.9 2-2V4c0-1.1-.9-2-2-2zM64 110.73c-1.1 0-2 .9-2 2v11.22c0 1.1.9 2 2 2s2-.9 2-2v-11.22a2 2 0 0 0-2-2zM104.99 20.15l-7.93 7.93a2.004 2.004 0 0 0 1.41 3.42c.51 0 1.02-.2 1.41-.59l7.93-7.93c.78-.78.78-2.05 0-2.83s-2.04-.78-2.82 0zM28.11 97.03l-7.93 7.93a2.004 2.004 0 0 0 1.41 3.42c.51 0 1.02-.2 1.41-.59l7.93-7.93c.78-.78.78-2.05 0-2.83s-2.04-.78-2.82 0zM123.97 61.97h-11.22c-1.1 0-2 .9-2 2s.9 2 2 2h11.22a2 2 0 1 0 0-4zM15.25 61.97H4.03c-1.1 0-2 .9-2 2s.9 2 2 2h11.22c1.1 0 2-.9 2-2s-.9-2-2-2zM99.89 97.03c-.78-.78-2.05-.78-2.83 0s-.78 2.05 0 2.83l7.93 7.93c.39.39.9.59 1.41.59s1.02-.2 1.41-.59c.78-.78.78-2.05 0-2.83zM23.01 20.15c-.78-.78-2.05-.78-2.83 0s-.78 2.05 0 2.83l7.93 7.93c.39.39.9.59 1.41.59s1.02-.2 1.41-.59c.78-.78.78-2.05 0-2.83z"
          fill={fillBg}
          opacity="1"
        />
      </g>
    </svg>
  )
}

export default memo(LightModeIcon)
