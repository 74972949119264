// Modules
import _ from 'lodash'

// Constants
import { defaultProps } from 'components/TVChartContainer/constants'

import { graphResolutionValueKey } from 'constants/index'

// Types
import {
  GET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MAP_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST_LOADING,
  SET_INTERVAL_GRAPH_VALUE,
  WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE,
} from 'store/actions/CurrencySocket/types'

const initialState = {
  currencyList: null,
  currencyListLoading: true,

  currencyMap: null,

  intervalGraphValue: localStorage.getItem(graphResolutionValueKey) || defaultProps.interval,
}

const currencySocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERVAL_GRAPH_VALUE:
      return {
        ...state,
        intervalGraphValue: action.payload,
      }
    case SET_CURRENCY_MAP_MARKET_LIST:
      return {
        ...state,
        currencyMap: action.payload,
      }
    case SET_CURRENCY_MARKET_LIST:
      return {
        ...state,
        currencyList: action.payload,
      }
    case GET_CURRENCY_MARKET_LIST:
      return {
        ...state,
        currencyListLoading: true,
      }
    case SET_CURRENCY_MARKET_LIST_LOADING:
      return {
        ...state,
        currencyListLoading: action.payload,
      }
    case WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE:
      let transform = _.cloneDeep(state.currencyList)
      const elementSocket = action?.payload

      if (state.currencyList) {
        if (state.currencyMap) {
          // if element from socket is not array
          if (!_.isArray(elementSocket)) {
            algorithmOfChangingCurrency(elementSocket, state.currencyMap, transform)
          } else {
            elementSocket?.map((elS) => {
              algorithmOfChangingCurrency(elS, state.currencyMap, transform)
            })
          }
        }

        // old algorithm
        // if (_.isArray(action?.payload)) {
        //   // Create a dictionary for faster lookup by label
        //   const pairMap = Object.values(transform)
        //     .flatMap((pairArr) => pairArr)
        //     .reduce((map, pair) => {
        //       map[pair?.label] = pair
        //       return map
        //     }, {})
        //
        //   // Update prices by looking up the dictionary
        //   action.payload?.forEach((el) => {
        //     if (pairMap[el?.symbol]) {
        //       pairMap[el.symbol].price = el?.close
        //     }
        //   })
        // } else {
        //   const allPairs = Object.values(transform).flatMap((pairArr) => pairArr)
        //   const pair = allPairs.find((p) => p?.name === action?.payload?.symbol)
        //   if (pair) {
        //     pair.price = action?.payload?.close
        //   }
        // }
      }
      return {
        ...state,
        currencyList: transform,
      }
    default:
      return state
  }
}
export default currencySocketReducer

export const algorithmOfChangingCurrency = (elS, currencyMap, list) => {
  const indexOfElement = currencyMap?.[elS?.group]?.[elS?.symbol]
  // found element to change in currencyList using group from socket and found index above
  const elToChange = list?.[elS?.group]?.[indexOfElement]
  if (elToChange?.price) {
    // change price in currencyList
    elToChange.price = elS?.close
  }
}
