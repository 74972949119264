// Modules
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

export const paginationDefault = { total: 0, page: 1 }
export const paginationPerPage = 20

function useHistoryTablePagination(listPagination, request) {
  const dispatch = useDispatch()

  const { userTradingAccountId } = useUserTradingAccountId()

  const paginationInfo = useMemo(() => {
    if (listPagination) {
      return listPagination
    } else {
      return paginationDefault
    }
  }, [listPagination])

  const sentRequest = useCallback(
    (pageValue) => {
      if (!userTradingAccountId) return null

      dispatch(
        request({
          userTradingAccountId,
          page: pageValue,
        }),
      )
    },
    [dispatch, userTradingAccountId, request],
  )

  const onNextPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) + 1)
  }, [sentRequest, paginationInfo])

  const onPreviousPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) - 1)
  }, [sentRequest, paginationInfo])

  return {
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage: sentRequest,
  }
}

export default useHistoryTablePagination
