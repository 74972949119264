// Languages
import {
  LN_DEPOSIT_TAB_HISTORY_HEADER_AMOUNT,
  LN_DEPOSIT_TAB_HISTORY_HEADER_CURRENCY,
  LN_DEPOSIT_TAB_HISTORY_HEADER_DATE,
  LN_DEPOSIT_TAB_HISTORY_HEADER_DEPOSIT_TYPE,
  LN_DEPOSIT_TAB_HISTORY_HEADER_FINANCE_TYPE,
  LN_DEPOSIT_TAB_HISTORY_HEADER_STATUS,
} from 'locales/constants'

export const depositTypeConstants = {
  1: 'up sale',
  2: 'FTD',
  3: 'pre FTD',
}

export const headerConstants = {
  date: 'date',
  typeFinance: 'typeFinance',
  amount: 'amount',
  currency: 'currency',
  depositType: 'depositType',
  status: 'status',
  infoBtn: 'infoBtn',
}

export const columns = [
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_DATE,
    name: headerConstants?.date,
  },
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_FINANCE_TYPE,
    name: headerConstants?.typeFinance,
  },
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_AMOUNT,
    name: headerConstants?.amount,
  },
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_CURRENCY,
    name: headerConstants?.currency,
  },
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_DEPOSIT_TYPE,
    name: headerConstants?.depositType,
  },
  {
    title: LN_DEPOSIT_TAB_HISTORY_HEADER_STATUS,
    name: headerConstants?.status,
  },
  {
    title: '',
    name: headerConstants?.infoBtn,
  },
]
