// Modules
import { lazy, memo } from 'react'

// Components
import PendingPopover from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/PendingPopover'
import TakeProfitPopover from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/TakeProfitPopover'

// UI
import { Stack } from '@chakra-ui/react'

const TakeProfitBlock = () => {
  return (
    <Stack gap={2} mt={2}>
      <TakeProfitPopover />
      <PendingPopover />
    </Stack>
  )
}

export default memo(TakeProfitBlock)
