// Modules
import { memo } from 'react'

function DarkModeIcon(props) {
  const { fillBg = '#9bc9ff', fillBorder = '#57a4ff' } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      x="0"
      y="0"
      viewBox="0 0 512 512"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill={fillBg}
          d="M56.95 253.521c0-92.534 58.127-176.372 144.527-209.103l21.101-7.993-11.353 19.5c-20.599 35.381-31.033 74.989-31.033 115.938 0 61.84 24.069 119.952 67.795 163.681 43.725 43.727 101.843 67.799 163.68 67.799 5.191 0 10.382-.178 15.562-.524l22.291-1.49-16.279 15.301c-41.523 39.029-95.803 60.369-152.813 60.369-123.231 0-223.478-100.246-223.478-223.478z"
          opacity="1"
          data-original={fillBg}
          className=""
        />
        <path
          fill={fillBorder}
          d="M280.429 468.999c-118.819 0-215.478-96.664-215.478-215.478 0-90.191 56.987-170.416 139.361-201.622-21.103 36.247-32.119 77.139-32.119 119.964 0 63.965 24.906 124.105 70.138 169.337 45.227 45.232 105.371 70.142 169.337 70.142 5.362 0 10.733-.184 16.095-.542-39.692 37.308-91.873 58.199-147.334 58.199zm188.824-80.946a12.012 12.012 0 0 0-12.693-5.409 216.754 216.754 0 0 1-44.893 4.698c-118.814 0-215.479-96.665-215.479-215.479 0-48.954 16.001-95.091 46.273-133.425a11.998 11.998 0 0 0 .777-13.767 11.994 11.994 0 0 0-12.679-5.409c-52.879 11.2-100.971 40.52-135.432 82.548-34.937 42.622-54.179 96.499-54.179 151.711 0 63.965 24.911 124.105 70.143 169.337C156.318 468.09 216.458 493 280.428 493c73.742 0 142.283-33.231 188.042-91.18a12.023 12.023 0 0 0 .783-13.767z"
          opacity="1"
          data-original={fillBorder}
        />
      </g>
    </svg>
  )
}

export default memo(DarkModeIcon)
