// Modules
import { memo, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Languages
import { LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET } from 'locales/constants'

// Components
import TakeProfitPopoverMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/TakeProfitPopoverMobileUI'
import TakeProfitPopoverUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/TakeProfitPopoverUI'
import { takeProfitRadio } from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock/constants'
import {
  RADIO_BY_RATE,
  getRadioTitle,
} from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Context
import { MarketCalcContext, takeProfitStopLossIncreaseStep } from 'context/MarketCalcContext'

// Store
import { isMobileSelector, userBalanceSelector } from 'store/actions/User/selectors'

const TakeProfitPopoverContainer = () => {
  const { t } = useTranslation()
  const isMobile = useSelector(isMobileSelector)

  const {
    takeProfitRadioValue,
    setTakeProfitRadioValue,
    takeProfitValue,
    setTakeProfitValue,
    stopLossRadioValue,
    setStopLossRadioValue,
    stopLossValue,
    setStopLossValue,
    currentCurrencySymbolPointValue,
    unchangeableCurrencyPriceValue,
    stopLossTouched,
    setStopLossTouched,
    takeProfitTouched,
    setTakeProfitTouched,
    calcTakeProfitStopLossStep,
  } = useContext(MarketCalcContext)

  const onChangeTakeProfitInput = useCallback(
    (e) => {
      setTakeProfitValue(e?.target?.value)
      setTakeProfitTouched(true)
    },
    [setTakeProfitValue, setTakeProfitTouched],
  )

  const onChangeTakeProfitRadio = useCallback(
    (val) => setTakeProfitRadioValue(Number(val)),
    [setTakeProfitRadioValue],
  )

  const onIncreaseTakeProfit = useCallback(() => {
    setTakeProfitValue((prevValue) => prevValue + calcTakeProfitStopLossStep)
    // setTakeProfitValue((prevValue) => prevValue + currentCurrencySymbolPointValue)
    setTakeProfitTouched(true)
  }, [setTakeProfitValue, calcTakeProfitStopLossStep, setTakeProfitTouched])

  const onDecreaseTakeProfit = useCallback(() => {
    setTakeProfitValue((prevValue) => {
      const newV = prevValue - calcTakeProfitStopLossStep
      // const newV = prevValue - currentCurrencySymbolPointValue
      return newV > 0 ? newV : 0
    })
    setTakeProfitTouched(true)
  }, [setTakeProfitValue, calcTakeProfitStopLossStep, setTakeProfitTouched])

  const onChangeStopLossRadio = useCallback(
    (val) => setStopLossRadioValue(Number(val)),
    [setStopLossRadioValue],
  )

  const onChangeStopLossInput = useCallback(
    (e) => {
      setStopLossValue(e?.target?.value)
      setStopLossTouched(true)
    },
    [setStopLossValue, setStopLossTouched],
  )

  const onResetValueStopLoss = useCallback(() => {
    setStopLossValue(unchangeableCurrencyPriceValue)
    setStopLossRadioValue(RADIO_BY_RATE)
    setStopLossTouched(false)
  }, [setStopLossValue, setStopLossTouched, setStopLossRadioValue, unchangeableCurrencyPriceValue])

  const onResetValueTakeProfit = useCallback(() => {
    setTakeProfitValue(unchangeableCurrencyPriceValue)
    setTakeProfitRadioValue(RADIO_BY_RATE)
    setTakeProfitTouched(false)
  }, [
    setTakeProfitValue,
    setTakeProfitTouched,
    setTakeProfitRadioValue,
    unchangeableCurrencyPriceValue,
  ])

  const onIncreaseStopLoss = useCallback(() => {
    setStopLossValue((prevValue) => prevValue + calcTakeProfitStopLossStep)
    // setStopLossValue((prevValue) => prevValue + currentCurrencySymbolPointValue)
    setStopLossTouched(true)
  }, [setStopLossValue, calcTakeProfitStopLossStep, setStopLossTouched])

  const onDecreaseStopLoss = useCallback(() => {
    setStopLossValue((prevValue) => {
      const newV = prevValue - calcTakeProfitStopLossStep
      // const newV = prevValue - currentCurrencySymbolPointValue
      return newV > 0 ? newV : 0
    })
    setStopLossTouched(true)
  }, [setStopLossValue, calcTakeProfitStopLossStep, setStopLossTouched])

  const popoverLabelVal = useMemo(() => {
    let takeProfitStr = ''
    let stopLossStr = ''
    // roundDigitsAfterDots(takeProfitValue, currentCurrencySymbolPointValue)

    if (takeProfitTouched && takeProfitValue !== 0) {
      // takeProfitStr = roundDigitsAfterDots(Number(takeProfitValue), currentCurrencySymbolPointValue)
      takeProfitStr = `${roundDigitsAfterDots(
        Number(takeProfitValue),
        currentCurrencySymbolPointValue,
      )} (${t(getRadioTitle(takeProfitRadioValue))})`
    } else {
      takeProfitStr = t(LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET)
    }
    if (stopLossTouched && stopLossValue !== 0) {
      stopLossStr = `${roundDigitsAfterDots(
        Number(stopLossValue),
        currentCurrencySymbolPointValue,
      )} (${t(getRadioTitle(stopLossRadioValue))})`
    } else {
      stopLossStr = t(LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET)
    }

    if (!takeProfitTouched && !stopLossTouched) {
      return t(LN_TRANSACTION_CALC_STOP_LOSS_NOT_SET)
    } else {
      return `${takeProfitStr} & ${stopLossStr}`
    }
  }, [
    takeProfitValue,
    stopLossValue,
    stopLossTouched,
    takeProfitTouched,
    takeProfitRadioValue,
    stopLossRadioValue,
    currentCurrencySymbolPointValue,
    t,
  ])

  return isMobile ? (
    <TakeProfitPopoverMobileUI
      popoverLabelVal={popoverLabelVal}
      takeProfitRadio={takeProfitRadio}
      takeProfitRadioValue={takeProfitRadioValue}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
      onChangeTakeProfitRadio={onChangeTakeProfitRadio}
      takeProfitValue={takeProfitValue}
      onChangeTakeProfitInput={onChangeTakeProfitInput}
      onIncreaseTakeProfit={onIncreaseTakeProfit}
      onDecreaseTakeProfit={onDecreaseTakeProfit}
      onResetValueTakeProfit={onResetValueTakeProfit}
      stopLossValue={stopLossValue}
      onChangeStopLossInput={onChangeStopLossInput}
      onIncreaseStopLoss={onIncreaseStopLoss}
      onDecreaseStopLoss={onDecreaseStopLoss}
      onResetValueStopLoss={onResetValueStopLoss}
      stopLossRadioValue={stopLossRadioValue}
      onChangeStopLossRadio={onChangeStopLossRadio}
    />
  ) : (
    <TakeProfitPopoverUI
      popoverLabelVal={popoverLabelVal}
      takeProfitRadio={takeProfitRadio}
      takeProfitRadioValue={takeProfitRadioValue}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
      onChangeTakeProfitRadio={onChangeTakeProfitRadio}
      takeProfitValue={takeProfitValue}
      onChangeTakeProfitInput={onChangeTakeProfitInput}
      onIncreaseTakeProfit={onIncreaseTakeProfit}
      onDecreaseTakeProfit={onDecreaseTakeProfit}
      onResetValueTakeProfit={onResetValueTakeProfit}
      stopLossValue={stopLossValue}
      onChangeStopLossInput={onChangeStopLossInput}
      onIncreaseStopLoss={onIncreaseStopLoss}
      onDecreaseStopLoss={onDecreaseStopLoss}
      onResetValueStopLoss={onResetValueStopLoss}
      stopLossRadioValue={stopLossRadioValue}
      onChangeStopLossRadio={onChangeStopLossRadio}
    />
  )
}

export default memo(TakeProfitPopoverContainer)
