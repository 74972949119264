// Modules
import _ from 'lodash'
import { memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

// Languages
import { LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS } from 'locales/constants'

// Components
import SelectFormikField from 'components/Form/SelectFormikField'
import { withdrawalMethodsGroupServer } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'
import { formConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'

// Store
import { withdrawalMethodsSelector } from 'store/actions/Withdrawal/selectors'

const MethodSelect = (props) => {
  const { isInputDisabled, formik, setActiveMethodFields } = props

  const withdrawalFields = useSelector(withdrawalMethodsSelector)

  const activeWithdrawalFields = useMemo(() => {
    const serverFields = withdrawalFields?.filter((f) => f?.active)
    const list = serverFields?.map((serverEl) => {
      return withdrawalMethodsGroupServer?.[serverEl?.name?.toLowerCase()]
    })

    return _.flatten(list)
  }, [withdrawalFields])

  useEffect(() => {
    if (activeWithdrawalFields || activeWithdrawalFields?.length > 0) {
      formik.setFieldValue(formConstants.withdrawalMethod, activeWithdrawalFields?.[0]?.id)
      setActiveMethodFields(activeWithdrawalFields)
    }
  }, [activeWithdrawalFields])

  if (!activeWithdrawalFields || activeWithdrawalFields?.length === 0) {
    return null
  }

  return (
    <SelectFormikField
      formik={formik}
      fieldName={formConstants.withdrawalMethod}
      label={LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS}
      disabled={isInputDisabled}
      optionsList={activeWithdrawalFields}
    />
  )
}

export default memo(MethodSelect)
