// Modules
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCopyOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME,
  LN_USER_WITHDRAWAL_ACCOUNT_NUMBER,
  LN_USER_WITHDRAWAL_BANK_SWIFT_CODE,
  LN_USER_WITHDRAWAL_CARD_HOLDER_NAME,
  LN_USER_WITHDRAWAL_CARD_NUMBER,
  LN_USER_WITHDRAWAL_INFO_MODAL_TITLE,
  LN_USER_WITHDRAWAL_TABLE_AMOUNT,
  LN_USER_WITHDRAWAL_TABLE_DATE,
  LN_USER_WITHDRAWAL_TABLE_STATUS,
  LN_USER_WITHDRAWAL_WALLET_ADDRESS,
} from 'locales/constants'

// Components
import { CopyBtnLikeInput, DisabledInputInfo, TextMd } from 'components'
import StatusItem from 'components/Table/TableHistory/StatusItem'
import { statusServerConstants } from 'components/Table/TableHistory/constants'
import {
  withdrawalDetailsObjList,
  withdrawalMethodsConstants,
} from 'pages/User/components/Content/Withdrawal/WithdrawalForm/MethodSelect/constants'
import { formConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'
import { typeWithdrawalIcon } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// Utils
import { formatDateToPrettierFullDateTime } from 'utils/dateTransform'
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'

// Store
import {
  cancelWithdrawal,
  setIsWithdrawalModalInfoOpen,
  setWithdrawalModalInfoItem,
} from 'store/actions/Withdrawal/actions'
import {
  isWithdrawalModalInfoOpenSelector,
  modalWithdrawalItemInfoSelector,
} from 'store/actions/Withdrawal/selectors'

// UI
import {
  Box,
  Flex,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'

const InfoModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const info = useSelector(modalWithdrawalItemInfoSelector)
  const isOpen = useSelector(isWithdrawalModalInfoOpenSelector)

  const onClose = useCallback(() => {
    dispatch(setIsWithdrawalModalInfoOpen(false))
    dispatch(setWithdrawalModalInfoItem(null))
  }, [dispatch])

  const onCancelWithdrawal = useCallback(() => {
    dispatch(cancelWithdrawal({ financeId: info?.id }))
    dispatch(setWithdrawalModalInfoItem({ ...info, status: statusServerConstants?.failed }))
  }, [info, dispatch])

  const switcherInfoDependsOnType = useMemo(() => {
    switch (info?.typeWithdrawal?.toLowerCase()) {
      case withdrawalMethodsConstants.bank:
        return (
          <>
            {info?.description?.[formConstants.accountHolderName] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.accountHolderName]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
            {info?.description?.[formConstants.accountNumber] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_NUMBER)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.accountNumber]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
            {info?.description?.[formConstants.iban] && (
              <Box>
                <FormLabel>IBAN</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.iban]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
            {info?.description?.[formConstants.swiftCode] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_BANK_SWIFT_CODE)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.swiftCode]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
          </>
        )
      case withdrawalMethodsConstants.card:
        return (
          <>
            {info?.description?.[formConstants.cardHolderName] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_HOLDER_NAME)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.cardHolderName]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
            {info?.description?.[formConstants.cardNumber] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_NUMBER)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.cardNumber]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
          </>
        )
      case withdrawalMethodsConstants.bitcoin:
      case withdrawalMethodsConstants.usdt:
      case withdrawalMethodsConstants.ethereum:
        return (
          <>
            {info?.description?.[formConstants.walletAddress] && (
              <Box>
                <FormLabel>{t(LN_USER_WITHDRAWAL_WALLET_ADDRESS)}</FormLabel>
                <CopyBtnLikeInput
                  value={info?.description?.[formConstants.walletAddress]}
                  leftIcon={<IoCopyOutline />}
                />
              </Box>
            )}
          </>
        )
    }
  }, [info, t])

  const typeIcon = typeWithdrawalIcon?.[info?.typeWithdrawal] || null

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(LN_USER_WITHDRAWAL_INFO_MODAL_TITLE)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          <Stack gap={3}>
            <Flex>
              <Flex gap={2} alignItems="center">
                {typeIcon && <Icon as={typeIcon} />}
                <TextMd fontSize={{ base: 'sm', sm: 'md' }} fontWeight="bold">
                  {t(withdrawalDetailsObjList?.[info?.typeWithdrawal]?.name)}
                </TextMd>
              </Flex>
            </Flex>
            <Flex gap={2} alignItems="center">
              <FormLabel m={0}>{t(LN_USER_WITHDRAWAL_TABLE_STATUS)}: </FormLabel>
              <StatusItem status={info?.status} isCancel onCancel={onCancelWithdrawal} />
            </Flex>
            <DisabledInputInfo
              label={t(LN_USER_WITHDRAWAL_TABLE_DATE)}
              getInputProps={{
                value: info?.date ? formatDateToPrettierFullDateTime(info?.date) : '-',
              }}
              mb={0}
            />
            <DisabledInputInfo
              label={t(LN_USER_WITHDRAWAL_TABLE_AMOUNT)}
              getInputProps={{
                value: info?.amount ? formatDigitsWithSpaces(info?.amount) : '0',
              }}
              isInputRightAddon
              inputRightAddonVal={info?.currency}
              mb={0}
            />
            {switcherInfoDependsOnType}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(InfoModal)
