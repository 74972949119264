// Modules
import { memo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { FiUpload } from 'react-icons/fi'

// Languages
import {
  LN_KYC_FILE_DND_SELECT_TITLE,
  LN_KYC_FILE_DND_SUPPORTED_FORMATS,
  LN_KYC_FILE_DND_TITLE,
} from 'locales/constants'

// Components
import PreviewList from 'components/DNDFileUploader/PreviewList'

// UI
import { Flex, Icon, Text, VStack, useColorModeValue } from '@chakra-ui/react'

const supportedFileFormats = ['.jpeg', '.png']

const DNDFileUploader = (props) => {
  const { file, setFile, isDeleted, width, preview = false, height, onDelete } = props

  const { t } = useTranslation()

  const onDrop = useCallback((acceptedFiles) => {
    setFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'image/*': supportedFileFormats,
    },
  })

  const borderColor = useColorModeValue('gray.300', 'gray.500')

  return (
    <Flex
      {...getRootProps()}
      border="2px dashed"
      borderColor={isDragActive ? 'teal.500' : borderColor}
      borderRadius="md"
      p={6}
      textAlign="center"
      cursor="pointer"
      bg={useColorModeValue('gray.50', 'gray.700')}
      width={width}
      alignItems="center"
      justifyContent="center"
      height={height}
    >
      <input {...getInputProps()} />
      <VStack spacing={4}>
        <Icon as={FiUpload} boxSize={12} color="blue.200" />
        <Text fontSize="lg" color={isDragActive ? 'blue.200' : 'gray.500'}>
          {isDragActive ? t(LN_KYC_FILE_DND_TITLE) : t(LN_KYC_FILE_DND_SELECT_TITLE)}
        </Text>
        <Text fontSize="sm" color="gray.400">
          {t(LN_KYC_FILE_DND_SUPPORTED_FORMATS)}: {supportedFileFormats?.join(', ')}
        </Text>
        <Flex flexDirection="row" flexWrap="wrap" marginTop={3}>
          {preview && <PreviewList file={file} isDeleted={isDeleted} onDelete={onDelete} />}
        </Flex>
      </VStack>
    </Flex>
  )
}

export default memo(DNDFileUploader)
