// Modules
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoSignOut } from 'react-icons/go'

// Languages
import { LN_USER_NAVBAR_START_TRADING_BTN } from 'locales/constants'

// Components
import { TextLg, TextMd, TextSm, UserLogoBtn } from 'components'
import LanguageDropdown from 'components/LanguageDropdown'
import SubscriptionPlan from 'components/SubscriptionPlan'
import { sidebarList } from 'pages/User/components/Sidebar/constants'
import useUserSidebar from 'pages/User/components/Sidebar/useUserSidebar'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Button, Flex, Icon } from '@chakra-ui/react'

export const sidebarWidth = '267px'

const Sidebar = () => {
  const { t } = useTranslation()

  const { mainBorderColor, mainBgColor } = useCurrentColorTheme()

  const { userInfo, onChangeSidebarActiveItem, onLogout, onStartTrading, sidebarActiveItem } =
    useUserSidebar()

  return (
    <aside style={{ position: 'fixed' }}>
      <Box
        w={sidebarWidth}
        minH="90vh"
        h="100%"
        p="10px"
        borderRight="2px solid"
        borderColor={mainBorderColor}
        position="relative"
        bg={mainBgColor}
      >
        <Flex alignItems="center" flexDirection="column" gap={1} mt={4}>
          <UserLogoBtn
            w="56px"
            h="56px"
            mb={2}
            _hover={{
              cursor: 'default',
            }}
          />
          <TextMd>#{userInfo?.id}</TextMd>
          <TextLg fontWeight="600" color="navy.300">
            {userInfo?.email}
          </TextLg>
        </Flex>
        <Flex flexDirection="column" gap={3} mt={6}>
          {sidebarList?.map((el, i) => (
            <SidebarItem
              key={el?.id + i}
              onClickItem={onChangeSidebarActiveItem}
              id={el?.id}
              sidebarActiveItem={sidebarActiveItem}
              icon={el?.icon}
              title={t(el?.title)}
            />
          ))}
          <SidebarItem
            key="logout"
            onClickItem={onLogout}
            id="logout"
            sidebarActiveItem={sidebarActiveItem}
            icon={GoSignOut}
            title="Logout"
          />
          <Flex gap={4} pt={4} mt={2} borderTop="1px solid" borderColor={mainBorderColor}>
            <LanguageDropdown />
          </Flex>
        </Flex>
        {/*<Box position="absolute" top="8px">*/}
        {/*  <LanguageDropdown />*/}
        {/*</Box>*/}
        <Box position="absolute" top="8px" right="6px">
          <SubscriptionPlan />
        </Box>
        <Box position="absolute" bottom="31px" w="92%">
          <Button colorScheme="blue" color="white" onClick={onStartTrading} w="100%">
            {t(LN_USER_NAVBAR_START_TRADING_BTN)}
          </Button>
        </Box>
      </Box>
    </aside>
  )
}

export default memo(Sidebar)

export const SidebarItem = memo((props) => {
  const { onClickItem, id, sidebarActiveItem, icon, title, isMobile } = props

  const { mainTextColor, isLightTheme, mainBorderColor } = useCurrentColorTheme()

  const onChooseItem = useCallback(() => {
    onClickItem(id)
  }, [id, onClickItem])

  const titleProps = useMemo(() => {
    return {
      color:
        sidebarActiveItem === id
          ? isLightTheme
            ? 'blue.500'
            : 'navy.300'
          : isLightTheme
          ? 'brand.textColor1Light'
          : 'brand.textColor2',
      groupHover: { color: isLightTheme ? mainTextColor : 'white' },
    }
  }, [sidebarActiveItem, id, isLightTheme, mainTextColor])

  return (
    <Flex
      gap={{ base: 2, sm: 3 }}
      alignItems="center"
      onClick={onChooseItem}
      _hover={{
        cursor: 'pointer',
        color: isLightTheme ? mainTextColor : 'white',
      }}
      role="group"
      borderBottom={isMobile ? '1px solid' : 0}
      borderColor={sidebarActiveItem === id ? mainBorderColor : 'transparent'}
    >
      <Icon
        as={icon}
        w={{ base: 4, sm: 5 }}
        h={{ base: 4, sm: 5 }}
        color={titleProps.color}
        _groupHover={titleProps.groupHover}
      />
      {isMobile ? (
        <TextSm
          fontSize={{ base: 'xs', sm: 'sm' }}
          fontWeight="500"
          color={titleProps.color}
          _groupHover={titleProps.groupHover}
        >
          {title}
        </TextSm>
      ) : (
        <TextLg fontWeight="500" color={titleProps.color} _groupHover={titleProps.groupHover}>
          {title}
        </TextLg>
      )}
    </Flex>
  )
})

SidebarItem.displayName = 'SidebarItem'
